'use client';
import React, { useEffect, useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks';
import { addDoc, collection, deleteDoc, doc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { GrGallery } from "react-icons/gr";
import { firestoreDB } from 'services/Firebase/firebaseConfig';
import { appUrl } from 'api/api';
import { apiUrl } from 'api/api';
import firestorekeys from 'constants/firestorekeys';
import { api } from 'api/api';
import avatar from 'assets/images/background_opinion.jpg'
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import { scheme } from 'constants/env';

const LiveHotes = ({
    live,
    requests,
    visible = false,
    onChangeVisible = () => null
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authContextState } = useAuthReducer()
    const navigate = useNavigate()

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [hasMorePage, setHasMorePage] = useState(false)

    const [isFetching, setIsFetching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const [searchText, setSearchText] = useState('')

    const handleCancel = () => {
        onChangeVisible(false)
    }



    const handleAcceptParticipate = async (user_id) => {
        const collectionRef = collection(
            firestoreDB,
            scheme,
            firestorekeys.lives,
            firestorekeys.channels,
            live?.live_channel_name,
            firestorekeys.requests
        );

        const refDoc = doc(collectionRef, user_id?.toString())

        await updateDoc(refDoc, {
            live_request_status: true
        }).then(async() => {
            console.log('Live participate request saved.');

            const collectionRef = collection(
                firestoreDB,
                scheme,
                firestorekeys.lives,
                firestorekeys.channels,
                live?.live_channel_name,
                firestorekeys.audiences
            );
    
            const refDoc = doc(collectionRef, user_id?.toString())
    
            await updateDoc(refDoc, {
                live_is_broadcaster: true,
                live_on_pause: false,
                live_on_mute: false,
            }).then(() => {
                console.log('Live participate request saved.');
            }).catch((error) => {
                console.error('Error save participate request:', error);
            });
        }).catch((error) => {
            console.error('Error save participate request:', error);
        });

       
    }


    const handleRejetParticipate = async (user_id) => {
        const collectionRef = collection(
            firestoreDB,
            scheme,
            firestorekeys.lives,
            firestorekeys.channels,
            live?.live_channel_name,
            firestorekeys.requests
        );

        const refDoc = doc(collectionRef, user_id?.toString())

        await deleteDoc(refDoc).then(async() => {
            console.log('Live participate request deleted.');

            const collectionRef2 = collection(
                firestoreDB,
                scheme,
                firestorekeys.lives,
                firestorekeys.channels,
                live?.live_channel_name,
                firestorekeys.audiences
            );

            const refDoc2 = doc(collectionRef2, user_id?.toString())

            await updateDoc(refDoc2, {
                live_is_broadcaster: false
            }).then(async () => {

            }).catch((error) => {
                console.error('Error update audience:', error);
            });
        }).catch((error) => {
            console.error('Error delete participate request:', error);
        });
    }



    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '5%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', }}>
                        {t('loading')}
                    </h6>
                </div>
            </div>
        )
    }

    return (
        <Dialog open={visible} onClose={() => onChangeVisible(false)} className="relative z-10">
            <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
                    <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">

                        <div className="bg-white px-4 pb-4 pt-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="w-full  sm:ml-4 sm:text-left">

                                    <DialogTitle as="h3" className="text-base text-center font-semibold leading-6">
                                        {i18n.language == 'en'
                                            ? 'Request for participation'
                                            : 'Demande de participation'
                                        }
                                    </DialogTitle>
                                    <div className='flex overflow-x-auto mb-6 w-11/12'>
                                        {selectedUsers.map((user, index) => (
                                            <div key={index} className='text-center me-3'>
                                                <img
                                                    src={user?.profile?.prof_picture || avatar}
                                                    alt=''
                                                    style={{
                                                        width: '32px',
                                                        height: '32px',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto'
                                                    }}
                                                    className='object-cover object-center rounded-full'
                                                />
                                                <div>
                                                    <p className='text-xs font-bold p-0 m-0'>
                                                        {`${user?.user_surname} ${user?.user_name}`.length > 9
                                                            ? `${user?.user_surname} ${user?.user_name}`?.slice(0, 9) + '...'
                                                            : `${user?.user_surname} ${user?.user_name}`
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='overflow-y-auto h-96'>
                                        {requests?.map((user, index) => {
                                            return (
                                                <div key={index} className='flex items-center py-3 border-b cursor-pointer'>
                                                    <img src={user?.profile?.prof_picture || avatar} alt='' className='w-12 h-12 object-cover object-center rounded-full' />
                                                    <div>
                                                        <p className='font-bold p-0 m-0'>
                                                            {user?.user_surname || ''} {user?.user_name || ''}
                                                        </p>
                                                        <span className='text-sm'>
                                                            @{user?.user_username}
                                                        </span>
                                                    </div>
                                                    <div className='ms-auto'>
                                                        {!user?.live_request_status && <button onClick={() => handleAcceptParticipate(user?.user_id)} className='text-xs text-white bg-orange-400 px-2 py-2 me-2 rounded'>
                                                            {t('accept')}
                                                        </button>
                                                        }
                                                        <button onClick={() => handleRejetParticipate(user?.user_id)} className='text-xs text-white bg-red-500 px-2 py-2 rounded'>
                                                            {t('cancel')}
                                                        </button>
                                                    </div>

                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="border border-emerald-600 inline-flex w-full justify-center   mt-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                {t('cancel')}
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default LiveHotes