import React, { useEffect } from 'react'
import Comment from '../../Comment/Comment'
import CommentInput from '../../CommentInput/CommentInput'
import { IoHandLeft, IoHandLeftOutline } from 'react-icons/io5'
import { BsSquare, BsPersonSquare, BsCheckSquareFill } from 'react-icons/bs'
import { RiShareForwardLine, RiSendPlaneFill, RiShareForwardFill } from 'react-icons/ri'
import { AiOutlineEye, AiFillGift, AiOutlineLike } from 'react-icons/ai'
import LikeCounter from './LikeCounter/LikeCounter'
import ModelUnauth from '../../ModalUnauth/ModelUnauth'
import { useState } from 'react'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { color } from '../../../hooks/Utils/color'
import { useNavigate } from 'react-router-dom'

const OpinionFooter = ({
    opinion,
    sond_id,
    ago_id=0,
    sond_user,
    sond_caption,
    last_comment = [],
    comments = [],
    comments_count = 0,
    soutiens = [],
    soutiens_count = 0,
    suggestions = [],
    suggestions_count = 0,
    votes_count = 0,
    get_user_vote = [],
    shares = [],
    shares_count = 0,
    updateOpinion,
    sourceFrom,
}) => {
    
    const accessToken = useAccessToken()
    const { data: AuthData } = useAuthReducer()
    const navigate = useNavigate()
    const [showUnauthModal, setShowUnauthModal] = useState(false)

    const goToViewOpinion = () => {
        if (sourceFrom === 'ViewOpinion') {
            return
        }
        navigate(`/opinion/select/${opinion?.agora?.ago_id ? opinion?.agora?.ago_id : 0}/${opinion?.sond_id}`, {
            state: {
              details: opinion
            }
          })
    }


    return (
        <div className='opinion-footer mt-2'>

            <div style={{display: 'flex' ,justifyContent: 'space-around', borderTopWidth: 1, borderTopColor: '#f1f1f1'}}>

                <LikeCounter
                    sond_id={sond_id}
                    sond_user={sond_user}
                    sond_caption={sond_caption}
                    soutiens={soutiens}
                    soutiens_count={soutiens_count}
                    setShowUnauthModal={setShowUnauthModal}
                    sourceFrom={sourceFrom}
                />

                <button
                    onClick={() => {
                        if (sourceFrom == 'Feed'  || !accessToken?.Authorization) {
                            setShowUnauthModal(true)
                            return
                        }
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    {suggestions?.length === 0 ?
                        <IoHandLeftOutline size={26} color='#808080' />
                        :
                        <IoHandLeft size={26} color={color.primary} />
                    }
                    <span style={{ color:  suggestions_count === 0 ? '#808080' : '#1880a1', fontSize: '16px', fontWeight: '600', margin: '0px', marginLeft: '3px' }}>
                        {suggestions_count}
                    </span>
                </button>

                <button
                    onClick={() => {
                        if (sourceFrom == 'Feed' || !accessToken?.Authorization) {
                            setShowUnauthModal(true)
                            return
                        }
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    {get_user_vote.length === 0 ?
                        <BsSquare size={22} color='#808080' />
                        :
                        <BsCheckSquareFill size={22} color='#28a745' />
                    }

                    <span style={{ color:  votes_count === 0 ? '#808080' : '#1880a1', fontSize: '16px', fontWeight: '600', margin: '0px', marginLeft: '5px' }}>
                        {votes_count}
                    </span>
                </button>

                <button
                    onClick={() => {
                        // if (sourceFrom == 'Feed') {
                        //     setShowUnauthModal(true)
                        //     return
                        // }
                        goToViewOpinion()
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <BsPersonSquare size={22} color={comments?.length === 0 ? '#808080' : color.primary} />
                    <span style={{ color:  comments_count === 0 ? '#808080' : '#1880a1', fontSize: '16px', fontWeight: '600', margin: '0px', marginLeft: '5px' }}>
                        {comments_count}
                    </span>
                </button>

                <button
                    onClick={() => {
                        console.log(accessToken)
                        if (sourceFrom == 'Feed' || !accessToken?.Authorization?.Authorization) {
                            setShowUnauthModal(true)
                            return
                        }
                    }}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    {shares?.length === 0 ?
                        <RiShareForwardLine size={26} color='#808080' />
                        :
                        <RiShareForwardFill size={26} color='#0570E7' />
                    }
                    <span style={{ color: shares_count === 0 ? '#808080' : '#1880a1', fontSize: '16px', fontWeight: '600', margin: '0px', marginLeft: '3px' }}>
                        {shares_count}
                    </span>
                </button>

            </div>

            {last_comment && sourceFrom != 'ViewOpinion' &&
                <Comment
                    user={last_comment?.user}
                    sond_user={sond_user}
                    sond_id={sond_id}
                    sond_caption={sond_caption}
                    com_id={last_comment?.com_id}
                    com_mute={last_comment?.com_mute}
                    com_parent={last_comment?.com_parent}
                    com_sond={last_comment?.com_sond}
                    com_text={last_comment?.com_text}
                    com_type={last_comment?.com_type}
                    com_user={last_comment?.com_user}
                    attachment={last_comment?.attachment}
                    soutiens={last_comment?.soutiens}
                    soutiens_count={last_comment?.soutiens_count}
                    subcomments={last_comment?.subcomments}
                    subcomments_count={last_comment?.subcomments_count}
                    com_date_created={last_comment?.com_date_created}
                    updateOpinion={updateOpinion}
                    sourceFrom={sourceFrom}
                    setShowUnauthModal={setShowUnauthModal}
                />
            }

            <CommentInput
                sond_id={sond_id}
                com_sond={sond_id}
                com_user={AuthData?.user?.user_id}
                sond_caption={sond_caption}
                com_type="c"
                sond_user={sond_user}
                updateOpinion={updateOpinion}
                sourceFrom={sourceFrom}
                showUnauthModal={showUnauthModal}
                setShowUnauthModal={setShowUnauthModal}
            />

            {/* RENDER MODAL UNAUTH */}
            <ModelUnauth show={showUnauthModal} setShow={setShowUnauthModal} />

        </div>
    )
}

export default OpinionFooter