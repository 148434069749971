'use client'

import { useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { localStorageGetItem, localStorageKeys } from 'store/localstorage'
import { useTranslation } from 'react-i18next'
import { color } from 'hooks/Utils/color'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { api } from 'api/api'
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction'
import { formatCash } from 'hooks/Utils/parsing'
import { useLocation, useNavigate } from 'react-router-dom'

const DonorList = ({
    recompenses = [],
    open = false,
    onClose = () => null,
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const location = useLocation()
    const { data: authContextState } = useAuthReducer()
    const [recompensesFormated, setRecompensesFormated] = useState([])

    const handleClose = () => onClose(false)

    const goToDonorProfil = (donor) => {
        navigate(`/profile/${donor?.user_username}`)
        setTimeout(() => {
            if (location.pathname?.includes('profile/')) {
                window.location.reload();
            }
        }, 200);
    }

    useEffect(() => {
        const groupedData = recompenses.reduce((acc, current) => {
            const donorId = current.recom_donor_id;
            const donor = current.donor;

            if (!acc[donorId]) {
                acc[donorId] = {
                    donorId,
                    donor,
                    total: 0
                };
            }

            acc[donorId].total += current.recom_value;
            return acc;
        }, {});
        const data = Object.values(groupedData)?.sort((a, b) => b?.total - a?.total);
        setRecompensesFormated(data)
    }, [recompenses])

    return (
        <Dialog open={open} onClose={onClose} className="relative z-10">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all data-closed:translate-y-4 data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in sm:my-8 sm:w-full sm:max-w-lg data-closed:sm:translate-y-0 data-closed:sm:scale-95"
                    >

                        <DialogTitle as="h2" className="flex justify-between items-center px-3 text-lg font-semibold text-gray-900 mt-3">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-white">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                            </svg>

                            {i18n.language == 'en'
                                ? 'List of gifters'
                                : 'Liste des donateurs'
                            }
                            <button
                                onClick={handleClose}
                                className="p-2 rounded-full hover:bg-gray-100"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6" style={{ color: color.primary }}>
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </DialogTitle>

                        <div className="px-4 mt-3">
                            {recompensesFormated?.map((item, index) => (
                                <button
                                    key={index}
                                    onClick={() => goToDonorProfil(item?.donor)}
                                    className={`flex justify-between items-center py-4 w-full ${index !== 0 ? 'border-t border-gray-300' : ''}`}
                                >
                                    <div className="flex items-center">
                                        <img
                                            src={item?.donor?.profile?.prof_picture || "/assets/background_opinion.jpg"}
                                            width={38}
                                            height={38}
                                            className="w-10 h-10 rounded-full border border-gray-400 mr-2"
                                            alt="Donor Profile"
                                        />
                                        <div className="w-3/4">
                                            <p className="font-semibold truncate text-black">
                                                {item?.donor?.user_surname} {item?.donor?.user_name}
                                            </p>
                                            <p className="text-xs font-medium truncate text-gray-500">
                                                @{item?.donor?.user_username}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-center font-medium">
                                        <img
                                            src={require("assets/coins/coins.png")}
                                            width={18}
                                            height={18}
                                            className="w-4 h-4 mr-1"
                                            alt="Coins"
                                        />
                                        <p className="text-lg font-bold text-dark">{formatCash(item?.total)}</p>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}


export default DonorList;