import React, { useState } from 'react'
import styles from './LiveCommentInput.module.css'
import { IoSend } from "react-icons/io5";
import firestorekeys from 'constants/firestorekeys';
import { collection, doc, setDoc } from 'firebase/firestore';
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { firestoreDB } from 'services/Firebase/firebaseConfig';
import { useTranslation } from 'react-i18next';
import { scheme } from 'constants/env';


const LiveCommentInput = ({
    channelName,
}) => {

    const { t, i18n } = useTranslation()
    const { data: authContexteState } = useAuthReducer()
    const [value, setValue] = useState('')


    const handleSubmit = async (e) => {
        try {
            e.preventDefault()

            const collectionRef = collection(
                firestoreDB,
                scheme,
                firestorekeys.lives,
                firestorekeys.channels,
                channelName,
                firestorekeys.comments
            );

            const refDoc = doc(collectionRef)

            await setDoc(refDoc, {
                text: value,
                timestamp: new Date().getTime(),
                user: {
                    user_surname: authContexteState?.user?.user_surname,
                    user_name: authContexteState?.user?.user_name,
                    user_username: authContexteState?.user?.user_username,
                    user_email: authContexteState?.user?.user_email,
                    profile: {
                        prof_picture: authContexteState?.user?.profile?.prof_picture
                    }
                }
            }).then(() => {
                console.log('Live comments sended.');
                setValue('')
            }).catch((error) => {
                console.error('Error send live comment:', error);
            });
        } catch (error) {
            console.error('Error send live comment:', error);
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={`flex justify-between items-center border border-gray-500 rounded-full py-1 ps-2 pe-1`}>
                <input
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    className={`${styles.input} text-white`}
                    type='text'
                    placeholder={i18n.language == 'en'
                        ? 'Comment...'
                        : 'Commentaire...'
                    }
                />

                <button type='submit' className='flex justify-center items-center ps-1 bg-white rounded-full' style={{ width: '35px', height: '35px' }}>
                    <IoSend
                        size={22}
                        color='black'
                    />
                </button>
            </div>
        </form>
    )
}

export default LiveCommentInput