import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap';
import './HomeOpinions.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { api } from '../../../api/api'
import Opinion from '../../Opinion/Opinion'
import { useAccessToken, useHomeReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import useReducerHomeAction from '../../../hooks/ReducerHooksAction/useReducerHomeAction'
import ViewOpinion from '../../viewOpinion/ViewOpinion'
import { BsArrowLeft, BsArrowReturnLeft } from 'react-icons/bs'
import PeopleMatchCarousel from '../../PeopleMatchCarousel/PeopleMatchCarousel'
import ShimmerPost from 'components/ShimmerPost/ShimmerPost'
import { ShimmerSocialPost } from 'react-shimmer-effects'


const HomeOpinions = () => {

    // STATE
    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const { ADD_HOME_OPINIONS, DELETE_HOME_OPINION, REFRESH_HOME_OPINIONS, MUTED_USER_HOME_OPINION, UPDATE_HOME_OPINION } = useReducerHomeAction()
    const { data: HomeData, store: HomeStore } = useHomeReducer()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [opinions, setOpinions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isRefresh, setIsRefresh] = useState(false)


    useEffect(() => {
        fetchOpinions()
    }, [])

    const fetchOpinions = async () => {
        if (isRefresh || HomeData.opinions.length == 0) {
            setIsLoading(true)
        }
        try {
            const request = await api(`api/opinions/v2/welcome?page=${page}`, 'GET', {}, accessToken)
            const response = await request.json()
            console.log('Response fetch opinions home:', response)
            if (request.status === 200) {
                if (response.success) {
                    setIsEmpty(page == 1 && response?.opinions?.data?.length == 0)

                    if (response?.opinions?.current_page == 1) {
                        REFRESH_HOME_OPINIONS(response?.opinions?.data)
                    } else {
                        ADD_HOME_OPINIONS(response?.opinions?.data)
                    }

                    setPage(oldValue => oldValue + 1)
                    setHasMore(response?.opinions?.next_page_url)
                }
            }
            setIsRefresh(false)
            setIsLoading(false)
        } catch (error) {
            fetchOpinions()
            setIsLoading(false)
            console.warn(error.message);
        }
    };




    if (isLoading) {
        return (
            <div style={{ marginTop: '-10px' }}>
                <ShimmerPost />
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                    <div style={{ textAlign: 'center' }}>
                        <Spinner
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            variant='secondary'
                            style={{
                                width: '30px',
                                height: '30px'
                            }}
                        />
                        <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '13px' }}>
                            {t('loading_audience')}
                        </h6>
                    </div>
                </div> */}
            </div>
        )
    }


    if (HomeData.opinions.length == 0) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                <div style={{ textAlign: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-20 mx-auto" style={{ color: '#c0c0c0' }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
                    </svg>
                    <h6 className='font-semibold' style={{ color: '#c0c0c0', marginTop: '5%', fontSize: '18px' }}>
                        {i18n.language == 'en'
                            ? 'No opinion available'
                            : 'Aucune opinion disponible'
                        }
                    </h6>
                    <h6 className='font-semibold' style={{ color: '#c0c0c0', marginTop: '5px', fontSize: '18px' }}>
                        {i18n.language == 'en'
                            ? 'Be the first to post an opinion'
                            : 'Soyez le premier à publier une opinion'
                        }
                    </h6>

                </div>
            </div>
        )
    }


    const renderLoader = () => {
        return (
            <div style={{ marginTop: '-10px' }}>
                <ShimmerSocialPost type="both" />
            </div>
            // <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
            //     <div style={{ textAlign: 'center' }}>
            //         <Spinner
            //             size="sm"
            //             role="status"
            //             aria-hidden="true"
            //             animation="border"
            //             variant='secondary'
            //             style={{
            //                 width: '25px',
            //                 height: '25px'
            //             }}
            //         />
            //         <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
            //             {t('loading_opinion')}
            //         </h6>
            //     </div>
            // </div>
        )
    }



    return (
        <div id="HomeScrollableDiv" className='home-opinions overflow-x-hidden'>
            <InfiniteScroll
                dataLength={HomeData.opinions.length}
                next={fetchOpinions}
                hasMore={hasMore}
                loader={renderLoader()}
                scrollableTarget="HomeScrollableDiv"
                scrollThreshold={0.9}
                style={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    overflowX: 'hidden'
                }}>
                {HomeData.opinions.map((item, index) => {
                    return (
                        <Opinion
                            key={index}
                            item={item}
                            index={index}
                            opinion={item}
                            updateOpinion={UPDATE_HOME_OPINION}
                            deleteOpinion={DELETE_HOME_OPINION}
                            handleMuteUser={MUTED_USER_HOME_OPINION}
                            sourceFrom={'Home'}
                        />
                    )
                })}
            </InfiniteScroll>
        </div>
    )
}

export default HomeOpinions