import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import styles from './LiveJoin.module.css'
import {
  AgoraRTCProvider,
  LocalUser,
  LocalVideoTrack,
  RemoteUser,
  RemoteVideoTrack,
  useClientEvent,
  useIsConnected,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
  useRemoteVideoTracks,
  useRTCClient,
} from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { agoraAppID } from "constants/env";
import { collection, deleteDoc, doc, getDoc, onSnapshot, query, setDoc, updateDoc } from "firebase/firestore";
import firestorekeys from "constants/firestorekeys";
import { firestoreDB, firestoreKeystore } from "services/Firebase/firebaseConfig";
import { FaPauseCircle, FaPlayCircle, FaUser } from "react-icons/fa";
import { useAccountReducer } from "hooks/ReducerHooks/ReducerHooks";
import { useAuthReducer } from "hooks/ReducerHooks/ReducerHooks";
import { IoIosCloseCircle } from "react-icons/io";
import { SiSlideshare } from "react-icons/si";
import { IoIosShareAlt } from "react-icons/io";
import { MdSupervisorAccount } from "react-icons/md";
import { FaHeart } from "react-icons/fa";
import { CgComment } from "react-icons/cg";
import { Spinner } from "react-bootstrap";
import avatar from 'assets/images/background_opinion.jpg'
import { useTranslation } from "react-i18next";
import LiveInvite from "../Componants/LiveInvite/LiveInvite";
import LiveShare from "../Componants/LiveShare/LiveShare";
import LiveComment from "../Componants/LiveComment/LiveComment";
import { scheme } from "constants/env";
import { useAgoraContext } from "contexts/AgoraContext";
import { agorakeys } from "constants/agorakeys";
import LiveCommentInput from "../Componants/LiveComment/LiveCommentInput/LiveCommentInput";
import background from 'assets/images/bg_clips.jpg'
import { BiSolidMicrophone, BiSolidMicrophoneOff } from "react-icons/bi";
import Broadcasters from "./Broadcasters/Broadcasters";
import SendGift from "components/SendGIft/SendGift";

const LiveJoin = () => {

  const { t, i18n } = useTranslation()
  const { data: authContextState } = useAuthReducer()
  const navigate = useNavigate();

  const { live_code } = useParams();

  // AGORA CLIENT
  const client = useRef(null)
  const localMicrophoneTrack = useRef(null);
  const localCameraTrack = useRef(null);

  const [uid, setUid] = useState(0)

  const [audiences, setAudiences] = useState([])
  const [remoteUsers, setRemoteUsers] = useState([])
  const [broadcasters, setBroadcasters] = useState([]);
  const [broadcasterCurrent, setBroadcasterCurrent] = useState(null);
  const [request, setRequest] = useState(null);
  const [totalRecompense, setTotalRecompense] = useState(0);
  const [recompenses, setRecompenses] = useState([]);

  // LOCAL USER
  const [live, setLive] = useState(null)
  const [joined, setJoined] = useState(false);
  const [published, setPublished] = useState(false);
  const [leaved, setLeaved] = useState(false);
  const [finished, setFinished] = useState(false);
  const [onMute, setOnMute] = useState(false);
  const [onPause, setOnPause] = useState(false);


  // LIVE STATES
  const [isLiked, setIsLiked] = useState(false)
  const [likeCount, setLikeCount] = useState(0)
  const [commentCount, setCommentCount] = useState(0)
  const [audienceCount, setAudienceCount] = useState(0)
  const [requestSended, setRequestSended] = useState(false);
  const [requestAccepted, setRequestAccepted] = useState(false);
  const [isVisibleGift, setIsVisibleGift] = useState(false)

  const toggleGift = () => setIsVisibleGift(!isVisibleGift)

  // MODAL STATE
  const [showInviteLive, setShowInviteLive] = useState(false)
  const [showShareLive, setShowShareLive] = useState(false)

  const goBack = useCallback(() => navigate('/live'), [])
  const handleShowShareLive = () => setShowShareLive(oldValue => !oldValue)
  const handleShowInviteLive = () => setShowInviteLive(oldValue => !oldValue)


  // FETCH LIVE
  useEffect(() => {
    const fetchlive = async () => {
      const docRef = doc(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setLive(docSnap.data());
      }
    };

    fetchlive();
  }, [live_code]);



  // audience snapshot
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.audiences
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAudiences(data)
    });
    return () => {
      unsubscribe();
    }
  }, []);


  // AUDIENCE SNAPSHOT
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.audiences
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setAudienceCount(data?.length)
    });
    return () => {
      unsubscribe();
    }
  }, []);


  // REQUESTS SNAPSHOT
  useEffect(() => {
    const collectionRef = collection(
      firestoreDB,
      scheme,
      firestorekeys.lives,
      firestorekeys.channels,
      live_code,
      firestorekeys.requests
    );

    const docRef = doc(collectionRef, authContextState?.user?.user_id?.toString());

    // Listen to real-time updates
    const unsubscribe = onSnapshot(
      docRef,
      async (docSnapshot) => {
        if (docSnapshot.exists()) {
          setRequestSended(true)
          console.log("Request document data:", docSnapshot.data());
          setRequest(docSnapshot.data())
          if (docSnapshot.data()?.live_request_status) {
            setRequestAccepted(true)
            joinAsHost()

            // client.current.setClientRole(agorakeys.broadcasterRole)
            // // CREATE MEDIA TRACK
            // const [localAudioTrack, localVideoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
            // localAudioTrack.setEnabled(true)
            // localVideoTrack.setEnabled(true)

            // localMicrophoneTrack.current = localAudioTrack
            // localCameraTrack.current = localVideoTrack

            // localVideoTrack.play("local-stream");

            // //  PUBLISH
            // await client.current.publish([localAudioTrack, localVideoTrack])

          } else {
            setRequestAccepted(false)
            client.current.setClientRole(agorakeys.audienceRole)
          }
        } else {
          setRequestSended(false)
          client.current.setClientRole(agorakeys.audienceRole)
          console.log("No such document!");
        }
      },
      (error) => {
        console.error("Error fetching document snapshot:", error);
      }
    );

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, [authContextState?.user?.user_id]);


  // LIKES MANAGEMENT
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.likes
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setIsLiked(data?.some(item => item?.id == authContextState?.user?.user_id))
      setLikeCount(data?.length)
    });
    return () => {
      unsubscribe();
    }
  }, []);


  // Get recompenses
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.recompenses
      ),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      const groupedData = data.reduce((acc, current) => {
        const donorId = current.recom_donor_id;
        const donor = current.donor;

        if (!acc[donorId]) {
          acc[donorId] = {
            donorId,
            donor,
            total: 0
          };
        }

        acc[donorId].total += current.recom_value;
        return acc;
      }, {});

      const dataFormated = Object.values(groupedData)?.sort((a, b) => b?.total - a?.total);

      setRecompenses(dataFormated?.slice(0, 3))
      setTotalRecompense(dataFormated?.reduce((acc, current) => acc + current?.total, 0))

    });
    return () => {
      unsubscribe();
    }
  }, []);


  // AGORA INIT
  useEffect(() => {
    const agoraInit = async () => {
      try {
        if (!live || joined) return

        const agoraClient = AgoraRTC.createClient({ mode: "live", codec: "vp8" });
        client.current = agoraClient
        agoraClient.setClientRole(agorakeys.audienceRole)

        // Join channel
        if (!joined) {
          const user = await client.current.join(
            agoraAppID,
            live_code,
            live?.live_code || null,
            uid || null,
          )
          setUid(user)
          addAudience(user)
        }


        agoraClient.on("user-joined", (user) => {
          setJoined(true);
        })

        agoraClient.on("user-published", async (user, mediaType) => {
          try {
            console.log("user-published:", user.uid);

            // Subscribe to the user
            await agoraClient.subscribe(user, mediaType);

            if (mediaType === "video") {
              const remoteVideoTrack = user.videoTrack;

              console.log('===================', remoteVideoTrack)

              // Create and ensure the video container
              // const containerId = `local-stream`;
              const containerId = `remote-stream-${user.uid}`;
              let videoContainer = document.getElementById(containerId);
              if (!videoContainer) {
                videoContainer = document.createElement("div");
                videoContainer.id = containerId;
                videoContainer.style.width = "100%";
                videoContainer.style.height = "200px";
                document.getElementById("remote-container").appendChild(videoContainer);
              }

              // Play the video track
              remoteVideoTrack.play(containerId);
              console.log("Playing remote video for user:", user.uid);
            }

            if (mediaType === "audio" || mediaType === "all") {
              const remoteAudioTrack = user.audioTrack;
              remoteAudioTrack.play();
            }

            setRemoteUsers((oldUsers) => {
              const exists = oldUsers.some((item) => item.uid === user.uid);
              return exists
                ? oldUsers
                : [...oldUsers, { uid: user.uid, videoTrack: user.videoTrack }];
            });
          } catch (error) {
            console.error("Error handling user-published:", error);
          }
        });

        agoraClient.on("user-unpublished", async (user) => {
          console.log("user-unpublished:====================", remoteUsers);

          const userIndex = remoteUsers.findIndex((remoteUser) => remoteUser.user.uid === user.uid);
          if (userIndex !== -1) {
            const { track } = remoteUsers[userIndex];

            // Unsubscribe from the user's track if it exists
            if (track) {
              await agoraClient.unsubscribe(track);
              console.log("unsubscribe success:", user.uid);
            }

            // Remove the user from the remoteUsers list
            remoteUsers.splice(userIndex, 1);
          }
        });

        agoraClient.on("user-left", (user) => {
          console.log("user-left:", user);
          setRemoteUsers(oldValue =>
            oldValue.filter(item => item?.uid !== user.uid)
          )
        });


      } catch (error) {
        console.error('Error init agora', error);
      }
    }
    agoraInit()

    return () => {
      leaveChannel()
    };
  }, [live]);


  useEffect(() => {
    const getBroadcaster = () => {
      setBroadcasterCurrent(
        audiences?.filter(item =>
          item?.live_is_broadcaster && item?.live_current_speaker
        )[0]
      )

      setBroadcasters(
        audiences?.filter(item =>
          item?.live_is_broadcaster &&
          !item?.live_current_speaker
        )
      )
    }
    getBroadcaster()
  }, [remoteUsers, audiences, request])


  const joinAsHost = async () => {
    client.current.setClientRole(agorakeys.broadcasterRole)

    // Create media track
    const [localAudioTrack, localVideoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
    localAudioTrack.setEnabled(true)
    localVideoTrack.setEnabled(true)

    localMicrophoneTrack.current = localAudioTrack
    localCameraTrack.current = localVideoTrack

    // Publish audio and video track
    publishAudioAndVideoTrack(localAudioTrack, localVideoTrack)

    const containerId = `local-stream`;
    let videoContainer = document.getElementById(containerId);
    if (!videoContainer) {
      videoContainer = document.createElement("div");
      videoContainer.id = containerId;
      videoContainer.style.width = "100%";
      videoContainer.style.height = "200px";
      document.getElementById("remote-container").appendChild(videoContainer);
    }

    localVideoTrack.play(videoContainer);

    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.audiences
      );
      const docRef = doc(collectionRef, authContextState?.user?.user_id?.toString())
      await updateDoc(docRef, {
        live_is_broadcaster: true
      }).then(() => {
        console.log('Live audience added.');
      }).catch((error) => {
        console.error('Error audience added:', error);
      });
    } catch (error) {
      console.error('Error audience added:', error);
    }
  }

  const publishAudioAndVideoTrack = async (localAudioTrack, localVideoTrack) => {
    try {
      await client.current.publish([localAudioTrack, localVideoTrack])
      setPublished(true)
    } catch (error) {
      console.error(error);
    }
  }

  const leaveChannel = async () => {
    try {
      setJoined(false)

      // Unpublish
      if (published) {
        await client.current.unpublish([localMicrophoneTrack.current, localCameraTrack.current]);
      }

      // Stop device track
      if (localMicrophoneTrack.current) {
        localMicrophoneTrack.current.stop();
      }
      if (localCameraTrack.current) {
        localMicrophoneTrack.current.stop();
      }

      // Leave channel
      await client.current.removeAllListeners();
      await client.current.leave();

      setRemoteUsers([])
      localMicrophoneTrack.current = null;
      localCameraTrack.current = null;

      removeAudience()
      removeRequest()

      goBack()

    } catch (error) {
      console.error('Error leave Channel:', error);
    }
  };



  // COMMENT SNAPSHOT
  const handleComment = async (text) => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.comments
      );
      const refDoc = doc(collectionRef)
      await setDoc(refDoc, {
        text: text,
        timestamp: new Date().getTime(),
        user: {
          user_surname: authContextState?.user?.user_surname,
          user_name: authContextState?.user?.user_name,
          user_username: authContextState?.user?.user_username,
          user_email: authContextState?.user?.user_email,
          profile: {
            prof_picture: authContextState?.user?.profile?.prof_picture
          }
        }
      }).then(() => {
        console.log('Live comments sended.');
      }).catch((error) => {
        console.error('Error send live comment:', error);
      });
    } catch (error) {
      console.error('Error send live comment:', error);
    }
  }


  const addAudience = async (user) => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.audiences
      );
      const docRef = doc(collectionRef, authContextState?.user?.user_id?.toString())
      await setDoc(docRef, {
        ...authContextState?.user,
        live_local_uid: 0,
        live_remote_uid: user,
        live_current_speaker: false,
        live_is_owner: false,
        live_is_broadcaster: false,
        live_on_pause: false,
        live_on_mute: false,
      }).then(() => {
        console.log('Live audience added.');
      }).catch((error) => {
        console.error('Error audience added:', error);
      });
    } catch (error) {
      console.error('Error audience added:', error);
    }
  }


  const removeAudience = async () => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.audiences
      );

      const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

      await deleteDoc(refDoc).then(() => {
        console.log('Live remove audience.');
      }).catch((error) => {
        console.error('Error remove audience:', error);
      });
    } catch (error) {
      console.error('Error remove audience:', error);
    }
  }

  const removeRequest = async () => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.requests
      );

      const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

      await deleteDoc(refDoc).then(() => {
        console.log('Live remove audience.');
      }).catch((error) => {
        console.error('Error remove audience:', error);
      });
    } catch (error) {
      console.error('Error remove audience:', error);
    }
  }


  const handleEndLive = async () => {
    try {
      const refDoc = doc(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code
      )

      await updateDoc(refDoc, { live_finished: true }).then(() => {
        console.log('Live finished.');
        // navigate('/')
      }).catch((error) => {
        console.error('Error live finished:', error);
      });
    } catch (error) {
      console.error('Error live finished:', error);
    }
  }


  const handleLike = async () => {
    try {
      const collectionRef = collection(
        firestoreDB,
        scheme,
        firestorekeys.lives,
        firestorekeys.channels,
        live_code,
        firestorekeys.likes
      );

      const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

      if (isLiked) {
        await deleteDoc(refDoc).then(() => {
          console.log('Live like added.');
        }).catch((error) => {
          console.error('Error like added:', error);
        });
      } else {
        await setDoc(refDoc, authContextState?.user).then(() => {
          console.log('Live like added.');
        }).catch((error) => {
          console.error('Error like added:', error);
        });
      }
    } catch (error) {
      console.error('Error like added:', error);
    }
  }

  const handleRequestParticipate = async () => {
    const collectionRef = collection(
      firestoreDB,
      scheme,
      firestorekeys.lives,
      firestorekeys.channels,
      live_code,
      firestorekeys.requests
    );

    const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

    await setDoc(refDoc, {
      ...authContextState?.user,
      live_request_status: false
    }).then(() => {
      console.log('Live participate request saved.');
    }).catch((error) => {
      console.error('Error save participate request:', error);
    });
  }

  const handleRejetParticipate = async () => {
    const collectionRef = collection(
      firestoreDB,
      scheme,
      firestorekeys.lives,
      firestorekeys.channels,
      live_code,
      firestorekeys.requests
    );

    const refDoc = doc(collectionRef, authContextState?.user?.user_id?.toString())

    await deleteDoc().then(() => {
      console.log('Live participate request saved.');
    }).catch((error) => {
      console.error('Error save participate request:', error);
    });
  }


  const handleOnMute = async () => {
    if (localMicrophoneTrack?.current) {
      if (onMute) {
        localMicrophoneTrack?.current?.setMuted(false);
        setOnMute(false)

        const collectionRef = collection(
          firestoreDB,
          scheme,
          firestorekeys.lives,
          firestorekeys.channels,
          live_code,
          firestorekeys.audiences
        )
        const docRef = doc(collectionRef, authContextState?.user?.user_id?.toString())
        await updateDoc(docRef, {
          live_on_mute: false,
        })
      } else {
        localMicrophoneTrack?.current?.setMuted(true);
        setOnMute(true)

        const collectionRef = collection(
          firestoreDB,
          scheme,
          firestorekeys.lives,
          firestorekeys.channels,
          live_code,
          firestorekeys.audiences
        )
        const docRef = doc(collectionRef, authContextState?.user?.user_id?.toString())
        await updateDoc(docRef, {
          live_on_mute: true,
        })
      }
    }
  };


  const handleOnPause = async () => {
    if (localMicrophoneTrack?.current && localCameraTrack?.current) {
      if (onPause) {
        localMicrophoneTrack?.current?.setMuted(false);
        localCameraTrack?.current?.setMuted(false);
        setOnMute(false)
        setOnPause(false)

        const collectionRef = collection(
          firestoreDB,
          scheme,
          firestorekeys.lives,
          firestorekeys.channels,
          live_code,
          firestorekeys.audiences
        )
        const docRef = doc(collectionRef, authContextState?.user?.user_id?.toString())
        await updateDoc(docRef, {
          live_on_pause: false,
        })
      } else {
        localMicrophoneTrack?.current?.setMuted(true);
        localCameraTrack?.current?.setMuted(true);
        setOnMute(true)
        setOnPause(true)

        const collectionRef = collection(
          firestoreDB,
          scheme,
          firestorekeys.lives,
          firestorekeys.channels,
          live_code,
          firestorekeys.audiences
        )
        const docRef = doc(collectionRef, authContextState?.user?.user_id?.toString())
        await updateDoc(docRef, {
          live_on_pause: true,
        })
      }
    }
  };

  const sendGift = async (data) => {
    const recompense = data?.recompense

    if (!recompense) return

    const collectionRef = collection(
      firestoreDB,
      scheme,
      firestorekeys.lives,
      firestorekeys.channels,
      live_code,
      firestorekeys.recompenses
    );
    const docRef = doc(collectionRef, recompense?.recom_id?.toString())
    await setDoc(docRef, {
      ...recompense,
      donor: authContextState?.user
    }).then(() => {
      console.log('Live audience added.');
    }).catch((error) => {
      console.error('Error audience added:', error);
    });
  }


  if (live?.live_finished) {
    return (
      <div className="flex justify-center items-center h-screen bg-black text-center text-white">
        <div className="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-12 mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 0 1-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 0 0-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
          </svg>
          <p className="text-xl text-center font-bold">
            {i18n.language == 'en'
              ? 'The live is over.'
              : 'Le live est terminé.'
            }
          </p>
        </div>

      </div>
    );
  }

  // if (live?.live_on_pause) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-black text-center text-white">
  //       <div className="text-center">
  //         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-12 mx-auto">
  //           <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 0 1-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 0 0-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
  //         </svg>
  //         <p className="text-xl text-center font-bold">
  //           {i18n.language == 'en'
  //             ? 'Live on pause the creator will be back soon.'
  //             : 'Live en pause, le créateur sera bientôt de retour.'
  //           }
  //         </p>
  //       </div>

  //     </div>
  //   );
  // }

  // if (!live || !joined) {
  //   return (
  //     <div className="flex justify-center items-center h-screen bg-black text-center text-white">
  //       <div>
  //         <Spinner
  //           size="sm"
  //           role="status"
  //           aria-hidden="true"
  //           animation="border"
  //           variant={'secondary'}
  //         />
  //         <p>{' '} Connexion...</p>
  //       </div>
  //     </div>
  //   );
  // }


  return (
    <div className={`${styles.page} h-screen overflow-hidden bg-black w-3/3`}>
      <div className={`${styles.container} w-full h-full relative items-baseline`}>

        {/* Local and remote video strean */}
        <div
          style={{
            backgroundImage: `url(${live?.live_thumbnail || background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
          className='flex justify-between w-full h-full'>

          {/* Local stream */}
          <div
            id={`remote-stream-${broadcasterCurrent?.live_remote_uid}`}
            style={broadcasters?.length == 0 ?
              {
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
              }
              :
              {
                width: '65%',
                height: '60%',
                marginTop: '20%',
                backgroundColor: 'black',
                border: '1px solid #000000'
              }
            }
          />


          {/* Remote user stream */}
          <div id="remote-container"
            style={{
              width: '35%',
              marginTop: '20%',
              height: '60%',
              display: broadcasters?.length == 0 ? 'none' : 'block',
              overflowY: 'auto',
              backgroundColor: 'black'
            }}>
            {broadcasters?.map((user, index) => (
              <div key={index}>
                <Broadcasters
                  user={user}
                  live={live}
                />
              </div>
              // <div
              //   id={`remote-stream-${user?.live_remote_uid}`}
              //   key={index}
              //   className="bg-black w-full h-52"
              //   style={{ border: '1px solid #000000' }} />
            ))}
          </div>
        </div>

        {/* <div className="flex items-center justify-center h-screen bg-black">
          {leaved &&
            <div>
              <p className="text-center text-white font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-12 mx-auto">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M12 18.75H4.5a2.25 2.25 0 0 1-2.25-2.25V9m12.841 9.091L16.5 19.5m-1.409-1.409c.407-.407.659-.97.659-1.591v-9a2.25 2.25 0 0 0-2.25-2.25h-9c-.621 0-1.184.252-1.591.659m12.182 12.182L2.909 5.909M1.5 4.5l1.409 1.409" />
                </svg>

                {i18n.language == 'en'
                  ? 'The live is over.'
                  : 'Le live est terminé.'
                }
              </p>
            </div>
          }
          {remoteUsers?.map(user => {
            return (
              <RemoteVideoTrack
                track={user.videoTrack}
                play
              />
            )
          })}
        </div> */}

        <div>
          {/* HEADER */}
          <div className="w-full top-0 absolute flex justify-between items-center p-3" >

            <div className="flex items-center">
              <div className="bg-red-500 text-white px-3 rounded-full">
                Live
              </div>
              <div className="flex items-center mx-2 px-3 text-white bg-neutral-900 rounded-full">
                <FaUser />
                <span className="ms-1 font-bold">
                  {audienceCount || 0}
                </span>
              </div>
            </div>


            {/* Button options broadcast */}
            <div>
              {requestAccepted &&
                <>
                  <button
                    onClick={handleOnMute}
                    className="cursor-pointer p-2 me-4 rounded-full"
                    style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    {onMute
                      ? <BiSolidMicrophoneOff color="white" size={32} />
                      : <BiSolidMicrophone color="white" size={32} />
                    }
                  </button>

                  <button
                    onClick={handleOnPause}
                    className="cursor-pointer p-2 me-4 rounded-full"
                    style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    {onPause
                      ? <FaPlayCircle color="white" size={32} />
                      : <FaPauseCircle color="white" size={32} />
                    }
                  </button>
                </>
              }
              <button
                onClick={leaveChannel}
                className="cursor-pointer p-2 rounded-full"
                style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                <IoIosCloseCircle color="white" size={32} />
              </button>
            </div>

          </div>

          {totalRecompense > 0 &&
            <div className="flex absolute top-20 left-2 items-center mx-2 ps-2 pe-3 py-1 text-white bg-neutral-900 rounded-full">
              <div className="flex items-center">
                {recompenses?.map((item, index) => (
                  <div key={index} >
                    <img
                      src={item?.donor?.profile?.prof_picture || require('assets/picture_men.jpeg')}
                      alt="coins"
                      className="w-8 h-8 md:w-10 md:h-10 rounded-full"
                    />
                  </div>
                ))}
              </div>
              <span className="ms-2 me-1 font-bold">
                {totalRecompense || 0}
              </span>
              <img src={require('assets/coins/coins.png')} alt="coins" className="w-5 h-5 md:w-7 md:h-7 rounded-full" />
            </div>
          }

          <div className="flex items-end justify-between w-full absolute bottom-16 p-3 mt-auto">

            <div className="w-96 text-white">
              <LiveComment
                channelName={live_code}
                onChangeCommentCount={setCommentCount}
              />
            </div>

            <div className="me-1">
              <button onClick={handleLike} className="ps-2">
                <FaHeart color={isLiked ? 'red' : "white"} size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {likeCount || 0}
                </p>
              </button>

              <div className="mt-3">
                <CgComment color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-sm font-bold">
                  {commentCount || 0}
                </p>
              </div>

              <button
                onClick={toggleGift}
                className="mt-3 w-10 h-10">
                <img src={require('assets/images/live_gift.png')} alt="git" className="w-full h-full mx-auto" />
                <p className="text-white text-center text-xs">
                  {i18n.language == 'en'
                    ? 'Gift'
                    : 'Cadeau'
                  }
                </p>
              </button>
            </div>

          </div>

          {/* BOTTOM */}
          <div className="flex items-center justify-between w-full absolute bottom-0 p-3 mt-auto">

            {/* Comments */}
            <div className="w-96 me-3 lg:w-75">
              <LiveCommentInput channelName={live_code} />
            </div>

            <div className="flex items-center justify-end w-96">
              <button onClick={handleRequestParticipate} className="me-8">
                {requestSended &&
                  <div style={{ marginBottom: '-10px' }} className="ms-auto h-3 w-3 top-0 right-0 bg-red-500 rounded-full" />
                }
                <SiSlideshare color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-xs lg:text-sm">
                  {i18n.language == 'en'
                    ? 'Participate'
                    : 'Participer'
                  }
                </p>
              </button>

              <button onClick={handleShowInviteLive} className="me-8">
                <MdSupervisorAccount color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-xs lg:text-sm">
                  {i18n.language == 'en'
                    ? 'Invite'
                    : 'Inviter'
                  }
                </p>
              </button>

              <button onClick={handleShowShareLive}>
                <IoIosShareAlt color="white" size={32} className="mx-auto" />
                <p className="text-white text-center text-xs lg:text-sm">
                  {i18n.language == 'en'
                    ? 'Share'
                    : 'Partager'
                  }
                </p>
              </button>
            </div>

          </div>

        </div>
      </div>

      {/* INVITE USER */}
      <LiveInvite
        live={live}
        visible={showInviteLive}
        onChangeVisible={handleShowInviteLive}
      />

      {/* SHARE LIVE */}
      <LiveShare
        live={live}
        visible={showShareLive}
        onChangeVisible={handleShowShareLive}
      />

      {/* Send gift */}
      <SendGift
        live_id={live?.live_id}
        open={isVisibleGift}
        onClose={setIsVisibleGift}
        onChange={sendGift}
      />

    </div>
  )
}

export default LiveJoin
