import React, { useEffect } from 'react'
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const PrettyTime = ({
    time,
    className,
    style
}) => {

    const { t, i18n } = useTranslation()


    const getTimeDifference = () => {
        try {
            const now = new Date();
            const receivedTime = new Date(time);
            const diffInMs = now - receivedTime;
            const diffInHours = diffInMs / (1000 * 60 * 60);
            return diffInHours;
        } catch (error) {
            console.error(error);
        }
    };

    const formatTime = () => {
        try {
            const diffInHours = getTimeDifference();
            if (diffInHours >= 48) {
                return dayjs(time).format(`DD/MM/YYYY [${i18n.language == 'en' ? 'at' : 'à'}] HH:mm`)
            }
            else if (diffInHours >= 24) {
                return i18n.language === 'en'
                    ? `Yesterday at ${dayjs(time).format('HH:mm')}`
                    : `Hier à ${dayjs(time).format('HH:mm')}`;
            } else {
                return dayjs(time).format('HH:mm')
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <span style={style} className={className}>
            {formatTime()}
        </span>
    );
}

export default PrettyTime
