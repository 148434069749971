import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'react-bootstrap';
import './ViralFeed.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { api } from '../../../api/api'
import Opinion from '../../Opinion/Opinion'
import { ShimmerSocialPost } from 'react-shimmer-effects'
import ShimmerPost from 'components/ShimmerPost/ShimmerPost'

const ViralFeed = () => {

    // STATE
    const { t, i18n } = useTranslation()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [opinions, setOpinions] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        fetchOpinions()
    }, [])

    const fetchOpinions = async () => {
        setIsLoading(true)
        try {
            const request = await api(`api/feed?orderBy=viral&page=${page}`, 'GET', {})
            const response = await request.json()
            // console.log('Response fetch opinions:', response)
            if (request.status === 200) {
                if (response.success) {
                    if (response?.opinions?.next_page_url) {
                        setHasMore(true)
                    } else {
                        setHasMore(false)
                    }
                    if (response?.opinions?.data?.lengh === 0) {
                        setIsEmpty(true)
                        return
                    }

                    // USE DATA
                    setPage(page => page + 1)
                    setOpinions(opinions => [...opinions, ...response?.opinions?.data])
                } else {

                }
            } else {

            }
            setIsLoading(false)
        } catch (error) {
            fetchOpinions()
            setIsLoading(false)
            console.warn(error.message);
        }
    };


    if (isLoading) {
        return (
            <div style={{ marginTop: '-10px' }}>
                <ShimmerPost />
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                    <div style={{ textAlign: 'center' }}>
                        <Spinner
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            variant='secondary'
                            style={{
                                width: '30px',
                                height: '30px'
                            }}
                        />
                        <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '13px' }}>
                            {t('loading_audience')}
                        </h6>
                    </div>
                </div> */}
            </div>
        )
    }


    if (opinions.length == 0) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>
                <div style={{ textAlign: 'center' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-16 mx-auto" style={{ color: '#c0c0c0' }}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 0 1-2.25 2.25M16.5 7.5V18a2.25 2.25 0 0 0 2.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 0 0 2.25 2.25h13.5M6 7.5h3v3H6v-3Z" />
                    </svg>
                    <h6 className='font-semibold' style={{ color: '#c0c0c0', marginTop: '5%', fontSize: '18px' }}>
                        {i18n.language == 'en'
                            ? 'No opinion available'
                            : 'Aucune opinion disponible'
                        }
                    </h6>
                </div>
            </div>
        )
    }


    const renderLoader = () => {
        return (
            <div style={{ marginTop: '-10px' }}>
                <ShimmerSocialPost type="both" />
            </div>
            // <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
            //     <div style={{ textAlign: 'center' }}>
            //         <Spinner
            //             size="sm"
            //             role="status"
            //             aria-hidden="true"
            //             animation="border"
            //             variant='secondary'
            //             style={{
            //                 width: '25px',
            //                 height: '25px'
            //             }}
            //         />
            //         <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
            //             {t('loading_opinion')}
            //         </h6>
            //     </div>
            // </div>
        )
    }



    return (
        <div id="viralScrollableDiv" className='viral-feed'>
            <InfiniteScroll
                dataLength={opinions.length}
                next={fetchOpinions}
                hasMore={hasMore}
                scrollableTarget="viralScrollableDiv"
                loader={renderLoader()}
                style={{
                    width: '100%',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}>

                {/* RENDER ITEM */}
                {opinions.map((item, index) => {
                    return (
                        <div key={index?.toString()}>
                            <Opinion
                                key={index}
                                opinion={item}
                                item={item}
                                index={index}
                                sourceFrom={'Feed'}
                            />
                        </div>
                    )
                })}

            </InfiniteScroll>
        </div>
    )
}

export default ViralFeed