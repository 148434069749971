import { scheme } from 'constants/env';
import firestorekeys from 'constants/firestorekeys';
import { collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { IoIosCloseCircle } from 'react-icons/io'
import { firestoreDB } from 'services/Firebase/firebaseConfig';

function Broadcasters({
    user,
    live
}) {

    const {i18n} = useTranslation()


    return (
        <div className="bg-black w-full h-52">
            <div
                id={`remote-stream-${user?.live_remote_uid}`}
                className='w-full'
                style={{ borderBottom: '1px solid #ffffff' }}>
            </div>

            {/* <div style={{
                position: 'absolute',
                marginTop: '-30px',
                marginLeft: '15px',
            }}>
                {user?.live_on_pause ?
                    <span className='text-white'>
                      {i18n.language == 'en' 
                      ? 'Live on pause'
                    : 'Live en pause'
                    }
                    </span>
                    :
                    <span className='text-white'>
                        {user?.user_surname} {user?.user_name}
                    </span>
                }
            </div> */}
        </div>
    )
}

export default Broadcasters