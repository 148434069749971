const firestorekeys = {
    channels: 'channels',
    lives: 'lives',
    calls: 'calls',
    comments: 'comments',
    requests: 'requests',
    audiences: 'audiences',
    likes: 'likes',
    recompenses: 'recompenses',
    conversations: 'conversations',
    messaging: 'messaging',
    participants: 'participants',
    messages: 'messages',
    private: 'private',
    public: 'public',
    generalPublic: 'general-public'
}

export default firestorekeys