import React, { useEffect, useState } from 'react'
import './LoginForm.style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'
import { api } from '../../api/api';
import { Link, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import Select from 'react-select'
import FacebookLogin from '@greatsumini/react-facebook-login';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';

// IMAGE
import logo from '../../assets/images/logo.png';
import ButtonSubmit from '../../components/ButtonSubmit/ButtonSubmit';
import TextInput from '../../components/TextInput/TextInput';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { ValidateEmail } from '../../hooks/Utils/parsing';
import ButtonGmail from '../../components/ButtonGmail/ButtonGmail/ButtonGmail';
import ButtonFacebook from '../../components/ButtonFacebook/ButtonFacebookUI/ButtonFacebook';
import Constants from '../../constants/Constants';
import AppleLogin from 'react-apple-login'
import ButtonApple from '../ButtonApple/ButtonApple';
import useReducerAuthAction from '../../hooks/ReducerHooksAction/useReducerAuthAction';
import ModelAlert from '../ModalAlert/ModelAlert';
import { Alert } from 'react-bootstrap';
import { color } from 'hooks/Utils/color';


const LoginForm = () => {

    const APP_VERSION = process.env.REACT_APP_VERSION

    // STATES
    const { t } = useTranslation()
    const navigate = useNavigate()
    const language = localStorage.getItem('language')

    const { LOGIN, UPDATE_LANGUAGE } = useReducerAuthAction()

    const [isLoading, setIsLoading] = useState(false)
    const [isGoogleLoading, setIsGoogleloading] = useState(false)
    const [isFacebookLoading, setIsFacebookLoading] = useState(false)
    const [isAppleLoading, setIsAppleLoading] = useState(false)

    const [showAlert, setShowAlert] = useState(false)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [IPAddress, setIPAddress] = useState('192.168.1.1');
    const languages = [
        {
            label: t('french_france'),
            value: 'fr'
        },
        {
            label: t('english_us'),
            value: 'en'
        },
    ]


    /* CHANGE LANGUAGE */
    const handleChangeLanguage = (value) => {
        i18next.changeLanguage(value)
        UPDATE_LANGUAGE(value)
    }


    /* CHECK FORM */
    const checkForm = async (e) => {
        e.preventDefault();
        if (!ValidateEmail(email.trim())) {
            setError(t('invalid_email'))
            return
        }
        if (password.length < 8) {
            setError(t('invalid_password'))
            return
        }
        setError('')
        handleSubmit()
    }


    /* GET IP ADDRESS */
    useEffect(() => {
        const fetchIPAddress = async () => {
            try {
                const response = await axios.get('https://geolocation-db.com/json/')
                // console.log(response.data)
                if (response.status === 200) {
                    setIPAddress(response.data.IPv4)
                }
            } catch (e) {
                console.warn(e);
            }
        }
        fetchIPAddress()
    }, [])


    const fetchGoogleAccount = useGoogleLogin({
        redirect_uri: process.env.REACT_APP_APP_URL,
        onSuccess: async (tokenResponse) => {
            console.log('response tokenResponse:', tokenResponse)
            try {
                setIsGoogleloading(true)
                const request = await axios.get('https://www.googleapis.com/userinfo/v2/me', {
                    headers: {
                        'Authorization': `Bearer ${tokenResponse.access_token}`
                    }
                })
                const response = request.data
                console.log('Response fetch google account:', response)
                if (request.status === 200) {
                    const provider = {
                        user_email: response?.email,
                        user_oauth_provider: 'google',
                        user_oauth_id: response?.id,
                        user_surname: response?.given_name,
                        user_name: response?.name,
                        prof_picture: response?.picture,
                        user_ip: IPAddress,
                    }
                    socialLogin(provider)
                } else {
                    setIsGoogleloading(false)
                    setError(t('no_internet_connection'))
                }
            } catch (error) {
                setIsGoogleloading(false)
                setError(t('no_internet_connection'))
                console.warn('Error fetch google account:', error.message);
            }
        },
    });


    const socialLogin = async (data) => {
        try {
            console.log('Form data social login:', data)
            const request = await api('api/social-login', 'POST', data)
            const response = await request.json()
            console.log('Response social login:', response)
            setIsGoogleloading(false)
            setIsFacebookLoading(false)
            if (request.ok && request.status === 200) {
                LOGIN(response)
            } else {
                setError(t('no_internet_connection'))
            }
        } catch (e) {
            // socialLogin(data)
            console.warn('Error login social:', e);
        }
    }


    /* LOGIN HANDLER */
    const handleSubmit = async (e) => {
        setIsLoading(true)
        try {
            const body = {
                user_email: email,
                user_password: password,
                user_ip: IPAddress,
            }
            const request = await api('api/login', 'POST', body)
            const response = await request.json()
            console.log(response)
            if (request.ok && request.status === 200) {
                LOGIN(response)
                setError('')
            } else {
                setError(t('incorrect_email_address_or_password'))
            }
            setIsLoading(false)
        } catch (error) {
            setError(t('an_error_occurred_please_try_again_later'));
            setIsLoading(false)
            console.warn(error.message);
        }
    };


    /* LOGIN APPLE */
    const appleResponse = (response) => {
        try {
            console.log('Response login apple:', response)
            if (!response.error && response?.user) {
                const provider = {
                    user_email: response?.user?.email?.toLowerCase(),
                    user_oauth_provider: 'apple',
                    user_oauth_id: response?.authorization?.code,
                    user_surname: response?.user?.firstName,
                    user_name: response?.user?.lastName,
                    prof_picture: '',
                    user_ip: IPAddress,
                }
                socialLogin(provider)
            } else {
                // setError(language === 'fr' ?
                //     'La connexion avec un compte apple est momentanément indisponible.' :
                //     'Logging in with an apple account is temporarily unavailable.'
                // )
            }
        } catch (error) {
            // setError(language === 'fr' ?
            //     'La connexion avec un compte apple est momentanément indisponible.' :
            //     'Logging in with an apple account is temporarily unavailable.'
            // )
            console.error(error);
        }
    };


    /* RENDER BUTTON APPLE */
    const renderAppleButton = () => {
        return (
            <AppleLogin
                clientId={process.env.REACT_APP_APPLE_ID_CLIENT}
                redirectURI={process.env.REACT_APP_AUTH_REDIRECT_URI}
                usePopup={false}
                // callback={appleResponse}
                scope="email name"
                responseMode="query"
                render={renderProps => (
                    <ButtonApple
                        onClick={renderProps.onClick}
                    />
                )}
            />
        )
    }


    /* RENDER BUTTON APPLE */
    const renderFacebookButton = () => {
        return (
            <div>
                {/* <LoginSocialFacebook
                    appId={Constants.FACEBBOK_APP_ID}
                    onResolve={(response) => {
                        console.log('Get Profile Success!', response.data);
                        const provider = {
                            user_email: response.data?.email?.toLowerCase(),
                            user_oauth_provider: 'facebook',
                            user_oauth_id: response.data?.id,
                            user_surname: response.data?.name,
                            user_name: '',
                            prof_picture: response.data?.picture?.data?.url,
                            user_ip: IPAddress,
                        }
                        setIsFacebookLoading(true)
                        socialLogin(provider)
                    }}
                    onReject={(error) => {
                        console.log('Login Failed!', error);
                    }}>

                    <FacebookLoginButton />

                </LoginSocialFacebook> */}

                <FacebookLogin
                    appId={Constants.FACEBBOK_APP_ID}
                    autoLoad={false}
                    useRedirect={false}
                    onSuccess={(response) => {
                        console.log('Login Success!', response);
                    }}
                    onFail={(error) => {
                        setIsFacebookLoading(false)
                        console.erreur('Login Failed!', error);
                    }}
                    onProfileSuccess={(response) => {
                        console.log('Get Profile Success!', response);
                        const provider = {
                            user_email: response?.email?.toLowerCase(),
                            user_oauth_provider: 'facebook',
                            user_oauth_id: response?.id,
                            user_surname: response?.name,
                            user_name: '',
                            prof_picture: response?.picture?.data?.url,
                            user_ip: IPAddress,
                        }
                        socialLogin(provider)
                    }}
                    render={({ onClick, logout }) => (
                        <div style={{ marginBottom: '5%' }}>
                            <ButtonFacebook
                                onClick={() => {
                                    setIsFacebookLoading(true)
                                    onClick()
                                }}
                                isLoading={isFacebookLoading}
                            />
                        </div>
                    )}
                />

            </div>
        )
    }


    return (
        <div className='loginform'>
            <div className='form'>
                <img src={logo} className='logo-myopinion' alt='' />
                <form onSubmit={checkForm} >

                    {/* <div className='error-message'>
                        {error}
                    </div> */}

                    {error &&
                        <Alert variant="danger" style={{ marginBottom: '20px' }}>{error}</Alert>
                    }

                    <TextInput
                        value={email}
                        setValue={setEmail}
                        type='text'
                        placeholder={t('email')}
                        style={{
                            width: '100%',
                            marginBottom: '5%'
                        }}
                    />

                    <TextInput
                        value={password}
                        setValue={setPassword}
                        type='password'
                        placeholder={t('password')}
                        style={{
                            width: '100%',
                            marginBottom: '8%'
                        }}
                    />

                    <ButtonSubmit
                        value={t('login')}
                        isLoading={isLoading}
                        loadBoostrapColor={'light'}
                        style={{
                            width: '100%',
                            color: 'white',
                            marginBottom: '5%',
                            backgroundColor: '#1880a1'
                        }}
                    />

                </form>

                <Link to={'/forget-password'} className='forget-password-text'>
                    {t('forgot_your_password')}
                </Link>

                <p className='connect-with'>
                    - {t('connect_with')} -
                </p>


                <div className='social'>

                    {/* RENDER BUTTON GOOGLE */}
                    <ButtonGmail
                        onClick={() => fetchGoogleAccount()}
                        isLoading={isGoogleLoading}
                        style={{ marginBottom: '5%' }}
                    />

                    {/* RENDER BUTTON FACEBOOK */}
                    {/* {renderFacebookButton()} */}

                    {/* RENDER BUTTON APPLE */}
                    {renderAppleButton()}

                </div>

                <p className='connect-with'>
                    - {t('or')} -
                </p>

                <ButtonSubmit
                    onClick={() => navigate('/register')}
                    value={t('create_an_account')}
                    loadBoostrapColor={'light'}
                    style={{
                        width: '100%',
                        color: 'white',
                        marginBottom: '5%',
                        backgroundColor: '#1880a1'
                    }}
                />

                <Select
                    placeholder='Langue'
                    options={languages}
                    isSearchable={false}
                    defaultValue={languages.filter(items => items.value === language)}
                    onChange={newValue => handleChangeLanguage(newValue.value)}
                    className='select-language'
                />

                <div className='terms-conditions'>
                    {/* <a style={{ fontSize: '13px', marginRight: '5px' }}
                        href={language === 'en' ? 'https://myopinion.ci/legal/en/terms-and-conditions/' : 'https://myopinion.ci/legal/fr/conditions-generales/'}>
                        {t('terms_and_conditions')}
                    </a>

                    <a style={{ fontSize: '13px', marginLeft: '5px' }}
                        href={language === 'en' ? 'https://myopinion.ci/legal/en/privacy-policy/' : 'https://myopinion.ci/legal/fr/politique-de-confidentialite/'}>
                        {t('privacy_policy')}
                    </a> */}

                    {language === 'fr' ?
                        <p className='policy'>
                            En me connectant je confirme avoir lu et acceptez
                            <span style={{ color: color.primary, cursor: 'pointer', fontWeight: 'bold' }} onClick={() => navigate(language === 'en' ? '/legal/en/terms-and-conditions/' : '/legal/fr/conditions-generales/')}> les conditions générales</span> et
                            <span style={{ color: color.primary, cursor: 'pointer', fontWeight: 'bold' }} onClick={() => navigate(language === 'en' ? '/legal/en/privacy-policy/' : '/legal/fr/politique-de-confidentialite/')}> la politique de confidentialité</span>.
                        </p>

                        :
                        <p className='policy'>
                            By logging in I confirm that I have read and accept
                            <span style={{ color: color.primary, cursor: 'pointer', fontWeight: 'bold' }} onClick={() => navigate(language === 'en' ? '/legal/en/terms-and-conditions/' : '/legal/fr/conditions-generales/')}> the general conditions</span> and
                            <span style={{ color: color.primary, cursor: 'pointer', fontWeight: 'bold' }} onClick={() => navigate(language === 'en' ? '/legal/en/privacy-policy/' : '/legal/fr/politique-de-confidentialite/')}> conditions and the privacy policy</span>.
                        </p>
                    }
                    <span style={{ color: color.primary, cursor: 'pointer', fontWeight: 'bold', marginTop: '10px' }} onClick={() => navigate('/support')} className='policy'>
                        {language === 'en'
                            ? 'MyOpinion help page (Support)'
                            : 'Page d\'aide MyOpinion  (Support)'
                        }
                    </span>
                </div>

                <p className='copyrigth'>
                    MyOpinion © version {APP_VERSION} - {new Date().getFullYear()}
                </p>
            </div>

            <ModelAlert
                message={language === 'fr' ? 'Bientôt disponible.' : 'coming soon.'}
                show={showAlert}
                setShow={setShowAlert}
            />
        </div>
    )
}

export default LoginForm