const en = {
    translation: {
        'app_name': 'MyOpinion',
        "download_myopinion": "Téléchargez Myopinion",
        "with_myopinion_share_and_stay": "The world is listening.",
        "login_now_to_interact_with_thousands_of_people": "Login now to interact with millions of people.",
        "connect_with": "Connect with",
        "login_with_gmail": "Login with Gmail",
        "login_with_facebook": "Login with facebook",
        "login_with_apple": "Login with Apple",
        "create_an_account": "Create an account",
        "continue": "Continue",
        "reset": "Reset",
        "or": "or",
        "login": "Login",
        "email": "Email",
        "password": "Password",
        "new_password": "New password",
        "confirm_password": "Confirm password",
        "forgot_password": "Forgot your password",
        "forgot_your_password": "Forgot your password ?",
        "please_enter_your_account_email_below_to_reset_it": "Please enter your account email below to reset it.",
        "terms_and_conditions": "Terms and Conditions",
        "privacy_policy": "Privacy Policy",
        'french_france': "French (France)",
        'english_us': "English (US)",
        "link_terms_of_use": "https://payesha.com/legal/en/conditions-generales.html",
        "link_privacy_policy": "https://payesha.com/legal/en/conditions-generales.html",
        "incorrect_email_address_or_password": "Incorrect email address or password.",
        "an_error_occurred_please_try_again_later": "An error occurred, please try again later.",
        "trend_for_you": "Trend for you",
        "enter_your_new_password": "Enter your new password",

        /*================ REGISTER */
        "sign_up": "Sign up",
        "you_do_not_have_an_account": "You do not have an account ? Create your account, it takes less than a minute",
        "name": "Name",
        "surname": "Surname",
        "nickname": "Nickname",
        "civility": "Civility",
        "phone_number": "Phone number",
        "city": "City",
        "country": "Country",
        "register": "Register",
        "confirm": "Confirm",
        "confirmation_code": "Confirmation code",
        "account_verification": "Account verification",
        "a_6_digit_code_has_just_been_sent": "A 6-digit code has just been sent to your email address",
        "if_you_dont_see_the_email_look_in_your_spam": "If you don't see the email, look in your spam.",
        "password_reset": "Password reset",

        /*============ ERROR INPUT */
        "your_surname_must_contain_at_least_4_characters": "Your surname must contain at least 4 characters.",
        "your_nickname_must_contain_at_least_4_characters_only_underscore": "Your nickname must contain at least 4 characters, the underscore (_) is allowed.",
        'invalid_confirmation_code': "Invalid confirmation code.",
        'invalid_confirmation_code_at_least_6_characters': "Invalid confirmation code, the confirmation code must contain at least 6 characters.",
        'invalid_email': "Invalid email.",
        'invalid_password': "Invalid password, your password must contain at least 8 characters.",
        'passwords_do_not_match': 'passwords do not match.',

        /*============== ERROR REQUEST */
        "the_email_is_already_taken": "The email is already taken",
        "no_internet_connection": "No internet connection, check your internet connection and try again.",
        "this_email_does_not_have_an_account": "This Email does not have an account.",

        /*============ OPINIONS */
        audience: "Audience",
        public: "Public",
        recent: 'Recent',
        loading_recent: 'Loading Recent...',
        random: 'Random',
        forYou: 'For you',
        loading_random: 'Loading Random...',
        loading_for_you: 'Loading for you...',
        viral: 'Viral',
        loading: 'Loading...',
        loading_viral: 'Loading Viral...',
        loading_audience: 'Loading Buddies...',
        loading_opinion: 'Loading opinion...',
        loading_profile: 'Loading profile...',

        //========= PROFILE
        profile: 'Profile',
        setting: 'Setting',
        language: 'Language',
        account: 'Account',
        update: 'Update',
        update_your_login_settings_associated_with_your_account: 'Update your connection settings associated with your account.',

        buddy: "Buddy",
        buddies: "Buddies",
        become_buddy: 'Become buddy',
        you_are_buddy: 'You are buddy',


        fan: 'Fan',
        fans: 'Fans',
        fan_of: 'Fan of',
        become_fan: 'Become fan',
        you_are_fan: 'You are fan',
        approve: 'Accept',
        approveRequest: 'Accept request',
        Received: 'Received',
        request: 'Request',
        sent: 'Sent',
        cancel: ' Cancel',
        block: 'Block',
        blocked: 'Blocked',
        unlock: 'Unlock',
        no_buddy: 'No buddy',




        //========== BADGE

        /* Goal */
        goal: 'Life goal',
        love: 'Love',
        god: 'God',
        money: 'Money',
        power: 'Power',
        fame: 'Fame',
        family: 'Family',
        humanitary: 'Humanitarian',
        patriot: 'Patriot',
        peace: 'Peace',
        freedom: 'Freedom',
        saveThePlanet: 'Save The Planet',

        /* Business */
        business: 'Business',
        technology: 'Technology',
        finance: 'Finance',
        arts: 'Arts',
        health: 'Health',
        foodIndustry: 'Food industry',
        education: 'Education',
        law: 'Law',
        restaurant: 'Catering',
        communication: 'Communication',
        services: 'Services',
        proAthlete: 'Pro-Athlete',
        agriculture: 'Agriculture',


        /* Hooby */
        hobby: 'Hobby',
        travel: 'Travel',
        sport: 'Sport',
        cinema: 'Cinema',
        nightLife: 'Dance night',
        communityLife: 'Community life',
        art: 'Art',
        gardening: 'Gardening',
        hike: 'Hiking',
        hunting: 'Hunting',
        fishing: 'Fishing',
        music: 'Music',
        videoGame: 'Video game',


        /* Food */
        food: 'Food',
        vegetarian: 'Vegetarian',
        meat: 'Meat',
        fish: 'Fish',
        bread: 'Bread',
        cake: 'Cake',
        pasta: 'Pasta',
        soup: 'Soup',
        seaFood: 'Sea food',
        cereals: 'Cereals',
        dairy: 'Dairy',
        rice: 'Rice',
        wildMeat: 'Wild meat',

        /* Color */
        color: 'Color',
        red: 'Red',
        blue: 'Blue',
        green: 'Green',
        yellow: 'Yellow',
        orange: 'Orange',
        black: 'Black',
        white: 'White',
        purple: 'Purple',
        pink: 'Pink',
        brown: 'Brown',
        grey: 'Gray',
        cyan: 'Cyan',
        beige: 'Beige',

        select_at_least_one_badge_per_category: 'Select at least one badge per category.',
        you_cannot_modify_your_badges: 'You cannot update your badges because you last updated it less than 30 days ago.',

        edit_profile: 'Edit profile',
        edit: 'Edit',
        profile_title: 'Profile title',
        profile_description: 'Profile description',
        profile_email: 'Profile Email',
        web_site: 'Web site',

        subscribe_to_email_notification: 'Unsubscribe to email notification',
        yes: 'Yes',
        no: 'No',
        security_and_password: 'Security and password',
        balance: 'Balance',
        subscriptions: 'Subscriptions',

        current_password: 'Current password',
        new_password: 'New password',
        confirmation_of_new_password: 'Confirmation of new password',
        your_current_password_is_incorrect: 'Your current password is incorrect.',
        your_password_has_been_updated: 'Your password has been updated.',

        coming_soon: 'Coming soon',
        reload: 'Reload',
        collect: 'Collect',
        no_transactions_available: 'No transactions available',

        buying_coins: 'Digital Gifts',
        verified_account: 'Verified account',
        premium_account: 'Premium account',
        sponsored_opinion: 'Sponsored opinion',
        send_gift_to: 'Send gift to',
        gift_received_from: 'Gift received from',
        monthly_subscription: 'Monthly Subscription',
        annual_subscription: 'Annual subscription',

        // create opinion
        create_opinion: 'Create opinion',
        activate_vote: 'Activate vote',
        enter_your_opinion: 'Enter your opinion',
        publishing_in_progress: 'Publishing in progress...',
        add_to_your_opinion: 'Add to your opinion',
        publish: 'Publish',
        choice: 'Choice',
        mandatory: 'mandatory',
        you_must_add_a_description_to_your_opinion: 'You must add a description to your opinion.',
        the_first_two_choices_are_mandatory: 'The first two choices are mandatory.',
        video_too_large_please_select_a_video_less_than_50mb: 'Video too large, please select a video less than 50mb.',
        coming_soon: 'Coming soon.',

        mute: 'Mute',
        unmute: 'Unmute',
        report: 'Report',
        download: 'Download',
        delete: 'Delete',
        available: 'Available',
        unavailable: 'Unavailable',
        sorry: 'Sorry',

        // REPORT
        opinionTitle: 'opinion',
        commentTitle: 'comment',
        conversationTitle: 'conversation',
        reportOpinion: {
            sexualContent: 'Sexual content',
            violent: 'Violent or repulsive content',
            hateful: 'Hateful or abusive content',
            harassment: 'Harassment or bullying',
            harmful: 'Harmful or dangerous acts',
            childAbuse: 'Child abuse',
            infrigeRight: 'Infringes my rights',
            terrorism: 'Promotes terrorism',
            spam: 'Spam or misleading',
        },
        reportComment: {
            commercial: 'Unwanted commercial content or spam',
            sexual: 'Pornography or sexual explicit material',
            childAbuse: 'Child abuse',
            hateSpeech: 'Hate speech or graphic violence',
            harassment: 'Harassment or bullying',
        },
        report: 'Report',
        other: 'Other',

        responseReportTitle: 'We are sorry you had this bad experience on MyOpinion.',
        responseReportDescription:
            'Your report has successfully been sent. We will process it and take further actions if necessary.',

        responseOpinionTitle: 'Opinion reported',
        responseOpinionDescription:
            'We will review and take further actions if necessary.',

        responseCommentTitle: 'Comment reported',
        responseCommentDescription:
            'We will review and take further actions if necessary.',

        responseConversationTitle: 'Conversation reported',
        responseConversationDescription:
            'We will review and take further actions if necessary.',

        enter_the_reason: 'Enter the reason...',


        opinion_for_android: 'Opinion for Android',
        opinion_for_ios: 'Opinion for IOS',
        opinion_for_huawei: 'Opinion for Huawei',
        opinion_for_web: 'Opinion for Web',
        opinion_for: 'Opinion for',

        search: 'Search',
        people: 'People',
        search_by_badge: 'Search by badge',
        search_by_category: 'Search by category',
        people_you_match: 'People you match',

        my_agoras: 'My Agoras',
        private_agora: 'private Agora',
        public_agora: 'Public Agora',
        agora_name: 'Agora name',
        agora_description: 'Agora description',
        my_agoras: 'My Agoras',
        my_requests: 'My requests',
        my_invites: 'My invites',
        create_agora: 'Create Agora',
        create: 'Create',
        title: 'Title',
        participate: 'Participate',
        agora_you_might_be_interested_in: 'Agora you might be interested in',
        show_your_last_seen: 'Show your last seen',
        hide_your_last_seen: 'Hide your last login',
        delete_all_messages: 'Delete all messages',
        write_your_message: 'Write your message',
        member: 'Member',
        members: 'Members',
        view_all: 'View all',
        publish_in_the_agora: 'Publish in the agora',
        enter_your_group_information: 'Enter your Agora information',
        request_to_participate: 'Request to participate',
        list_of_members: 'List of members',

        // ADD PAGE
        my_accounts: 'My accounts',
        add_an_account: 'Add an account',
        login_to: 'Login to',
        delete_this_account: 'Delete this account',
        this_account_has_already_been_added: 'This account has already been added.',

        // CREATE PAGE
        my_pages: 'My pages',
        create_a_page: 'Create a page',
        create_a_page_description: 'Your colleagues come to your Page to learn more about you. Please enter any information they may require.',


        pageName: 'Page name',
        pageNameError: 'Enter the page name',
        pageNickname: 'Page nickname',
        pageNicknameError: 'Entrer the page nickname',
        descriptionOfThePage: 'Description of the page',
        descriptionOfThePageError: 'Enter the page description',
        emailOfThePage: 'Email of the page',
        emailOfThePageError: 'Enter the Email of the page',
        country: 'Country',
        countryError: 'Select a country',
        phone: 'Phone',
        category: 'Category',
        categories: 'Categories',
        webSite: 'Website',
        createPage: 'Create page',
        myPages: 'My pages',
        pageCreated: 'Page created',
        pageCreatedSuccessfully: 'Page created successfully.',
        thisNicknameIsAlreadyInUse: "This nickname is already in use.",

        loginTo: 'Login to',
        pageSetting: 'Page settings',

        inviteAnAdministrator: 'Invite an administrator',
        inviteAnAdministratorDesc: 'Send a moderation proposal to a user.',

        listOfModerationRequests: 'List of moderation requests',
        listOfModerationRequestsDesc: 'Management of moderation requests.',

        noModerationRequestAvailable: 'No moderation request available',
        noUserFound: 'No user found, launch a search.',
        search: 'Search...',
        selectARole: 'Select a role.',
        inviteConfirmMessage: 'Would you like to send a moderation request to this user ?',
        inviteSentMessageTitle: 'Moderation request sent',
        inviteSentMessageDesc: 'Moderation request sent successfully.',
        inviteErrorMessage: 'Moderation request already sent to this user.',
        invited: 'Invited',
        invite: 'Invite',
        confirm: 'Confirm',
        cancel: 'Cancel',
        delete: 'Delete',
        deleteRequest: 'Delete request',
        deleteRequestDesc: 'Are you sure you want to delete this request ?',

        moderationConfirmMessage: 'sent you a moderation request, would you like to be a moderator of this page ?',
        moderationRequestAccepted: 'Moderation request accepted',
        moderationRequestAcceptedDesc: 'Moderation request accepted successfully.',

        no_data_available: 'No data available',

        start: 'Start',
        start_a_live: 'Start a live',
        no_live_in_progress: "No live in progress",
        you_dont_have_a_live_in_progress: 'You don\'t have a live in progress',
        write_a_description: 'Write a description...',
        upload_a_file: 'Upload a file',
        or_drag_and_drop: 'or drag and drop',
        new_group: 'New group',
        invitation_list: 'Invitation list',
        group_image: 'Group image',
        group_name: 'Group name',
        enter_the_group_name: 'Enter the group name',
        group_description: 'Group description',
        enter_the_group_description: 'Enter the group description',
        create_group: 'Create group',
        accept: 'Accept',
        refuse: 'Refuse',
        live_unavailable: 'Live indisponible',
        Go_to_home_page: 'Go to home page'


    }
}
export default en;