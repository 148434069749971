import React, { useEffect, useRef, useState } from 'react'
import { FaRegPlayCircle } from 'react-icons/fa'

const SondVideo = ({
    uri,
    thumbnail,
    width = '100%',
    height = '400px',
}) => {

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false); // Start paused
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
            //   videoRef.current.play();
            //   videoRef.current.muted = true;
            } else {
              videoRef.current.pause();
            }
          },
          { threshold: 0.5 } // La vidéo doit être à 50% visible pour être jouée
        );
    
        if (videoRef.current) {
          observer.observe(videoRef.current);
        }
    
        return () => {
          if (videoRef.current) {
            observer.unobserve(videoRef.current);
          }
        };
      }, []);


    const onPlay = () => {
        setIsPlaying(true);
        videoRef.current.play();
    }

    const onPause = () => {
        setIsPlaying(false);
        videoRef.current.pause();
    }

    return (
        <div>

            {/* Video player */}
            <video
                ref={videoRef}
                src={uri}
                controls={isPlaying}
                style={{
                    width: '100%',
                    maxHeight: height,
                    objectFit: 'contain',
                    borderRadius: '5px',
                    backgroundColor: 'black',
                    overflow: 'hidden',
                    position: 'relative',
                }}
                className={`${isPlaying ? 'block' : 'hidden'}`}
            />

            {/* Video thumbnail */}
            {!isPlaying &&
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: `url(${thumbnail}) no-repeat center`,
                        backgroundColor: 'transparent',
                        width: 'auto',
                        height: height,
                    }}>
                    <div onClick={onPlay} style={{ cursor: 'pointer' }}>
                        <FaRegPlayCircle size={60} color='white' />
                    </div>
                </div>
            }
        </div>
    )
}

export default SondVideo;