import ButtonSubmit from 'components/ButtonSubmit/ButtonSubmit'
import React, { useEffect, useRef, useState } from 'react'
import styles from './ChatIncomingCall.module.css'
import firestorekeys from 'constants/firestorekeys'
import { scheme } from 'constants/env'
import { firestoreDB } from 'services/Firebase/firebaseConfig'
import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { useLocation, useNavigate } from 'react-router-dom'
import audio from '../../assets/audios/incoming-call.mp3'
import { useTranslation } from 'react-i18next'

const ChatIncomingCall = () => {

    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { data: authReducerState } = useAuthReducer()
    const [callData, setCallData] = useState(null)
    const [visible, setVisible] = useState(false)

    const audioRef = useRef(null);



    const goToIncomingCall = () => {
        navigate(`/one-to-one/${callData?.conversation_id}/incoming-call`, {
            state: {
                channel: callData?.call_channel_name,
                token: callData?.call_code,
                type: callData?.call_type,
                data: callData
            }
        })
    }

    useEffect(() => {
        if (!scheme || !firestorekeys || !authReducerState?.user?.user_id) return;

        // Reference the document
        const docRef = doc(
            firestoreDB,
            scheme,
            firestorekeys.calls,
            firestorekeys.channels,
            authReducerState?.user?.user_id?.toString()
        );

        // Listen for real-time updates
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
            if (docSnap.exists()) {
                console.log(docSnap.data())
                setCallData(docSnap.data())
                if (docSnap.data()?.call_finished) {
                    setVisible(false)
                } else {
                    setVisible(true)
                }
            } else {
                setVisible(false)
            }
        });

        // Cleanup listener on unmount
        return () => unsubscribe();
    }, [authReducerState?.user?.user_id]);


    useEffect(() => {
        try {
            if (visible) {
                // Joue le son lorsque l'appel entrant est détecté
                audioRef.current.play().catch((error) => {
                    console.error("Erreur lors de la lecture du son :", error);
                });
            } else {
                // Met en pause si l'appel est accepté ou refusé
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Réinitialiser la lecture
            }
        } catch (error) {
            console.error(error);

        }
    }, [visible]);


    const handleDecline = async () => {
        try {
            // Finish firestore call
            const docRef = doc(
                firestoreDB,
                scheme,
                firestorekeys.calls,
                firestorekeys.channels,
                authReducerState?.user?.user_id?.toString()
            );

            await updateDoc(docRef, { call_finished: true }).then(() => {
                console.log('Call finished to firstore.');
            }).catch((error) => {
                console.error('Error Call finished:', error);
            });
        } catch (error) {
            console.error(error);
        }
    }


    if (!visible || location?.pathname?.includes('incoming-call')) {
        return (
            <div>
            </div>
        )
    }

    return (
        <div className={`${styles.container} flex justify-center text-white absolute top-0 p-3`}>
            <audio ref={audioRef} src={audio} loop className='hidden' />
            <div className={`${styles.content} p-3 rounded shadow-xl `}>

                <div className='flex items-center justify-between'>
                    <div className='me-3'>
                        <p className='text-xl font-semibold mb-1'>Joel parfait</p>
                        <div className='flex justify-end items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                            </svg>
                            <p className='ms-2'>
                                {i18n.language
                                    ? 'Appel entrant...'
                                    : 'Incoming call...'
                                }
                            </p>
                        </div>

                    </div>
                    {callData?.caller?.profile?.prof_picture ?
                        <img src={callData?.caller?.profile?.prof_picture} alt='' style={{ width: 50, height: 50, borderRadius: 50 }} />
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-12">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                    }

                </div>


                <div className='flex items-center mt-3'>
                    <button onClick={handleDecline} className='bg-red-600 px-3 py-1.5 font-semibold rounded me-5'>
                        {i18n.language
                            ? 'Decline'
                            : 'Raccrocher'
                        }
                    </button>

                    <button onClick={goToIncomingCall} className='bg-emerald-600 px-3 py-1.5 font-semibold ms-auto rounded'>
                        {i18n.language
                            ? 'Accept'
                            : 'Répondre'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChatIncomingCall