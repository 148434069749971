import React, { memo, useEffect, useMemo, useState } from 'react'
import styles from './Live.module.css'
import background from 'assets/images/bg_clips.jpg'
import { MdLiveTv } from 'react-icons/md'
import { Link, useNavigate } from 'react-router-dom'
import LiveCreate from './Componants/LiveCreate/LiveCreate'
import { useTranslation } from 'react-i18next'
import { firestoreDB } from 'services/Firebase/firebaseConfig'
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import firestorekeys from 'constants/firestorekeys'
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { MdAdsClick } from "react-icons/md";
import avatar from 'assets/images/background_opinion.jpg'
import { FiChevronsDown } from "react-icons/fi";
import { scheme } from 'constants/env'
import LiveCard from './Componants/LiveCard/LiveCard'
import Header from 'components/Header/Header'
import { useCallback } from 'react'

const Live = () => {

    const { t, i18n } = useTranslation()
    const [tabIndex, setTabIndex] = useState(0);
    const { data: authContextState } = useAuthReducer()
    const navigate = useNavigate()

    const [lives, setLives] = useState([])
    const [liveBuddies, setLiveBuddies] = useState([])
    const [showCreateLive, setShowCreateLive] = useState(false)

    const handleShowCreateLive = () => setShowCreateLive(oldValue => !oldValue)

    const handleTabChange = (index) => {
        setTabIndex(index);
    };


    // Live for you snapshot
    useEffect(() => {
        const q = query(
            collection(
                firestoreDB,
                scheme,
                firestorekeys.lives,
                firestorekeys.channels
            ),
            where("live_finished", "==", false)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            // Get the documents, and transform them into an array of objects
            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

            // Filter and Sort the list by live_start_time
            const sortedList = data?.filter(item => {
                if (item?.live_visibility === 'private') {
                    return item?.live_private_audience?.includes(authContextState?.user?.user_id);
                }
                return true;
            })

            // Set lives
            setLives(sortedList)

        });
        return () => {
            unsubscribe();
        }
    }, []);

    // Live buddies snapshot
    useEffect(() => {

        // Filter and Sort the list by live_start_time
        const sortedList = lives?.filter(item =>
            item?.live_private_audience?.includes(authContextState?.user?.user_id)
        ).sort((a, b) =>
            new Date(b.live_start_at) - new Date(a.live_start_at)
        );

        setLiveBuddies(sortedList)

    }, [lives]);



    const RenderEmptyComponant = memo(() => {
        return (
            <div
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                className='w-full min-h-screen overflow-hidden flex items-center justify-center pb-24 bg-black border-b-4 border-b-gray-200'>

                <div className='text-center'>
                    <MdLiveTv
                        color='white'
                        size={68}
                        className='mx-auto'
                    />
                    <p className='text-white my-3 text-center'>
                        {t('no_live_in_progress')}
                    </p>
                    <Link onClick={handleShowCreateLive} className='text-white font-bold text-center bg-orange-500 py-2 px-3 ms-auto rounded-full'>
                        {t('start_a_live')}
                    </Link>
                </div>

            </div>
        )
    }, [])


    return (
        <div className='max-h-screen bg-white'>

            {/* TAB */}
            <div className={styles.tab}>
                <span style={{ paddingBottom: '10px' }} onClick={() => handleTabChange(0)} className={tabIndex === 0 ? styles.tabLabelActive : styles.tabLabel}>
                    Live {t('buddies')?.toLocaleLowerCase()}
                </span>
                <span style={{ paddingBottom: '10px' }} onClick={() => handleTabChange(1)} className={tabIndex === 1 ? styles.tabLabelActive : styles.tabLabel}>
                    Live {t('forYou')?.toLocaleLowerCase()}
                </span>
            </div>


            {/* TAB PANEL */}
            {/* Live for you pager */}
            <div className={tabIndex === 0 ? styles.tabViewActive : styles.tabView}>
                {lives?.length != 0 ?
                    lives.map((item, index) => {
                        return (
                            <div key={index}>
                                <LiveCard
                                    live={item}
                                />
                            </div>
                        )
                    })
                    :
                    <RenderEmptyComponant />
                }
            </div>

            {/* Live for you pager */}
            <div className={tabIndex === 1 ? styles.tabViewActive : styles.tabView}>
                {liveBuddies?.length != 0 ?
                    liveBuddies.map((item, index) => {
                        return (
                            <div key={index}>
                                <LiveCard
                                    live={item}
                                />
                            </div>
                        )
                    })
                    :
                    <RenderEmptyComponant />
                }
            </div>

            {/* Modal start live */}
            <LiveCreate
                visible={showCreateLive}
                onChangeVisible={handleShowCreateLive}
            />

        </div>
    )
}

export default Live