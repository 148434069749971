import React, { useState } from 'react'
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { api } from '../api/api';
import ModalEditBadges from '../components/ModalEditBadge/ModalEditBadge';
import { useAccessToken, useAuthReducer, useNotificationReducer, useOneToOneReducer } from '../hooks/ReducerHooks/ReducerHooks';
import useReducerAuthAction from '../hooks/ReducerHooksAction/useReducerAuthAction';
import AppLayout from '../pages/AppLayout/AppLayout';
import Home from '../pages/home/Home'
import Profile from '../pages/Profile/Profile';
import ChangePassword from '../pages/Profile/ChangePassword/ChangePassword';
import Public from '../pages/public/Public';
import Transactions from '../pages/Profile/Transactions/Transactions';
import OpinionDetails from '../pages/OpinionDetails/OpinionDetails';
import PrivacyPolicy from '../pages/legal/en/PrivacyPolicy';
import TermsConditions from '../pages/legal/en/TermsConditions';
import PolitiqueConfidentialite from '../pages/legal/fr/PolitiqueConfidentialite';
import ConditionsGenerales from '../pages/legal/fr/ConditionsGenerales';
import useReducerNotifAction from '../hooks/ReducerHooksAction/useReducerNotifAction';
import Search from '../pages/search/Search';
import SearchBadge from '../pages/SearchBadge/SearchBadge';
import useReducerPeopleMatchAction from '../hooks/ReducerHooksAction/useReducerPeopleMatchAction';
import useReducerOneToOneAction from '../hooks/ReducerHooksAction/useReducerOneToOneAction';
import PeopleMatchCarousel from '../components/PeopleMatchCarousel/PeopleMatchCarousel';
import PeopleYouMatch from '../pages/PeopleYouMatch/PeopleYouMatch';
import Notifications from '../pages/Notifications/Notifications';
import AddAccount from 'pages/Profile/Settings/screens/AddAccount/AddAccount';
import PageSetting from 'pages/Profile/Settings/screens/PageSetting/PageSetting';
import Settings from 'pages/Profile/Settings/screens/Settings';
import Agora from 'pages/Agora/Agora';
import AgoraDetails from 'pages/Agora/AgoraDetails/AgoraDetails';
import AgoraMembers from 'pages/Agora/AgoraMembers/AgoraMembers';
import SearchCategory from 'pages/SearchCategory/SearchCategory';
import CreatePage from 'pages/Profile/Settings/screens/CreatePage/CreatePage';
import ModerationRequests from 'pages/Profile/Settings/screens/ModerationRequests/ModerationRequests';
import SendModerationRequest from 'pages/Profile/Settings/screens/SendModerationRequest/SendModerationRequest';
import Audience from 'pages/Profile/Audience/Audience';
import MyAudience from 'pages/Profile/Audience/MyAudience';
import Support from '../pages/Support/Support';
import Contact from 'pages/Messaging/Contacts/Contact';
import OneToOne from 'pages/Messaging/OneToOne/OneToOne';
import OneToOneMessage from 'pages/Messaging/OneToOneMessage/OneToOneMessage';
import OneToOneProfil from 'pages/Messaging/OneToOneProfil/OneToOneProfil';
import GroupCreate from 'pages/Messaging/GroupChat/GroupCreate/GroupCreate';
import GroupInvitations from 'pages/Messaging/GroupChat/GroupInvitations/GroupInvitations';
import GroupInvite from 'pages/Messaging/GroupChat/GroupInvite/GroupInvite';
import Live from 'pages/Live/Live';
import LiveStream from 'pages/Live/LiveStream/LiveStream';
import LiveJoin from 'pages/Live/LiveJoin/LiveJoin';
import Calling from 'pages/Messaging/LiveCall/Calling/Calling';
import IncomingCall from 'pages/Messaging/LiveCall/IncomingCall/IncomingCall';
import { localStorageKeys, localStorageSetItem } from 'store/localstorage';
import TransactionDetails from 'pages/Profile/Transactions/TransactionDetails/TransactionDetails';
// import { NextSeo } from 'next-seo';


function AppStack() {

    // STATE
    const accessToken = useAccessToken()
    const { data: authData } = useAuthReducer()
    const { data: notifData } = useNotificationReducer()
    const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()
    const { UPDATE_NOTIFICATIONS_NUMBER, REFRESH_NOTIFICATIONS, ADD_NOTIFICATIONS } = useReducerNotifAction()
    const { REFRESH_MESSAGE_NUMBER, REFRESH_CONVERSATION, REFRESH_BUDDIES } = useReducerOneToOneAction()
    const { REFRESH_PEOPLE } = useReducerPeopleMatchAction()
    const [showEditBadge, setShowEditBadge] = useState(false)

    const controller = new AbortController();
    const signal = controller.signal

    // HANDLE
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const request = await api('api/get-user', 'GET', {}, { ...accessToken, ...signal })
                const response = await request.json()
                console.log('Response fetch user:', response?.user)
                if (request.ok && request.status === 200) {
                    UPDATE_PROFILE(response?.user)
                    if (!response?.user?.user_badge_goal) {
                        setShowEditBadge(true)
                    }
                } else if (request.status === 401 || request.status === 403) {
                    LOGOUT()
                }
            } catch (e) {
                fetchUser()
                console.warn('Error fetch user info:', e.message)
                throw new Error(e)
            }
        }
        fetchUser()
        return () => controller.abort()
    }, []);



    //  FETCH AUDIENCE
    useEffect(() => {
        const fetchAudience = async () => {
            try {
                const request = await api(`api/user/audience/${authData?.user?.user_id}`, 'GET', null, { ...accessToken, ...signal })
                const response = await request.json()
                // console.log('Response fetch audience updated:', response)
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        if (response?.audience[0]) {
                            UPDATE_AUDIENCE(response?.audience[0])
                        }
                    }
                }
            } catch ({ message }) {
                throw new Error(message)
            }
        }
        fetchAudience()
        return () => controller.abort()
    }, [])



    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                if (notifData?.notifications?.lenght !== 0) {
                    return
                }
                const request = await api('api/v2/notifications', 'GET', null, { ...accessToken, ...signal })
                const response = await request.json()
                // console.log('Response fetch notifications:', response)
                if (request.status == 200 && response.success) {
                    if (response?.notifications.length !== 0) {
                        REFRESH_NOTIFICATIONS(response?.notifications)
                    }
                }
            } catch (e) {
                console.warn('Error fetch all notifications:', e)
            }
        }
        fetchNotifications()
        return () => controller.abort()
    }, [])



    useEffect(() => {
        const fetchNewNotifications = async () => {
            try {
                const request = await api('api/v2/notifications', 'GET', null, { ...accessToken, ...signal })
                const response = await request.json()
                // console.log('Response fetch notifications:', response)
                if (request.status == 200 && response.success) {
                    if (response?.notifications.length !== 0) {
                        ADD_NOTIFICATIONS(response?.notifications)
                        UPDATE_NOTIFICATIONS_NUMBER(response?.notifications?.length)
                    }
                }
            } catch (error) {
                console.warn('Error fetch all notifications:', error)
            }
            // try {
            //     const request = await api(`api/notifications/${0}`, 'GET', null, { ...accessToken, ...signal })
            //     const response = await request.json()
            //     // console.log('Response fetch new notification:', response?.notifications?.length + ' new notifications')
            //     if (request.status === 200 && response?.success) {
            //         if (response?.notifications?.length !== 0) {
            //             ADD_NOTIFICATIONS(response?.notifications)
            //             UPDATE_NOTIFICATIONS_NUMBER(response?.notifications?.length)

            //             /* UPDATE NOTIFICATION STATUS */
            //             // updateNotificationStatus()
            //         }
            //     }
            // } catch (error) {
            //     console.log('Error fetch new notifications:', error)
            // }
        }

        fetchNewNotifications()
        const notifTimer = setInterval(() => {
            fetchNewNotifications()
        }, 20000);
        return () => {
            clearInterval(notifTimer)
            return () => controller.abort()
        }
    }, [])


    const updateNotificationStatus = async () => {
        try {
            const data = { state: 0 }
            const request = await api(`api/confirm-notifications`, 'POST', data, accessToken)
            const response = await request.json()
            // console.log('Reponse update notification:', response?.notification + ' notification status updated')
        } catch (error) {
            console.error('Error update notification status:', error)
        }
    }


    // GESTION DES POTES && BUDDY YOU MAY KNOW
    useEffect(() => {
        const fetchBuddiesYouKnow = async () => {
            try {
                const request = await api('api/user/buddies-you-may-want-to-know', 'GET', null, accessToken)
                const response = await request.json()
                // console.log('Response fetch buddy you may know:', response?.result.length)
                if (request.ok && request.status === 200) {
                    if (response.success) {
                        REFRESH_PEOPLE(response?.result)
                    }
                }
            } catch (error) {
                fetchBuddiesYouKnow()
                console.log('Error get buddies-to-know:', error)
                throw new Error(error.message)
            }
        }
        fetchBuddiesYouKnow()
    }, [])



    // FETCH AND SAVE BUDDIES
    useEffect(() => {
        const fetchBuddies = async () => {
            try {
                const request = await api('api/user/get-buddies', 'GET', null, accessToken)
                const response = await request.json()
                // console.log('response buddies:', response)
                if (request.ok && request.status === 200) {

                    const buddiesToStore = response.buddies.map(buddy => ({
                        ...buddy,
                        profile: {
                            prof_picture: buddy?.prof_picture,
                        }
                    }))

                    REFRESH_BUDDIES(buddiesToStore)
                }
            } catch (error) {
                console.log('Error get-buddies:', error)
                throw new Error(error.message)
            }
        }
        fetchBuddies()
    }, [])


    useEffect(() => {
        fetchOneToOneConversation(true)
        const interval = setInterval(() => {
            fetchOneToOneConversation()
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    const fetchOneToOneConversation = async (isRefresh = false) => {
        try {
            const request = await api('api/my-messages?paginate&page=${1}', 'GET', null, accessToken)
            const response = await request.json()
            // console.log('Response one to one conversation:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    const numberOfMessage = response?.list?.data?.map(messageData => messageData?.sent_messages?.length)?.reduce(
                        (previousValue, currentValue) => previousValue + currentValue, 0,
                    )
                    REFRESH_MESSAGE_NUMBER(numberOfMessage)

                    if (isRefresh) {
                        REFRESH_CONVERSATION(response?.list?.data)
                    }
                }
            }
        } catch (error) {
            console.log('Error get messages:', error)
            throw new Error(error.message)
        }
    }

    // FETCH RECOMPENSES TYPE LIST  
    useEffect(() => {
        const fetctGifts = async () => {
            try {
                const request = await api(
                    `api/types_recompenses`,
                    'GET',
                    null,
                    accessToken,
                )
                const response = await request.json()
                console.log('Response fetch gifts:', response)
                if (request.ok && request.status === 200) {
                    localStorageSetItem(localStorageKeys.giftsType, response)
                }
            } catch (error) {
                fetctGifts()
                console.error(error)
            }
        }
        fetctGifts()
    }, [])



    return (
        <>
            {/* <NextSeo
            title="Home Page Title"
            description="Home page description of the page"
        /> */}
            <Routes>

                <Route path="*" element={<AppLayout />}>

                    <Route index={true} element={<Public />} />
                    <Route path='*' element={<Public />} />
                    <Route path='home' element={<Home />} />
                    <Route path='public' element={<Public />} />

                    {/* Live routes */}
                    <Route path='live' element={<Live />} />
                    <Route path=':user_username/live' element={<LiveStream />} />
                    <Route path='live/:live_code' element={<LiveJoin />} />

                    {/* Messaging routes */}
                    <Route path='one-to-on/contacts' element={<Contact />} />
                    <Route path='one-to-one' element={<OneToOne />} />
                    <Route path="one-to-one/:conv_id" element={<OneToOneMessage />} />
                    <Route path="one-to-one/:conv_id/profil" element={<OneToOneProfil />} />

                    <Route path="conversation/:conv_id" element={<OneToOneMessage />} />
                    <Route path="conversation/:conv_id/profil" element={<OneToOneProfil />} />

                    {/* Call routes */}
                    <Route path='one-to-one/:conv_id/calling' element={<Calling />} />
                    <Route path='one-to-one/:conv_id/incoming-call' element={<IncomingCall />} />

                    {/* Group chat routes */}
                    <Route path='group/create' element={<GroupCreate />} />
                    <Route path='group/invitations' element={<GroupInvitations />} />
                    <Route path='group/:chat_id/invite' element={<GroupInvite />} />

                    {/* Agora routes */}
                    <Route path='agora' element={<Agora />} />
                    <Route path='agora/:ago_id' element={<AgoraDetails />} />
                    <Route path='agora/:ago_id/members' element={<AgoraMembers />} />

                    <Route path="profile/:user_username" element={<Profile />} />
                    <Route path="profiles/user/:user_username" element={<Profile />} />
                    <Route path="profiles/user/:user_id/audience" element={<Audience />} />
                    <Route path="profiles/user/:user_id/my-audience" element={<MyAudience />} />
                    <Route path="profiles/user/:user_id/change-password" element={<ChangePassword />} />
                    <Route path="profiles/transactions" element={<Transactions />} />
                    <Route path="profiles/transactions/details" element={<TransactionDetails />} />
                    
                    <Route path="profiles/user/:user_id/settings" element={<Settings />} />
                    <Route path="profiles/user/accounts/add" element={<AddAccount />} />

                    <Route path="profiles/user/pages/create" element={<CreatePage />} />
                    <Route path="profiles/user/pages/:user_id/settings/moderation-requests" element={<ModerationRequests />} />
                    <Route path="profiles/user/pages/:user_id/settings/moderation-requests/send" element={<SendModerationRequest />} />


                    <Route path="opinion/:sond_id" element={<OpinionDetails />} />
                    <Route path="opinions/:sond_id" element={<OpinionDetails />} />
                    <Route path="opinion/select/:ago_id/:sond_id" element={<OpinionDetails />} />

                    <Route path="post/:sond_id" element={<OpinionDetails />} />
                    <Route path="post/select/:ago_id/:sond_id" element={<OpinionDetails />} />

                    <Route path="search" element={<Search />} />
                    <Route path="search-badge" element={<SearchBadge />} />
                    <Route path="search-category" element={<SearchCategory />} />
                    <Route path="people-you-match" element={<PeopleYouMatch />} />
                    <Route path="notification" element={<Notifications />} />

                </Route>

                <Route path='legal/en/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='legal/en/terms-and-conditions' element={<TermsConditions />} />
                <Route path='legal/fr/politique-de-confidentialite' element={<PolitiqueConfidentialite />} />
                <Route path='legal/fr/conditions-generales/' element={<ConditionsGenerales />} />
                <Route path="support" element={<Support />} />

            </Routes>

            <ModalEditBadges
                user={authData?.user}
                show={showEditBadge}
                setShow={setShowEditBadge}
                refresh={() => setShowEditBadge(false)}
            />

        </>

    )
}

export default AppStack