import { api } from 'api/api'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import avatar from 'assets/images/background_opinion.jpg'
import { useNavigate } from 'react-router-dom'
import Header from 'components/Header/Header'
import { doc, getDoc, getDocs, updateDoc } from 'firebase/firestore'
import { firestoreDB } from 'services/Firebase/firebaseConfig'
import { scheme } from 'constants/env'
import { generateChatId } from 'hooks/Utils/Utils'
import firestorekeys from 'constants/firestorekeys'
import messagingKeys from 'constants/messagingKeys'
import { Spinner } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { color } from 'hooks/Utils/color'


const actionType = {
  accept: 'ACCEPT',
  refuse: 'REFUSER'
}

const GroupInvitations = () => {

  const { t, i18n } = useTranslation()
  const accessToken = useAccessToken()
  const { data: authContextState } = useAuthReducer()

  const navigate = useNavigate()

  const [chat_id, setChat_id] = useState(null);
  const [invitations, setInvitations] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [isReFetching, setIsReFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const goBack = () => navigate(-1)

  useEffect(() => {
    setIsFetching(true)
    fetchInvitations()
  }, [])

  const fetchInvitations = async () => {
    try {
      if (!isFetching) {
        const request = await api(
          `api/conversations/pending_invitations?page=1`,
          'GET',
          null,
          accessToken
        )
        const response = await request.json()
        setIsFetching(false)
        setIsReFetching(false)
        console.log('Response  fetch conversation:', response)
        if (request.status === 200) {
          if (response?.success) {
            if (response?.data?.next_page_url) {
              setHasMore(true)
            } else {
              setHasMore(false)
            }
            if (response?.data?.data?.length === 0) {
              setIsEmpty(true)
            } else {
              setIsEmpty(false)
            }
            setPage(2)
            setInvitations(response?.data?.data)
          }
        }
      }
    } catch (error) {
      setIsFetching(false)
      setIsReFetching(false)
      setTimeout(() => {
        fetchInvitations()
      }, 1500);
      console.error('Error fetch conversation:', error);
    }
  }

  const loadInvitations = async () => {
    try {
      if (
        hasMore &&
        !isFetching &&
        !isLoading
      ) {
        setIsLoading(true)
        const request = await api(
          `api/conversations/pending_invitations?page=1`,
          'GET',
          null,
          accessToken
        )
        const response = await request.json()
        setIsLoading(false)
        // console.log('Response  fetch conversation:', response)
        if (request.status === 200) {
          if (response?.success) {
            if (response?.data?.next_page_url) {
              setHasMore(true)
            } else {
              setHasMore(false)
            }
            setPage(page => page + 1)
            setInvitations(invitations => [...invitations, ...response?.data?.data])
          }
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetch conversation:', error);
      setTimeout(() => {
        loadInvitations()
      }, 1500);
    }
  }

  const removeInvitation = (conv_id) => {
    try {
      setInvitations(oldValue => oldValue?.filter(item =>
        item?.conv_id !== conv_id
      ))
    } catch (error) {
      console.error(error);
    }
  }


  const getConversationFromFirestore = async (type, conv_id) => {

    const chat_id = generateChatId(messagingKeys.groupChat, conv_id, authContextState?.user?.user_id)

    const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString());
    getDoc(docRef).then((docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        console.log('Document data:', data);
        if (type == actionType.accept) {
          handleAccept(chat_id?.toString(), data)
        }
        if (type == actionType.refuse) {
          handleCancel(chat_id?.toString(), data)
        }
      } else {
        console.log('No such document!');
      }
    }).catch((error) => {
      console.error('Error getting document:', error);
    });
  }


  const handleAccept = async (chat_id, conversation) => {
    try {
      const request = await api(
        `api/conversations/${conversation?.conversation_id}/accept_invitation`,
        'POST',
        null,
        accessToken
      )
      const response = await request.json()
      console.log('Response accept invitation:', response)
      if (request.status === 200) {
        if (response?.success) {

          const requests = conversation?.requests?.filter(item =>
            item != authContextState?.user?.user_id
          )

          const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id);
          await updateDoc(docRef, {
            requests: requests || [],
            participants: [...conversation?.participants, authContextState?.user?.user_id]
          }).then(() => {
            console.log('Group chat updated from firstore.');
          }).catch((error) => {
            console.error('Error update firstore group chat:', error);
          });
          removeInvitation(conversation?.conversation_id)
        }
      }
    } catch (error) {
      console.error('Error accept invitation:', error);
    }
  }


  const handleCancel = async (chat_id, conversation) => {
    try {
      const request = await api(
        `api/conversations/${conversation?.conversation_id}/refuse_invitation`,
        'POST',
        null,
        accessToken
      )
      const response = await request.json()
      // console.log('Response cancel invitation:', response)
      if (request.status === 200) {
        if (response?.success) {

          const requests = conversation?.requests?.filter(item =>
            item != authContextState?.user?.user_id
          )
          const participants = conversation?.participants?.filter(item =>
            item != authContextState?.user?.user_id
          )

          const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id);
          await updateDoc(docRef, { requests, participants }).then(() => {
            console.log('Group chat updated from firstore.');
          }).catch((error) => {
            console.error('Error update firstore group chat:', error);
          });

          removeInvitation(conversation?.conversation_id)

        }
      }
    } catch (error) {
      console.error('Error cancel invitation:', error);
    }
  }

  const renderLoader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '5%' }}>
        <div style={{ textAlign: 'center' }}>
          <Spinner
            size="sm"
            role="status"
            aria-hidden="true"
            animation="border"
            variant='secondary'
            style={{
              width: '25px',
              height: '25px'
            }}
          />
          <h6 style={{ color: '#808080', marginTop: '3%', }}>
            {t('loading')}
          </h6>
        </div>
      </div>
    )
  }


  return (
    <div className='h-screen bg-white'>
      <Header title='Invitations' goBack={goBack} className='ms-2' />
      <div id='scrollableDiv' style={{ height: '94%' }} className='overflow-y-scroll pb-16 lg:pb-0'>
        <InfiniteScroll
          dataLength={invitations?.length}
          next={loadInvitations}
          hasMore={hasMore}
          loader={renderLoader()}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.9}>
          {invitations?.map((item, index) => {
            return (
              <div key={index} className='flex items-center py-3 px-3 '>
                <img src={avatar || item?.conversation?.conv_picture} alt='' className='w-16 h-16 me-4 rounded-full' />
                <div>
                  <p className='font-bold'>{item?.conversation?.conv_name}</p>
                  <p className='text-gray-500 mb-1'>{item?.conversation?.users_count} participant(s)</p>
                  <div className='flex items-center'>
                    <button
                     onClick={() => getConversationFromFirestore(actionType.accept, item?.conversation?.conv_id)}
                     style={{backgroundColor: color.primary}}
                      className='me-3  px-3 py-2 rounded text-white text-xs font-bold'>
                      {t('accept')}
                    </button>
                    <button 
                    onClick={() => getConversationFromFirestore(actionType.refuse, item?.conversation?.conv_id)} 
                    style={{color: color.primary, border: `1px solid ${color.primary}`}}
                    className='me-3 px-3 py-1.5 rounded  text-sm font-bold'>
                      {t('refuse')}
                    </button>
                  </div>
                </div>
              </div>
            )
          })}
        </InfiniteScroll>
      </div>

    </div>
  )
}

export default GroupInvitations