import React, { Fragment, useEffect, useState } from 'react'
import './OneToOne.style.css'
import { useAccessToken, useAuthReducer, useOneToOneReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import TopBar from 'components/Topbar/Topbar';
import { TfiMenu } from 'react-icons/tfi'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import useReducerNotifAction from 'hooks/ReducerHooksAction/useReducerNotifAction';
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction';
import { api } from 'api/api';
import { IoIosAdd, IoMdCloseCircleOutline } from 'react-icons/io';
import { Alert, IconButton, Snackbar } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'react-bootstrap';
import useReducerOneToOneAction from 'hooks/ReducerHooksAction/useReducerOneToOneAction';
import { color } from 'hooks/Utils/color';
import { useNavigate } from 'react-router-dom';
import OneToOneCard from './Components/OneToOneCard/OneToOneCard';
import { IoIosAddCircleOutline } from "react-icons/io";
import { TbListCheck } from "react-icons/tb";
import { collection, doc, getDoc, onSnapshot, orderBy, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { firestoreDB } from 'services/Firebase/firebaseConfig';
import { scheme } from 'constants/env';
import firestorekeys from 'constants/firestorekeys';
import { generateChatId } from 'hooks/Utils/Utils';
import dayjs from 'dayjs';
import messagingKeys from 'constants/messagingKeys';

const sortType = {
  all: 'ALL',
  unread: 'UNREAD'
}

const OneToOne = () => {

  const { t, i18n } = useTranslation()
  const accessToken = useAccessToken()
  const navigate = useNavigate()
  const { data: authReducerStore } = useAuthReducer()
  const { data: oneToOneData } = useOneToOneReducer()
  const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()
  const { REFRESH_MESSAGE_NUMBER, REFRESH_CONVERSATION, REFRESH_BUDDIES } = useReducerOneToOneAction()

  // DATA STATE
  const [conversations, setConversation] = useState([])
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false)

  const [searchData, setSearchData] = useState([])
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState(sortType.all)

  const [showOnLine, setShowOnline] = useState(authReducerStore?.user?.user_show_last_seen)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };

  const [openSnack, setOpenSnack] = useState(false);
  const handleClickSnack = () => { setOpenSnack(true) };
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  const goToInMail = () => navigate('/in-mail')
  const goToNewGroup = () => navigate('/group/create')
  const goToInvitationList = () => navigate('/group/invitations')

  // FETCH CONVERSATION FROM FIRESTORE
  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.messaging,
        firestorekeys.conversations
      ),
      where(firestorekeys.participants, 'array-contains', authReducerStore?.user?.user_id)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      const data = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        user: doc?.conversation_type == 'GroupChat'
          ? doc.data()?.users[0]
          : doc.data()?.users?.filter(item =>
            item?.user_id != authReducerStore?.user?.user_id &&
            item?.user_username != authReducerStore?.user?.user_username
          )[0],
      }))?.filter(item =>
        item?.chat_id !== `chat_user_${authReducerStore?.user?.user_id}_and_user_${authReducerStore?.user?.user_id}`
      )?.sort((a, b) =>
        b?.timestamp - a?.timestamp
      );

      // console.log('conversations:', data)
      setConversation(data)
      setIsEmpty(data?.length == 0)
    });
    return () => {
      unsubscribe();
    }
  }, []);



  useEffect(() => {
    fetchConversations()
  }, [])

  const fetchConversations = async () => {
    try {
      if (!isFetching) {
        setIsFetching(true)
        const request = await api(
          `api/conversations?paginate&page=1`,
          'GET',
          null,
          accessToken
        )
        const response = await request.json()
        setIsFetching(false)
        // console.log('Fetch conversations response:', response?.data?.data)
        if (request.status === 200) {
          if (response.success) {
            if (response?.data?.next_page_url) {
              setHasMore(true)
            } else {
              setHasMore(false)
            }
            if (response?.data?.data?.length === 0) {
              setIsEmpty(true)
            } else {
              setIsEmpty(false)
            }
            setPage(2)

            // SAVE FIRESTORE CONVERSATION
            response?.data?.data?.forEach(item => {
              getConversation(item)
            });

          }
        }
      }
    } catch (error) {
      setIsFetching(false)
      console.log('Error fetch conversation:', error)
      if (error.message == 'Network request failed') {
        setTimeout(() => {
          fetchConversations()
        }, 2000);
      }
    }
  }


  const loadMoreConversations = async () => {
    try {
      if (hasMore && !isFetching && !isLoading) {
        setIsLoading(true)
        const request = await api(
          `api/conversations?paginate&page=${page}`,
          'GET',
          null,
          accessToken
        )
        const response = await request.json()
        setIsLoading(false)
        // console.log('Response load more conversations:', response?.data?.data)
        if (request.ok && request.status === 200) {
          if (response.success) {
            if (response?.data?.next_page_url) {
              setHasMore(true)
            } else {
              setHasMore(false)
            }
            setPage(page => page + 1)

            // SAVE FIRESTORE CONVERSATION
            response?.data?.data?.forEach(item => {
              getConversation(item)
            });

          }
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error load more conversations:', error)
      if (error.message == 'Network request failed') {
        setTimeout(() => {
          loadMoreConversations()
        }, 2000);
      }
    }
  }


  const getConversation = (conversation) => {
    const chat_id = generateChatId(
      conversation?.conv_type,
      conversation?.conv_id,
      authReducerStore?.user?.user_id,
    )

    const docsRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString())
    getDoc(docsRef).then(documentSnapshot => {
      if (!documentSnapshot.exists()) {
        createConversation(chat_id, conversation)
      }
    }).catch(e => {
      console.log('Error get document ' + chat_id + ':', e)
    })

  }



  const createConversation = async (chat_id, conversation) => {
    try {
      const user = {
        profile: {
          prof_picture: conversation?.conv_picture || null
        },
        prof_picture: conversation?.conv_picture || null,
        user_id: conversation?.conv_id || null,
        user_surname: removeFirstWord(conversation?.conv_name) || null,
        user_name: conversation?.conv_name?.split(' ')[0] || null,
        user_username: conversation?.conv_username || conversation?.conv_name?.toLowerCase()?.replaceAll(' ', '') || null,
        user_civility: conversation?.conv_civility || null,
        user_verified: conversation?.conv_verified || null,
        user_gold: conversation?.conv_gold || null,
        user_badge_business: conversation?.conv_user_badge_business || null,
        user_badge_color: conversation?.conv_user_badge_color || null,
        user_badge_food: conversation?.conv_user_badge_food || null,
        user_badge_goal: conversation?.conv_user_badge_goal || null,
        user_badge_hobby: conversation?.conv_user_badge_hobby || null,
        user_match_value: conversation?.conv_user_match_value || null,
      }

      const id = conversation?.conv_last_message_id

      const timestamp = conversation?.conv_last_message_date
        ? new Date(conversation?.conv_last_message_date).getTime()
        : new Date().getTime()

      const last_message = {
        id: id,
        uid: id,
        _id: id,
        text: conversation?.conv_last_message_text,
        createdAt: dayjs(timestamp).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      }

      const docsRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString())
      setDoc(docsRef, {
        chat_id: chat_id,
        conversation_id: conversation?.conv_id || null,
        conversation_type: conversation?.conv_type || null,
        users: conversation?.conv_type == messagingKeys.groupChat
          ? [user]
          : [user, authReducerStore?.user],
        participants: [
          conversation?.conv_id,
          authReducerStore?.user?.user_id
        ],
        last_message: last_message,
        timestamp: timestamp
      }).then(() => {
        if (conversation?.conv_type == messagingKeys.groupChat) {
          updateGroupChatMembers(chat_id, conversation?.conv_id)
        }
      }).catch(e => {
        console.log('Error create conversation ' + chat_id + ':', e)
      })
    } catch (error) {
      console.error(error);
    }
  }

  const updateGroupChatMembers = async (chat_id, conv_id) => {
    try {
      setIsLoading(true)
      const request = await api(
        `api/conversations/${conv_id}`,
        'GET',
        {},
        accessToken
      )
      const response = await request.json()
      // console.log('Fetch group chat:', response?.data?.users)
      if (request.status === 200) {
        if (response.success) {

          const members = response?.data?.users?.map(item => item?.user_id)

          const docsRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString())
          updateDoc(docsRef, {
            participants: members
          }).then(() => {
            console.log('Group chat members updated.')
          }).catch(e => {
            console.log('Error update group chat members:', e)
          })

        }
      }
    } catch (error) {
      console.error('Error update group chat member:', error);
      setTimeout(() => {
        updateGroupChatMembers(chat_id, conv_id)
      }, 2000);
    }
  }


  function removeFirstWord(sentence) {
    if (sentence) {
      let words = sentence?.split(' ');
      words?.shift(); // Retire le premier mot
      return words.join(' ');
    } else {
      return null
    }
  }


  useEffect(() => {
    if (search) {
      setSort(sortType.all)
      const newData = conversations.filter(
        item =>
          `${item?.user?.user_name} ${item?.user?.user_surname}`?.toLowerCase()?.includes(search?.toLowerCase()) ||
          `${item?.user?.user_surname} ${item?.user?.user_name}`?.toLowerCase()?.includes(search?.toLowerCase()) ||
          item?.user?.user_username?.toLowerCase()?.includes(search?.toLowerCase()),
      )
      setSearchData(newData)
    } else {
      setSearchData(conversations)
    }
  }, [search])

  const handleSort = (sort) => {
    setSort(sort)
    if (sort == sortType.all) {
      setSearchData([])
    } else {
      const newData = conversations?.filter(conversation =>
        conversation?.unread_message_count && conversation?.unread_message_count != 0
      )
      setSearchData(newData)
    }
  }


  // useEffect(() => {
  //   fetchConversations()
  // }, [oneToOneData?.message_number])

  // const fetchConversations = async () => {
  //   try {
  //     setIsLoading(true)
  //     const request = await api(`api/my-messages?paginate&page=${1}`, 'GET', {}, accessToken)
  //     const response = await request.json()
  //     // console.log('Fetch conversations response:', response?.list?.data?.length)
  //     setIsLoading(false)
  //     if (request.ok && request.status === 200) {
  //       if (response.success) {
  //         if (response?.list?.next_page_url) {
  //           setHasMore(true)
  //         } else {
  //           setHasMore(true)
  //         }
  //         if (response?.list?.data?.length === 0) {
  //           setIsEmpty(true)
  //           return
  //         }
  //         setPage(2)
  //         setConversation(response?.list?.data)
  //         REFRESH_CONVERSATION(response?.list?.data)

  //         const numberOfMessage = response?.list?.data
  //           ?.map(message => message?.sent_messages?.length)
  //           ?.reduce(
  //             (previousValue, currentValue) => previousValue + currentValue, 0,
  //           )
  //         REFRESH_MESSAGE_NUMBER(numberOfMessage)
  //       }
  //     }
  //   } catch (error) {
  //     fetchConversations()
  //     console.error('Fetch conversations error:', error.message)
  //   }
  // }


  // const loadMoreConversations = async () => {
  //   try {
  //     if (hasMore && !isLoading) {
  //       setIsLoading(true)
  //       const request = await api(`api/my-messages?paginate&page=${page}`, 'GET', null, accessToken)
  //       const response = await request.json()
  //       // console.log('Load more conversations response:', response?.list?.data?.length)
  //       setIsLoading(false)
  //       if (request.ok && request.status === 200) {
  //         if (response.success) {

  //           if (response?.list?.next_page_url) {
  //             setHasMore(true)
  //           } else {
  //             setHasMore(false)
  //           }
  //           setConversation([...oneToOneData.conversations, ...response?.list?.data])
  //           REFRESH_CONVERSATION([...oneToOneData.conversations, ...response?.list?.data])

  //           const numberOfMessage = response?.list?.data
  //             ?.map(message => message?.sent_messages?.length)
  //             ?.reduce(
  //               (previousValue, currentValue) => previousValue + currentValue, 0,
  //             )
  //           REFRESH_MESSAGE_NUMBER(numberOfMessage)
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     loadMoreConversations()
  //     console.log('Load more conversations error:', error.message)
  //   }
  // }


  // HANDLE UPDATE LAST SEEN
  const handleChangeOnLineStatus = async () => {
    try {
      handleClose()
      const body = {
        user_show_last_seen: !showOnLine
      }
      const request = await api(`api/user/update-show-last-seen`, 'POST', body, accessToken)
      const response = await request.json()
      // console.log('Response updating last seen:', response)
      if (request.ok && request.status === 200) {
        if (response?.success) {
          setShowOnline(!showOnLine)
          handleClickSnack()
          updateUserData()
        }
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const updateUserData = async () => {
    try {
      const request = await api('api/get-user', 'GET', {}, accessToken)
      const response = await request.json()
      // console.log('Response fetch user:', response)
      if (request.ok && request.status === 200) {
        UPDATE_PROFILE(response?.user)
      }
    } catch (e) {
      console.warn('Error fetch user info:', e.message)
      throw new Error(e)
    }
  }


  const header = () => {
    if (isMobile) {
      return (
        <TopBar />
      )
    } else {
      return (
        <div className='header'>
          <p className='title'>{t('inbox')}</p>
        </div>
      )
    }
  }

  const renderLoader = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
        <div style={{ textAlign: 'center' }}>
          <Spinner
            size="sm"
            role="status"
            aria-hidden="true"
            animation="border"
            variant='secondary'
            style={{
              width: '25px',
              height: '25px'
            }}
          />
          <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
            {t('loading')}
          </h6>
        </div>
      </div>
    )
  }


  const renderEmptyConversation = () => {
    if (isEmpty) {
      return (
        <div style={{ textAlign: 'center', marginTop: '15vh', paddingLeft: '10%', paddingRight: '10%' }}>
          <h5 style={{ color: color.dark }}>{t('no_conversations_available')}</h5>
          <h6 style={{ color: color.grayDark }}>{t('no_conversations_available_desc')}</h6>
        </div>
      )
    }
  }


  return (
    <div className='onetoone min-h-screen bg-white'>
      <div className='header'>
        <p className='title'>
          Inbox
        </p>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick} >
          <TfiMenu size={28} color='#414141' />
        </Button>
      </div>

      <input
        type='search'
        placeholder={`${t('search')}...`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className='search-input'
      />

      <div className='flex items-center justify-between p-3 '>

        <div className='flex items-center'>
          <button
            onClick={() => handleSort(sortType.all)}
            style={{
              color: sort == sortType.all ? color.white : color.primary,
              backgroundColor: sort == sortType.all ? color.primary : color.white,
              border: `1.5px solid ${color.primary}`,
            }}
            className={`px-3 py-2 me-6 text-xs lg:text-xs font-bold rounded-full`}>
            {i18n.language == 'en'
              ? 'All'
              : 'Toutes'
            }
          </button>

          <button
            onClick={() => handleSort(sortType.unread)}
            style={{
              color: sort == sortType.unread ? color.white : color.primary,
              backgroundColor: sort == sortType.unread ? color.primary : color.white,
              border: `1.5px solid ${color.primary}`,
            }}
            className={`px-3 py-2 me-6 text-xs lg:text-xs font-bold rounded-full`}>
            {i18n.language == 'en'
              ? 'Unread'
              : 'Non lues'
            }
          </button>
        </div>

        <button onClick={() => navigate('/one-to-on/contacts')} style={{ backgroundColor: color.primary }} className={`flex items-center px-3 py-2 text-xs lg:text-xs font-bold text-white rounded-full`}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 me-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
          </svg>
          {i18n.language == 'en'
            ? 'New'
            : 'Nouveau'
          }
        </button>

      </div>

      <div id="HomeScrollableDiv" className='pb-12 overflow-x-hidden overflow-y-hidden' style={{ height: '80vh' }}>
        {renderEmptyConversation()}
        {/* CONVERSATIONS */}
        <InfiniteScroll
          dataLength={conversations.length}
          next={loadMoreConversations}
          hasMore={false}
          loader={renderLoader()}
          scrollableTarget="HomeScrollableDiv"
          scrollThreshold={0.9}
          style={{ overflowX: 'hidden', height: '87vh', paddingBottom: '180px' }}>
          {searchData?.length !== 0 ?
            searchData?.map((item, index) => {
              return (
                <OneToOneCard
                  key={index.toString()}
                  conversation={item}
                />
              )
            })
            :
            conversations?.map((item, index) => {
              return (
                <OneToOneCard
                  key={index.toString()}
                  conversation={item}
                />
              )
            })
          }
        </InfiniteScroll>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem onClick={goToNewGroup}>
          <IoIosAdd size={30} color='#414141' style={{ marginRight: '3px' }} />
          {t('new_group')}
        </MenuItem>

        <MenuItem onClick={goToInvitationList}>
          <TbListCheck size={28} color='#414141' style={{ marginRight: '5px' }} />
          {t('invitation_list')}
        </MenuItem>

        <MenuItem onClick={handleChangeOnLineStatus}>
          {showOnLine ?
            <AiOutlineEye size={28} color='#414141' style={{ marginRight: '5px' }} />
            :
            <AiOutlineEyeInvisible size={28} color='#414141' style={{ marginRight: '5px' }} />
          }
          {showOnLine ? t('hide_your_last_seen') : t('show_your_last_seen')}
        </MenuItem>
      </Menu>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
        <Alert onClose={handleCloseSnack} severity={"success"} sx={{ width: '100%' }}>
          {showOnLine ?
            t('sharing_your_last_connection_has_been_successfully_activated')
            :
            t('sharing_your_last_login_has_been_successfully_disabled')
          }
        </Alert>
      </Snackbar>

    </div>
  )
}

export default OneToOne