'use client';
import React, { useEffect, useState } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { useAccessToken } from 'hooks/ReducerHooks/ReducerHooks';
import { addDoc, collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { GrGallery } from "react-icons/gr";
import { firestoreDB } from 'services/Firebase/firebaseConfig';
import { appUrl } from 'api/api';
import { apiUrl } from 'api/api';
import firestorekeys from 'constants/firestorekeys';
import { api } from 'api/api';
import avatar from 'assets/images/background_opinion.jpg'
import { MdOutlineLink } from "react-icons/md";
import { FaCheck } from "react-icons/fa";


const LiveShare = ({
    live,
    visible = false,
    onChangeVisible = () => null,
    onRefresh = () => null
}) => {

    const { t, i18n } = useTranslation()

    const [isCopied, setIsCopied] = useState(false)

    const handleCancel = () => {
        onChangeVisible(false)
    }

    const handleCopy = () => {
        try {
            navigator.clipboard.writeText(`${appUrl}/live/${live?.live_channel_name}`).then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
            });
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Dialog open={visible} onClose={() => onChangeVisible(false)} className="relative z-10">
            <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4  sm:items-center sm:p-0">
                    <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">

                        <div className="bg-white px-4 pb-4 pt-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="w-full  sm:ml-4 sm:text-left">

                                    <DialogTitle as="h3" className="text-base text-center font-semibold leading-6">
                                        {i18n.language == 'en'
                                            ? 'Share'
                                            : 'Partager'
                                        }
                                    </DialogTitle>

                                    <div className='mt-4'>
                                        <p className='text-gray-500 text-sm'>
                                            {i18n.language == 'en'
                                                ? 'The links you share are unique and allow you to join the live.'
                                                : 'Les liens que vous partagez sont uniques et permet de joindre le live.'
                                            }
                                        </p>
                                    </div>

                                    <div className='flex items-center justify-between mt-3'>
                                        <p className='w-96 me-3 p-3 bg-gray-200 rounded-lg'>
                                            {`${appUrl}/live/${live?.live_channel_name}`}
                                        </p>

                                        <button onClick={handleCopy} className='flex justify-center items-center bg-gray-300 rounded-full w-12 h-12'>
                                            {isCopied ?
                                                <FaCheck color='green' size={22} />
                                                :
                                                <MdOutlineLink color='black' size={28} />
                                            }
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="border border-emerald-600 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                            >
                                {t('cancel')}
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

export default LiveShare