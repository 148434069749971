import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaSearch } from 'react-icons/fa'
import { IoSearch } from 'react-icons/io5'
import { MdOutlineErrorOutline } from 'react-icons/md'

const EmptyPage = ({
    text = 'Indisponible'
}) => {

    const { t } = useTranslation()


    return (
        <div style={{backgroundColor: 'white', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{ textAlign: 'center' }}>
                <IoSearch
                 size={55} 
                 color='#808080' 
                 className='mx-auto'
                 />
                <h6 style={{ color: '#808080', marginTop: '5%', fontSize: '18px' }}>
                    {text}
                </h6>
            </div>
        </div>
    )
}

export default EmptyPage