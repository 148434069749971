import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAdsClick } from 'react-icons/md'
import { Link } from 'react-router-dom'
import avatar from 'assets/images/background_opinion.jpg'
import background from 'assets/images/bg_clips.jpg'
import LiveCreate from '../LiveCreate/LiveCreate'
import { FaUser } from 'react-icons/fa'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { firestoreDB } from 'services/Firebase/firebaseConfig'
import { scheme } from 'constants/env'
import firestorekeys from 'constants/firestorekeys'
import { color } from 'hooks/Utils/color'

const LiveCard = ({
    live
}) => {


    const { t, i18n } = useTranslation()
    const [audienceCount, setAudienceCount] = useState(0)
    const [showCreateLive, setShowCreateLive] = useState(false)

    const handleShowCreateLive = () => setShowCreateLive(oldValue => !oldValue)

    // AUDIENCE SNAPSHOT
    useEffect(() => {
        console.log(live?.live_channel_name)
        const q = query(
            collection(
                firestoreDB,
                scheme,
                firestorekeys.lives,
                firestorekeys.channels,
                live?.live_channel_name,
                firestorekeys.audiences
            ),
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
            setAudienceCount(data?.length)
        });
        return () => {
            unsubscribe();
        }
    }, [live?.live_channel_name]);
    


    return (
        <Link
            to={`/live/${live?.live_channel_name}`}
            state={{
                live_data: live
            }}
            className="relative h-screen border-b-2 border-b-white">
            <img
                src={live?.user?.profile?.prof_picture || live?.live_thumbnail || require('assets/images/bg_clips.jpg')}
                className="h-screen object-cover object-center w-full"
            />
            <div className='absolute flex items-start justify-end top-0 left-0 w-full h-full bg-black bg-opacity-50'>
                <div className="flex items-center ms-auto mt-3 mr-3">
                    <div className="bg-red-500 text-white px-3 rounded-full">
                        Live
                    </div>
                    <div className="flex items-center mx-2 px-3 text-white bg-neutral-900 rounded-full">
                        <FaUser />
                        <span className="ms-1 font-bold">
                            {audienceCount || 0}
                        </span>
                    </div>
                </div>
            </div>
            <div className='absolute flex items-end top-0 left-0 w-full h-full'>

                <div className='w-full px-3 mb-50 md:mb-46 lg:mb-32'>
                    <div className='w-full mb-32'>
                        <MdAdsClick
                            color='white'
                            size={30}
                            className='mx-auto'
                        />
                        <p className='text-white my-3 text-center font-bold'>
                            {i18n.language == 'en'
                                ? "Join the live"
                                : "Joindre le live"}
                        </p>
                    </div>
                    <div className='w-full'>
                        <p className='text-white mb-3'>
                            {live?.live_description}
                        </p>
                        <div className="flex p-1 items-center w-72 rounded-full me-3 border border-neutral-700">
                            <img src={live?.user?.profile?.prof_picture || avatar} alt="" className="w-10 h-10 rounded-full me-1" />
                            <div className="ps-2 pe-4">
                                <h3 className="text-white font-bold">{live?.user?.user_surname} {live?.user?.user_name}</h3>
                                <span className="text-xs text-white ">@{live?.user?.user_username}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <Link onClick={handleShowCreateLive} className='absolute bottom-24 md:bottom-36 lg:bottom-20 right-4 text-white text-sm font-bold text-center py-2 px-3 ms-auto rounded-full' style={{ backgroundColor: color.primary }}>
                    {t('start_a_live')}
                </Link>
            </div>

            {/* CREATE LIVE */}
            <LiveCreate
                visible={showCreateLive}
                onChangeVisible={handleShowCreateLive}
            />
        </Link>
    )
}

export default LiveCard