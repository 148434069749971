'use client'

import { useEffect, useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { localStorageGetItem, localStorageKeys } from 'store/localstorage'
import { useTranslation } from 'react-i18next'
import { color } from 'hooks/Utils/color'
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import { api } from 'api/api'
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction'

const SendGift = ({
    user_id = null,
    sond_id = null,
    live_id = null,
    open = false,
    onClose = () => null,
    onChange = () => null
}) => {

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const { data: authContextState } = useAuthReducer()
    const [giftsType, setGiftsType] = useState([])
    const [giftSelected, setGiftSelected] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()

    useEffect(() => {
        const giftsType = localStorageGetItem(localStorageKeys.giftsType);
        if (giftsType && giftsType.length !== 0) {
            setGiftsType(giftsType)
        }
    }, [])

    const handleClose = () => onClose(false)


    const handleSelectedItem = (item) => {
        setGiftSelected(item)
    }

    const handleSendGift = async () => {
        try {
            setIsLoading(true)

            const type_recom_id = giftSelected?.type_recom_id
            const type_recom_name = giftSelected?.type_recom_name
            const type_recom_value = giftSelected?.type_recom_value
            const recom_text = i18n.language == 'en'
                ? `${authContextState?.user?.surname || ''} ${authContextState?.user?.user_name || ''} You have offered a gift ${type_recom_name} worth ${type_recom_value} coin(s). `
                : `${authContextState?.user?.surname || ''} ${authContextState?.user?.user_name || ''} Vous avez offert un gift ${type_recom_name} soit ${type_recom_value} coin(s). `

            let data

            if (user_id) {
                data = {
                    recom_user_id: user_id,
                    recom_type_id: type_recom_id,
                    recom_text
                }
            } else if (sond_id) {
                data = {
                    recom_sond_id: sond_id,
                    recom_type_id: type_recom_id,
                    recom_text
                }
            } else if (live_id) {
                data = {
                    recom_live_id: live_id,
                    recom_type_id: type_recom_id,
                    recom_text
                }
            }

            const request = await api(
                `api/recompenses`,
                'POST',
                data,
                accessToken,
            )
            const response = await request.json()
            console.log('Response fetch gifts:', response)
            setIsLoading(false)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    onChange(response)
                    handleClose()
                    fetchUser()
                }
            } else {
                console.error('Error fetch gifts :', response)
            }
        } catch (error) {
            setIsLoading(false)
            console.error("Error sending gift", error);
        }
    }

    const fetchUser = async () => {
        try {
            const request = await api(
                'api/get-user',
                'GET',
                {},
                accessToken
            )
            const response = await request.json()
            // console.log('Response fetch user:', response?.user)
            if (request.ok && request.status === 200) {
                UPDATE_PROFILE(response?.user)
            } else if (request.status === 401 || request.status === 403) {
                LOGOUT()
            }
        } catch (error) {
            setTimeout(() => {
                fetchUser()
            }, 2000);
            console.error('Error fetch user info:', error)
        }
    }


    return (
        <Dialog open={open} onClose={onClose} className="relative z-10">

            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all data-closed:translate-y-4 data-closed:opacity-0 data-enter:duration-300 data-enter:ease-out data-leave:duration-200 data-leave:ease-in sm:my-8 sm:w-full sm:max-w-lg data-closed:sm:translate-y-0 data-closed:sm:scale-95"
                    >

                        <DialogTitle as="h2" className="flex justify-between items-center px-3 text-lg font-semibold text-gray-900 mt-3">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 text-white">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                            </svg>

                            {i18n.language == 'en'
                                ? ' Send a gift'
                                : 'Envoyer un cadeau'
                            }

                            <button
                                onClick={handleClose}
                                className="p-2 rounded-full hover:bg-gray-100"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6" style={{ color: color.primary }}>
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
                                </svg>
                            </button>
                        </DialogTitle>

                        <div className="flex justify-center px-4 mt-3">
                            <div className="grid grid-cols-3 gap-4 md:gap-2 place-items-center">
                                {giftsType.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <button
                                                key={item.type_recom_id.toString()}
                                                onClick={() => handleSelectedItem(item)}
                                                className={`flex flex-col items-center p-2 md:px-6 rounded-lg transition-all ${giftSelected?.type_recom_id === item?.type_recom_id ? "ring ring-blue-500" : "ring-1 ring-gray-200"} hover:ring-blue-500`}
                                            >
                                                <div className="relative w-16 h-16 flex justify-center items-center">
                                                    <div className=" flex items-center justify-center">
                                                        <div className="w-6 h-6 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"></div>
                                                    </div>
                                                    <div className='absolute flex justify-center items-center'>
                                                        {item?.type_recom_logo_url && (
                                                            <img
                                                                src={item?.type_recom_logo_url}
                                                                alt={item?.type_recom_name}
                                                                className="w-full h-full object-contain  bg-white"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <p className="text-sm font-semibold">{item?.type_recom_name}</p>
                                                <p className="text-sm text-gray-500">
                                                    {item?.type_recom_value} {item?.type_recom_value == 1 ? "coin" : "coins"}
                                                </p>
                                            </button>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                        <div className="flex justify-between items-center bg-gray-50 px-4 py-6">
                            <div className="flex items-center">
                                <span className="text-sm font-bold text-gray-800">
                                    {i18n.language == 'en'
                                        ? 'Balance:'
                                        : 'Solde:'
                                    }
                                </span>
                                <img src={require('assets/coins/coins.png')} alt="coin" className="w-5 h-5 ml-2 mr-1" />
                                <span className="text-sm font-bold text-gray-800" style={{ color: color.primary }}>
                                    {authContextState?.user?.user_wallet_balance || 0}
                                </span>
                            </div>

                            {(
                                !giftSelected?.type_recom_value ||
                                authContextState?.user?.user_wallet_balance < giftSelected?.type_recom_value
                            ) ? (
                                <div
                                    className="px-4 py-2 text-sm font-semibold text-white rounded-lg bg-gray-300 cursor-not-allowed"
                                >
                                    <span>
                                        {i18n.language == 'en'
                                            ? 'Send'
                                            : 'Envoyer'
                                        }
                                    </span>
                                </div>
                            ) : (
                                <button
                                    onClick={handleSendGift}
                                    className="px-4 py-2 text-sm font-semibold text-white rounded-lg"
                                    style={{ backgroundColor: color.primary }}
                                >
                                    {isLoading ? (
                                        <div className="flex items-center justify-center mx-3">
                                            <div className="w-4 h-4 border-2 border-t-transparent rounded-full animate-spin"></div>
                                        </div>
                                    ) : (
                                        <span>
                                            {i18n.language == 'en'
                                                ? 'Send'
                                                : 'Envoyer'
                                            }
                                        </span>
                                    )}
                                </button>
                            )}

                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}


export default SendGift;