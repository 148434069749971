import React, { useCallback, useEffect, useState } from 'react'
import styles from './OneToOneCard.module.css'
import ProfileCard from 'components/ProfileCard/ProfileCard'
import { color } from 'hooks/Utils/color'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore'
import firestorekeys from 'constants/firestorekeys'
import { scheme } from 'constants/env'
import { firestoreDB } from 'services/Firebase/firebaseConfig'
import messagingKeys from 'constants/messagingKeys'
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import PrettyTime from 'components/PrettyTime/PrettyTime'


const OneToOneCard = ({
  conversation
}) => {

  const navigate = useNavigate()
  const { data: authreducerState } = useAuthReducer()

  const [messages, setMessages] = useState([])

  const goToConversation = () => {
    navigate(
      conversation?.conversation_type == messagingKeys.oneToOneChat
        ? `/one-to-one/${conversation?.user?.user_id}`
        : `/conversation/${conversation?.user?.user_id}`, {
      state: {
        chat_id: conversation?.chat_id,
        conv_id: conversation?.user?.user_id,
        conv_type: conversation?.conversation_type,
        user: conversation?.user,
        conversation: {
          conv_name: conversation?.user?.name,
          conv_username: conversation?.user?.user_username,
          conv_picture: conversation?.user?.prof_picture,

          conv_type: messagingKeys.oneToOneChat,
          conv_unread_messages_count: 0,

          conv_user_badge_business: conversation?.user?.user_badge_business,
          conv_user_badge_color: conversation?.user?.user_badge_color,
          conv_user_badge_food: conversation?.user?.user_badge_food,
          conv_user_badge_goal: conversation?.user?.user_badge_goal,
          conv_user_badge_hobby: conversation?.user?.user_badge_hobby,
          conv_user_match_value: conversation?.user?.match_value
        }
      }
    })
  }


  useEffect(() => {
    const q = query(
      collection(
        firestoreDB,
        scheme,
        firestorekeys.messaging,
        firestorekeys.conversations,
        conversation?.chat_id?.toString(),
        firestorekeys.messages
      ),
      where('status', '==', 'no')
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      const data = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      const unreadMessages = data?.filter(item =>
        item?.user?._id != authreducerState?.user?.user_id
      )
      // console.log('messages:', data)
      setMessages(unreadMessages)

      // update unread message count
      updateUnreadCount(unreadMessages?.length)
    });
    return () => {
      unsubscribe();
    }
  }, [conversation?.chat_id]);

  const updateUnreadCount = async (data) => {
    try {
      if (!conversation?.chat_id) return

      const docRef = doc(
        firestoreDB,
        scheme,
        firestorekeys.messaging,
        firestorekeys.conversations,
        conversation?.chat_id?.toString()
      );

      await updateDoc(docRef, {
        unread_message_count: data || 0
      }).then(() => {
        console.log('Doc updated.');
      }).catch((error) => {
        console.error('Error doc update:', error);
      });
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <div className={'hover:bg-gray-100 cursor-pointer'} onClick={goToConversation}>
      <div className={'flex py-3 ps-3'}>
        <div className='flex items-center'>
          <img src={conversation?.user?.profile?.prof_picture || require('assets/images/background_opinion.jpg')} alt='' className='w-12 h-12 me-3 rounded-full object-cover object-center' />
          <div style={{ width: '60%' }}>
            <p className='truncase'>{conversation?.user?.user_surname} {conversation?.user?.user_name}</p>
            <p className='text-xs pt-0 mt-0 text-gray-400'>@{conversation?.user?.user_username}</p>
            {conversation?.last_message?.text && conversation?.last_message?.text != 'null' && conversation?.last_message?.text != 'undefined' &&
              <p className='text-sm mt-1 text-gray-600'>
                {conversation?.last_message?.text?.length < 80
                  ? conversation?.last_message?.text
                  : `${conversation?.last_message?.text?.slice(0, 80)}...`
                }
              </p>
            }
          </div>
        </div>
        <div className='flex justify-end w-[30%] pe-3'>
          <div>
            <PrettyTime
              time={conversation?.timestamp}
              style={{ color: messages?.length > 0 ? color.primary : 'gray-400' }}
              className='text-xs font-semiBold text-gray-500 ms-auto'
            />
            {messages?.length !== 0 &&
              <div className='flex justify-center items-center bg-red-600 w-6 h-6 rounded-full ms-auto text-white text-xs mt-2'>
                {messages?.length}
              </div>
            }
          </div>
        </div>
      </div>
      <div style={{ height: 1 }} className='bg-gray-200 w-full ms-20' />
    </div>
  )
}

export default OneToOneCard