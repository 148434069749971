import { scheme } from 'constants/env';
import firestorekeys from 'constants/firestorekeys';
import { collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { IoIosCloseCircle } from 'react-icons/io'
import { firestoreDB } from 'services/Firebase/firebaseConfig';

function Broadcasters({
    user,
    live
}) {

    const {i18n} = useTranslation()

    const handleRejetParticipate = async () => {
        const collectionRef = collection(
            firestoreDB,
            scheme,
            firestorekeys.lives,
            firestorekeys.channels,
            live?.live_channel_name,
            firestorekeys.requests
        );

        const refDoc = doc(collectionRef, user?.user_id?.toString())

        await deleteDoc(refDoc).then(async () => {
            console.log('Live participate request deleted.');

            const collectionRef2 = collection(
                firestoreDB,
                scheme,
                firestorekeys.lives,
                firestorekeys.channels,
                live?.live_channel_name,
                firestorekeys.audiences
            );

            const refDoc2 = doc(collectionRef2, user?.user_id?.toString())

            await updateDoc(refDoc2, {
                live_is_broadcaster: false,
                live_on_pause: false,
                live_on_mute: false,
            }).then(async () => {

            }).catch((error) => {
                console.error('Error update audience:', error);
            });

        }).catch((error) => {
            console.error('Error delete participate request:', error);
        });
    }




    return (
        <div className="bg-black w-full h-52">


            <div
                id={`remote-stream-${user?.live_remote_uid}`}
                className='w-full h-full'
                style={{ border: '1px solid #000000' }}>
            </div>

            <button
                onClick={handleRejetParticipate}
                style={{
                    position: 'absolute',
                    right: 0,
                    marginTop: '-208px'
                }}
            >
                <IoIosCloseCircle color="white" size={32} />
            </button>

            <div style={{
                position: 'absolute',
                marginTop: '-30px',
                marginLeft: '15px',
            }}>
                {user?.live_on_pause ?
                    <span className='text-white'>
                         {i18n.language == 'en' 
                      ? 'Live on pause'
                    : 'Live en pause'
                    }
                    </span>
                    :
                    <span className='text-white'>
                        {user?.user_surname} {user?.user_name}
                    </span>
                }
            </div>
        </div>
    )
}

export default Broadcasters