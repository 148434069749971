import { api, apiUrl } from 'api/api';
import Header from 'components/Header/Header';
import messagingKeys from 'constants/messagingKeys';
import dayjs from 'dayjs';
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { generateChatId } from 'hooks/Utils/Utils';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import GroupEdit from '../GroupChat/GroupEdit/GroupEdit';
import axios from 'axios'
import { firestoreDB } from 'services/Firebase/firebaseConfig';
import { scheme } from 'constants/env';
import firestorekeys from 'constants/firestorekeys';
import { collection, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import ConfirmationAlert from 'components/ConfirmationAlert/ConfirmationAlert';
import MemberCard from './MemberCard/MemberCard';
import { color } from 'hooks/Utils/color';
import { Spinner } from 'react-bootstrap';

const OneToOneProfil = () => {

    // Get route state
    const { conv_id } = useParams()
    const location = useLocation()

    const controller = new AbortController();
    const signal = controller.signal;

    const { t, i18n } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const { data: authReducerState } = useAuthReducer()

    const [image, setImage] = useState(null)
    const [group, setGroup] = useState(null)
    const [groupFirestore, setGroupFirestore] = useState(null)

    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showLeave, setShowLeave] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleShowEdit = () => setShowEdit(oldValue => !oldValue)
    const handleShowDelete = () => setShowDelete(oldValue => !oldValue)
    const handleShowLeave = () => setShowLeave(oldValue => !oldValue)

    const getToAddMember = () => {
        const chat_id = generateChatId(messagingKeys.groupChat, conv_id, authReducerState?.user?.user_id)
        navigate(`/group/${conv_id}/invite`, {
            state: {
                chat_id: chat_id,
                conv_id: conv_id,
                conv_name: group?.conv_name
            }
        })
    }




    useEffect(() => {
        fetchGroupDetails()
        const getFirestoreMessages = async (messages) => {
            try {
                const chat_id = generateChatId(messagingKeys.groupChat, conv_id, authReducerState?.user?.user_id)
                const collectionRef = doc(
                    firestoreDB,
                    scheme,
                    firestorekeys.messaging,
                    firestorekeys.conversations,
                    chat_id
                )
                await getDoc(collectionRef).then((documentSnapshot) => {
                    if (documentSnapshot.exists) {
                        console.log(documentSnapshot.data())
                        setGroupFirestore(documentSnapshot.data())
                    } else {
                        console.log('Document do not exist.')
                    }
                });
            } catch (error) {
                console.error('Error fetching messages:', error);
            }
        }
        getFirestoreMessages()
    }, [])

    const fetchGroupDetails = async () => {
        try {
            setIsLoading(true)
            const request = await api(
                `api/conversations/${conv_id}`,
                'GET',
                {},
                accessToken
            )
            const response = await request.json()
            setIsLoading(false)
            console.log('Fetch group chat:', response?.data)
            if (request.status === 200) {
                if (response.success) {
                    setGroup(response?.data)
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetch group details:', error);
            // setTimeout(() => {
            //     fetchGroupDetails()
            // }, 2000);
        }
    }

    function removeFirstWord(sentence) {
        let words = sentence.split(' ');
        words.shift(); // Retire le premier mot
        return words.join(' ');
    }


    const handleFileChange = async (e) => {
        if (e.target.files) {
            const file = e.target.files[0]
            const uri = window.URL.createObjectURL(file)
            setImage({ file, uri })
        }
    };


    const handleEditPicture = async () => {
        try {
            if (image?.uri) {

                const formData = new FormData()
                formData.append('conv_picture', image?.file)

                const response = await axios.post(
                    `${apiUrl}/api/conversations/${group?.conv_id}/change_picture`,
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Accept: 'application/json',
                        ...accessToken,
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = (progressEvent.loaded / progressEvent.total) * 100;
                        // console.log(`${progress}% publication en cours...`)
                    }
                });
                console.log('Response picture group:', response.data)
                if (response.status === 200) {
                    if (response?.data?.success) {

                        const chat_id = generateChatId(messagingKeys.groupChat, group?.conv_id, authReducerState?.user?.user_id)
                        const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString());

                        const groupUsers = [{
                            profile: {
                                prof_picture: response?.data?.data || null
                            },
                            prof_picture: response?.data?.data || null,
                            user_id: group?.conv_id || null,
                            user_surname: removeFirstWord(group?.conv_name) || null,
                            user_name: group?.conv_name.split(' ')[0] || null,
                            user_username: group?.conv_username || group?.conv_name?.toLowerCase()?.replaceAll(' ', '') || null,
                            user_civility: group?.conv_civility || null,
                            user_verified: group?.conv_verified || null,
                            user_gold: group?.conv_gold || null,
                            user_badge_business: group?.conv_user_badge_business || null,
                            user_badge_color: group?.conv_user_badge_color || null,
                            user_badge_food: group?.conv_user_badge_food || null,
                            user_badge_goal: group?.conv_user_badge_goal || null,
                            user_badge_hobby: group?.conv_user_badge_hobby || null,
                            user_match_value: group?.conv_user_match_value || null
                        }]

                        await updateDoc(docRef, {
                            users: groupUsers
                        }).then(() => {
                            console.log('Group chat deleted to firstore.');
                        }).catch((error) => {
                            console.error('Error delete firstore group chat:', error);
                        });

                        fetchGroupDetails()
                        setTimeout(() => {
                            setImage(null)
                        }, 1000);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }


    const deleteGroup = async () => {
        try {
            const request = await api(
                `api/conversations/${group?.conv_id}/delete`,
                'POST',
                null,
                accessToken,
            )
            const response = await request.json()
            console.log('Response delete group:', response)
            if (request?.status === 200) {
                if (response?.success) {

                    const chat_id = generateChatId(messagingKeys.groupChat, group?.conv_id, authReducerState?.user?.user_id)
                    const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString());

                    await deleteDoc(docRef).then(() => {
                        console.log('Group chat deleted to firstore.');
                        handleShowDelete()
                        navigate('/one-to-one')
                    }).catch((error) => {
                        console.error('Error delete firstore group chat:', error);
                    });

                }
            }
        } catch (error) {
            console.error('Error delete group:', error);
        }
    }

    const handleLeaveGroup = async () => {
        try {
            const request = await api(
                `api/conversations/${group?.conv_id}/leave`,
                'POST',
                {},
                accessToken
            )
            const response = await request.json()
            // console.log('Responseleave group:', response)
            if (request.status === 200) {
                if (response.success) {

                    const chat_id = generateChatId(messagingKeys.groupChat, group?.conv_id, authReducerState?.user?.user_id)
                    const docRef = doc(firestoreDB, scheme, firestorekeys.messaging, firestorekeys.conversations, chat_id?.toString());

                    const data = groupFirestore?.participants?.filter(item => item !== authReducerState?.user?.user_id)

                    await updateDoc(docRef, {
                        participants: data
                    }).then(() => {
                        console.log('Group chat leaved to firstore.');

                        navigate('/one-to-one')
                    }).catch((error) => {
                        console.error('Error leave firstore group chat:', error);
                    });

                }
            }
        } catch (error) {
            console.error(error);
        }
    }


    if (isLoading) {
        return (
            <div className='h-screen bg-white'>
                <Header title={group?.conv_name || i18n.language == 'en' ? 'Group' : 'Groupe'} goBack={() => navigate(-1)} className={'ps-3'} />
                <div className='h-96 flex justify-center items-center bg-white'>
                    <Spinner />
                </div>
            </div>
        )
    }


    return (
        <div className='h-screen bg-white'>
            <Header title={group?.conv_name} goBack={() => navigate(-1)} className={'ps-3'} />
            <div style={{ height: '94%' }} className='overflow-y-scroll'>
                <div className='h-36' style={{ backgroundColor: color.primary }} />
                <div className='flex justify-center' style={{ marginTop: '-80px' }}>
                    <div>
                        <img src={image?.uri || group?.conv_picture || require('assets/images/background_opinion.jpg')} className='w-36 h-36 ring-1 ring-gray-200 mx-auto mb-2 rounded-full object-cover object-center' alt='' />
                        {image?.file ?
                            <div>
                                <button
                                    onClick={handleEditPicture}
                                    className='text-xs mx-2 px-2 py-1 rounded-full text-white'
                                    style={{ backgroundColor: color.primary }}>
                                    {i18n.language == 'en'
                                        ? 'Save'
                                        : 'Enregistrer'
                                    }
                                </button>
                                <button
                                    onClick={() => setImage(null)}
                                    className='text-xs mx-2 px-2 py-1 rounded-full'
                                    style={{ color: color.primary, border: `1px solid ${color.primary}` }}>
                                    {i18n.language == 'en'
                                        ? 'Cancel'
                                        : 'Annuler'
                                    }
                                </button>
                            </div>
                            :
                            <div className='text-center'>
                                <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer text-center mx-auto rounded-md font-semibold  focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2"
                                    style={{
                                        color: color.primary
                                    }}>
                                    <span className='w-full text-sm text-center mx-auto'>
                                        {i18n.language == 'en'
                                            ? 'Edit image'
                                            : 'Modifier image'
                                        }
                                    </span>
                                    <input id="file-upload"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        name="file-upload" type="file"
                                        className="sr-only"
                                    />
                                </label>
                            </div>
                        }
                    </div>
                </div>

                <div className='px-3 py-2'>
                    <h1 className='text-center text-xl font-black'>{group?.conv_name}</h1>
                    <p className='text-center text-gray-500'>
                        {group?.users_count} {i18n.language == 'en' ? 'member(s)' : 'membre(s)'}
                    </p>
                </div>

                <div className='flex items-center pt-2 justify-center'>
                    {group?.creator?.user_id == authReducerState?.user?.user_id &&
                        <button onClick={handleShowEdit} className='text-center mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mx-auto" style={{ color: color.primary }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                            </svg>
                            <p className='text-sm' style={{ color: color.primary }}>
                                {i18n.language == 'en'
                                    ? 'Edit'
                                    : 'Modifier'
                                }
                            </p>
                        </button>
                    }

                    {group?.creator?.user_id == authReducerState?.user?.user_id &&
                        <button onClick={handleShowDelete} className='text-center mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mx-auto" style={{ color: color.primary }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                            </svg>
                            <p className='text-sm' style={{ color: color.primary }}>
                                {i18n.language == 'en'
                                    ? 'Delete'
                                    : 'Supprimer'
                                }
                            </p>

                        </button>
                    }

                    {group?.creator?.user_id !== authReducerState?.user?.user_id &&
                        <button onClick={handleShowLeave} className='text-center mx-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mx-auto" style={{ color: color.primary }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                            </svg>

                            <p className='text-sm' style={{ color: color.primary }}>
                                {i18n.language == 'en'
                                    ? 'Leave'
                                    : 'Quitter'
                                }
                            </p>
                        </button>
                    }


                </div>

                <div className='px-3 py-2'>
                    <div className='flex items-center py-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-gray-500">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>

                        <p className='text-center ms-3'>
                            {group?.conv_name}
                        </p>
                    </div>

                    {group?.conv_descr &&
                        <div className='flex py-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-gray-500">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                            </svg>

                            <p className='text-center ms-3 mt-1'>
                                {group?.conv_descr}
                            </p>
                        </div>
                    }

                    <div className='flex items-center py-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-gray-500">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                        </svg>

                        <p className='text-center ms-3'>
                            {group?.users_count} {i18n.language == 'en' ? 'member(s)' : 'membre(s)'}
                        </p>
                    </div>

                    <div className='flex items-center py-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8 text-gray-500">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
                        </svg>

                        <p className='text-center ms-3'>
                            {i18n.language ? 'Created at' : 'Créé le'} {dayjs(group?.conv_updated_at).locale(i18n.language).format('DD MMMM YYYY')}
                        </p>
                    </div>
                </div>

                <div className='px-3 py-2'>
                    <p className='font-bold mb-3' style={{ color: color.primary }}>
                        {i18n.language == 'en' ? 'Group members' : 'Membres du groupe'}
                    </p>

                    <div>
                        {group?.creator?.user_id == authReducerState?.user?.user_id &&
                            <div onClick={getToAddMember} className='flex items-center py-2 cursor-pointer'>
                                <div className='flex justify-center items-center w-10 h-10  ps-1  rounded-full' style={{ backgroundColor: color.primary }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
                                    </svg>
                                </div>
                                <p className='ms-2  text-sm font-bold' style={{ color: color.primary }}>
                                    {i18n.language == 'en'
                                        ? 'Add a member'
                                        : 'Ajouter un membre'
                                    }
                                </p>
                            </div>
                        }

                        {group?.users?.map((item, index) => {
                            return (
                                <div key={index}>
                                    <MemberCard
                                        conv_id={conv_id}
                                        user={item}
                                        group={group}
                                        groupFirestore={groupFirestore}
                                        onRefresh={fetchGroupDetails}
                                    />
                                </div>
                            )
                        })}

                        {authReducerState?.user?.user_id == group?.creator?.user_id &&
                            <div>
                                {group?.pending_users?.length !== 0 &&
                                    <p className=' font-bold mt-4' style={{ color: color.primary }}>
                                        {i18n.language == 'en' ? 'Invitation pending' : 'Invitation en attente'}
                                    </p>
                                }
                                {group?.pending_users?.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <MemberCard
                                                isPending={true}
                                                conv_id={conv_id}
                                                user={item}
                                                group={group}
                                                groupFirestore={groupFirestore}
                                                onRefresh={fetchGroupDetails}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        }

                    </div>

                </div>
            </div>


            <GroupEdit
                group={group}
                visible={showEdit}
                onChangeVisible={setShowEdit}
                onRefresh={fetchGroupDetails}
            />

            <ConfirmationAlert
                message={i18n.language == 'en'
                    ? 'Are you sure you want to delete this group?'
                    : 'Êtes-vous sure de vouloir supprimé ce groupe ?'
                }
                visible={showDelete}
                onChangeVisible={setShowDelete}
                onConfirm={deleteGroup}
            />

            <ConfirmationAlert
                message={i18n.language == 'en'
                    ? 'Are you sure you want to leave this group?'
                    : 'Êtes-vous sure de vouloir quitter ce groupe ?'
                }
                visible={showLeave}
                onChangeVisible={setShowLeave}
                onConfirm={handleLeaveGroup}
            />
        </div>
    )
}

export default OneToOneProfil