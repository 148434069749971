import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { api } from 'api/api';
import Header from 'components/Header/Header';
import coins from 'assets/coins/coins.png';
import { Spinner } from 'react-bootstrap';

const TransactionDetails = () => {

    const location = useLocation();
    const { transaction } = location.state
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { data: authContextState } = useAuthReducer();
    const accessToken = useAccessToken();

    const [transactionData, setTransactionData] = useState(transaction);
    const [isLoading, setIsLoading] = useState(!transaction);

    useEffect(() => {
        console.log(transaction)
        fetchTransactionDetails();
    }, []);

    const fetchTransactionDetails = async () => {
        try {
            const request = await api(
                `api/transactions/${transactionData?.transac_id}`,
                'GET',
                null,
                accessToken
            );
            const response = await request.json();
            if (request.ok && response?.success) {
                setTransactionData(response?.data);
            }
            setIsLoading(false);
        } catch (e) {
            console.warn(e.message);
        }
    };

    if (isLoading) {
        return (
            <div className={`flex flex-col items-center justify-center h-screen bg-white`}>
                <div className="flex flex-col font-bold text-gray-500">
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        className='mx-auto w-10 h-10 mb-2'
                    />
                    {i18n.language == 'en'
                        ? 'Loading...'
                        : 'Chargement...'
                    }
                </div>
            </div>
        );
    }

    return (
        <div className={`min-h-screen bg-white`}>
            <Header title='Transactions' goBack={() => navigate(-1)} />
            <div className="max-w-2xl mx-auto rounded-lg p-6">
                <h2 className="text-xl font-semibold">
                    {i18n.language == 'en'
                        ? 'Transaction details'
                        : 'Détails de la transaction'
                    }
                </h2>
                <div className="mt-4 space-y-4">

                    {transactionData?.transac_merchand_order_id && (
                        <div className="flex justify-between">
                            <span>Transaction ID</span>
                            <span className="font-medium">{transactionData?.transac_merchand_order_id}</span>
                        </div>
                    )}

                    {transactionData?.method && (
                        <div className="flex justify-between items-center">
                            <span>
                                {i18n.language == 'en'
                                    ? 'Payment method'
                                    : 'Méthode de paiement'
                                }
                            </span>
                            <div className="flex items-center">
                                <img src={transactionData?.method?.method_logo_url} alt="Payment Method" className="w-6 h-6 rounded-full border border-gray-400 mr-2" />
                                <span className="font-medium">{transactionData?.method?.method_name}</span>
                            </div>
                        </div>
                    )}

                    {transactionData?.transac_type !== 'Gold' && transactionData?.transac_type !== 'Verified' ? (
                        <div className="flex justify-between items-center">
                            <span>Coins</span>
                            <div className="flex items-center">
                                <img src={coins} alt="Coins" className="w-5 h-5 mr-2" />
                                <span className={`font-bold ${transactionData?.transac_coins < 0 ? 'text-red-500' : 'text-green-600'}`}>{transactionData?.transac_coins} coins</span>
                            </div>
                        </div>
                    ) : (
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span>
                                    {i18n.language == 'en'
                                        ? 'Subscription'
                                        : 'Souscription'
                                    }
                                </span>
                                <span className="font-medium">
                                    {transactionData?.transac_type === 'Gold'
                                        ? i18n.language == 'en' ? 'Premium account' : 'Compte premium'
                                        : i18n.language == 'en' ? 'Verified account' : 'Compte vérifié'
                                    }
                                </span>
                            </div>
                            <div className="flex justify-between">
                                <span>{t('profil:duration')}</span>
                                <span className="font-medium">{transactionData?.transac_amount === 10 || transactionData?.transac_amount === -10
                                    ? i18n.language == 'en' ? 'Monthly subscription' : 'Souscription mensuelle'
                                    : i18n.language == 'en' ? 'Annual subscription' : 'Souscription annuelle'
                                }
                                </span>
                            </div>
                        </div>
                    )}

                    <div className="flex justify-between">
                        <span>
                            {i18n.language == 'en'
                                ? 'Price'
                                : 'Prix'
                            }
                        </span>
                        <span className="font-bold">${transactionData?.transac_amount}</span>
                    </div>

                    {transactionData?.receiver && (
                        <div className="flex justify-between">
                            <span>{i18n.language === 'en' ? 'Recipient' : 'Destinataire'}</span>
                            <span className="font-medium">{transactionData?.receiver?.user_surname} {transactionData?.receiver?.user_name}</span>
                        </div>
                    )}

                    {transactionData?.method_fees && (
                        <div className="flex justify-between">
                            <span>{i18n.language == 'en'
                                ? 'Operator fees'
                                : 'Opérateur de l\'opérateur'
                            } ({transactionData?.method?.method_fees}%)</span>
                            <span className="font-medium">${((transactionData?.transac_amount * transactionData?.method?.method_fees) / 100).toFixed(2)}</span>
                        </div>
                    )}

                    <div className="flex justify-between border-t pt-4">
                        <span>
                            {i18n.language == 'en'
                                ? 'Transation account'
                                : 'Montant de la transaction'
                            }
                        </span>
                        <span className="font-bold">${transactionData?.transac_amount}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionDetails;
