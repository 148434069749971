import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillGift, AiOutlineEye } from 'react-icons/ai'
import Moment from 'react-moment'
import SondAttachment from '../SondAttachment/SondAttachment'
import SondCaption from './SondCaption/SondCaption'
import SondOption from './SondOption/SondOption'
import { useNavigate } from 'react-router-dom'
import coins from 'assets/coins/coins.png';
import SendGift from 'components/SendGIft/SendGift'
import { useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks'
import DonorList from 'components/DonorList/DonorList'


const OpinionBody = ({
  opinion,
  viewOpinion,
  setViewOpinion,
  updateOpinion,
  sourceFrom,
}) => {

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: authContextState } = useAuthReducer()
  const [attachments, setAttachments] = useState([])
  const [totalRecompense, setTotalRecompense] = useState(opinion?.sond_total_recompense || 0)
  const [isVisibleGift, setIsVisibleGift] = useState(false)
  const [isVisibleGifters, setIsVisibleGifters] = useState(false)

  const toggleGift = () => opinion?.sond_user != authContextState?.user?.user_id && setIsVisibleGift(!isVisibleGift)
  const toggleGifters = () => opinion?.sond_user == authContextState?.user?.user_id && setIsVisibleGifters(!isVisibleGift)


  // Get attachments when opinion changes
  useEffect(() => {
    console.log(opinion?.sond_picture)
    const sondAttachment = opinion?.sond_picture ? [{
      attach_url: opinion?.sond_picture,
      attach_thumbnail: opinion?.sond_thumbnail,
      attach_type: opinion?.sond_type
    }] : []

    setAttachments([...sondAttachment, ...opinion?.attachments])
  }, [opinion])



  const goToViewOpinion = () => {
    if (sourceFrom === 'ViewOpinion') {
      return
    }
    navigate(`/opinion/select/${opinion?.agora?.ago_id ? opinion?.agora?.ago_id : 0}/${opinion?.sond_id}`, {
      state: {
        details: opinion
      }
    })
  }

  const sendGift = (data) => {
    if (data?.datas?.sond_total_recompense) {
      setTotalRecompense(data?.datas?.sond_total_recompense)
    }
  }

  return (
    <div>

      <SondCaption
        text={opinion?.sond_caption}
        opinion={opinion}
        setViewOpinion={setViewOpinion}
        sourceFrom={sourceFrom}
      />

      <div className='flex justify-between px-2 lg:px-4 my-3' >

        <span className='text-xs lg:text-sm' style={{ color: '#1880a1', fontWeight: '400', margin: '0px' }}>

          {opinion?.sond_device_os === 'Android' ? t('opinion_for_android') :
            opinion?.sond_device_os === 'iOS' ? t('opinion_for_ios') :
              opinion?.sond_device_os === 'Web' ? t('opinion_for_web') :
                opinion?.sond_device_os === 'Huawei' ? t('opinion_for_huawei') : t('opinion_for_android')}

        </span>

        <div onClick={opinion?.sond_user != authContextState?.user?.user_id ? toggleGift : toggleGifters} className='text-xs lg:text-sm cursor-pointer' style={{ display: 'flex', alignItems: 'center' }}>
          <AiFillGift color={totalRecompense != 0 ? 'red' : '#1880a1'} className='w-4 h-4 lg:w-5 lg:h-5' />
          {totalRecompense != 0 &&
            <>
              <span style={{ color: 'red', fontWeight: '600', margin: '0px', marginLeft: '3px' }}>
                {totalRecompense}
              </span>
              <img src={coins} alt='coins' className='w-3 ms-1' />
            </>
          }
        </div>

        <div className='text-xs lg:text-sm' style={{ display: 'flex', alignItems: 'center' }}>
          <AiOutlineEye size={16} color='#1880a1' />
          <span style={{ color: '#1880a1', fontWeight: '400', margin: '0px', marginLeft: '3px' }}>
            {opinion?.sond_views}
          </span>
        </div>

        <p className='text-xs lg:text-sm' style={{ color: '#808080', margin: '0px' }}>
          <Moment format="DD MMM YYYY HH:mm">
            {opinion?.sond_publishing}
          </Moment>
        </p>

      </div>

      {!!opinion?.options?.length && (
        <div style={{
          display: 'flex', display: 'flex',
          flexWrap: 'wrap', width: '100%'
        }}>

          {/* Render options */}
          {opinion?.options?.map(option => {
            return (
              <SondOption
                key={option?.opt_id}
                sond_id={opinion?.sond_id}
                sond_caption={opinion?.sond_caption}
                sond_user={opinion?.sond_user}

                options={opinion?.options}

                opt_id={option?.opt_id}
                opt_image={option?.opt_image}
                opt_type={option?.opt_type}

                opt_size={option?.opt_size}
                opt_extension={option?.opt_extension}
                opt_duration={option?.opt_duration}
                opt_width={option?.opt_width}
                opt_height={option?.opt_height}
                opt_mime_type={option?.opt_mime_type}

                opt_thumbnail={option?.opt_thumbnail}
                opt_libelle={option?.opt_libelle}
                votes_count={option?.votes_count}

                total_count={opinion?.votes_count}
                can_user_vote={opinion?.can_user_vote}
                get_user_vote={opinion?.get_user_vote}


                updateOpinion={updateOpinion}
              // updateVote={updateVote}

              // isOpinionExpired={isOpinionExpired}

              />
            )

          })}
        </div>
      )}


      {/* Render attachment */}
      {attachments.length == 1 && (
        <SondAttachment
          attachment={attachments[0]}
          width='100%'
          height={attachments?.length == 1 && attachments[0]?.attach_type == 'video'
            ? '70vh'
            : 'auto'
          }
          style={{ borderRadius: '10px' }}
        />
      )}

      {attachments.length == 2 && (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={400}
            />
          </div>
          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[1]}
              height={400}
            />
          </div>
        </div>
      )}

      {attachments.length == 3 && (
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '50%', height: '100%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={507}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[1]}
              height={250}
            />
            <SondAttachment
              attachment={attachments[2]}
              height={250}
            />
          </div>
        </div>
      )}

      {attachments.length == 4 && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={300}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={300}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[2]}
              height={300}
            />
            <SondAttachment
              attachment={attachments[3]}
              height={300}
            />
          </div>
        </div>
      )}


      {attachments.length == 5 && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={250}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={250}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[2]}
              height={166}
            />
            <SondAttachment
              attachment={attachments[3]}
              height={166}
            />
            <SondAttachment
              attachment={attachments[4]}
              height={166}
            />
          </div>
        </div>
      )}

      {attachments.length == 6 && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={250}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={250}
            />
            <SondAttachment
              attachment={attachments[2]}
              height={250}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[3]}
              height={250}
            />
            <SondAttachment
              attachment={attachments[4]}
              height={250}
            />
            <SondAttachment
              attachment={attachments[5]}
              height={250}
            />
          </div>
        </div>
      )}


      {attachments.length == 7 && (
        <div style={{ width: '100%', display: 'flex' }}>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[0]}
              height={266}
            />
            <SondAttachment
              attachment={attachments[1]}
              height={266}
            />
            <SondAttachment
              attachment={attachments[2]}
              height={266}
            />
          </div>

          <div style={{ width: '50%' }}>
            <SondAttachment
              attachment={attachments[3]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[4]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[5]}
              height={200}
            />
            <SondAttachment
              attachment={attachments[6]}
              height={200}
            />
          </div>
        </div>
      )}


      {/* Send gift */}
      <SendGift
        sond_id={opinion?.sond_id}
        open={isVisibleGift}
        onClose={setIsVisibleGift}
        onChange={sendGift}
      />

      <DonorList
        recompenses={opinion?.recompenses}
        open={isVisibleGifters}
        onClose={setIsVisibleGifters}
      />


    </div>
  )
}

export default OpinionBody