import React from 'react'
import { ShimmerCircularImage, ShimmerSocialPost } from 'react-shimmer-effects'

const ShimmerPost = () => {
  return (
    <div >
        <ShimmerSocialPost type="both" />
        <ShimmerSocialPost type="image" />
        <ShimmerSocialPost type="text" />
    </div>
  )
}

export default ShimmerPost