import React, { useEffect, useState } from 'react'
import SondVideo from '../OpinionBody/SondVideo/SondVideo'

const SondAttachment = ({
    attachment = {},
    width = '100%',
    height = '400px'
}) => {


    return (
        <div>
            {attachment?.attach_url && (
                <div
                    style={{
                        width: "100%",
                        minHeight: height,
                        // margin: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {/* Render image */}
                    {attachment?.attach_type === "image" && (
                        <div
                            style={{
                                width: "100%",
                                height: height,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                src={attachment?.attach_url}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover", 
                                    objectPosition: "center",
                                    border: "1px white solid",
                                    borderRadius: "8px",
                                }}
                                alt=""
                            />
                        </div>
                    )}

                    {/* Render video */}
                    {attachment?.attach_type === "video" && (
                        <div
                            style={{
                                width: width,
                                // height: height,
                                aspectRatio: "16/9",
                                border: "1px white solid",
                                borderRadius: "8px",
                                backgroundColor: 'black'
                            }}
                        >
                            <SondVideo
                                attachment={attachment}
                                uri={attachment?.attach_url}
                                thumbnail={attachment?.attach_thumbnail}
                                width={width}
                                height={height}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default SondAttachment