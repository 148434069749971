import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useAuthReducer } from '../../../../hooks/ReducerHooks/ReducerHooks'
import { color } from '../../../../hooks/Utils/color'
import { Link } from 'react-router-dom'

const TransactionItem = ({
    transaction,
    user,
    transac_id,
    transac_user_id,
    transac_receiver_id,
    transac_external_id,
    transac_key,
    transac_merchand_order_id,
    transac_pricing_id,
    transac_model_id,
    transac_method_id,
    transac_amount,
    transac_coins,
    transac_method_number,
    transac_type,
    transac_method_fees,
    transac_payesha_fees,
    transac_model_type,
    transac_last_balance,
    transac_new_balance,
    transac_status,
    transac_date,
    method,
    pricing,
    transac_details,
    receiver
}) => {


    const { t, i18n } = useTranslation()
    const { data: authData } = useAuthReducer()
    const isEn = authData.language == 'en'
    

    const getTransactionType = () => {
        try {
            let type_name = null
            if (transac_type == 'Reload') {
                type_name = t('buying_coins')
            }
            if (transac_type == 'Verified' || transac_type == 'Gold') {
                type_name = t('verified_account')
            }
            if (transac_type == 'Gold') {
                type_name = t('premium_account')
            }
            if (transac_type == 'Sponsoring') {
                type_name = t('sponsored_opinion')
            }
            if (transac_type == 'Gift') {
                if (transac_user_id == authData?.user?.user_id) {
                    type_name = `${t('send_gift_to')} ${receiver?.user_surname} ${receiver?.user_name} (@${receiver?.user_username})`
                }

                if (transac_receiver_id == authData?.user?.user_id) {
                    type_name = `${t('gift_received_from')} ${user?.user_surname} ${user?.user_name} (@${receiver?.user_username})`
                }
            }
            return type_name
        } catch (e) {
            console.warn('Error get transaction type', e);
        }
    }


    const getCoins = () => {
        try {
            let coins = null
            if (transac_type == 'Reload') {
                coins = `+${transac_coins} ${transac_coins == 1 || transac_coins == -1 ? 'coin' : 'coins'}`
            }
            if (transac_type == 'Gift') {
                if (transac_user_id == authData?.user?.user_id) {
                    coins = `${transac_coins} ${transac_coins == 1 || transac_coins == -1 ? 'coin' : 'coins'}`
                } else {
                    coins = `+${transac_coins} ${transac_coins == 1 || transac_coins == -1 ? 'coin' : 'coins'}`
                }
            }
            if (transac_type == 'Sponsoring') {
                coins = `-${transac_coins} ${transac_coins == 1 || transac_coins == -1 ? 'coin' : 'coins'}`
            }
            if (transac_type == 'Verified' || transac_type == 'Gold') {
                if (transac_amount <= 10) {
                    coins = t('monthly_subscription')
                } else {
                    coins = t('annual_subscription')
                }
            }
            return coins
        } catch (e) {
            console.warn(e);
        }
    }







    if (transac_type == 'Verified' || transac_type == 'Gold') {
        return (
            <Link
                to={'/profiles/transactions/details'}
                state={{
                    transaction
                }}
                style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                <div style={{ width: '49%' }}>
                    <h6 style={{ fontSize: '13px', marginLeft: '-3px' }}>
                        {getCoins()}
                    </h6>
                    <h6 numberOfLines={2} style={{ fontSize: '14px' }}>
                        {getTransactionType(transac_type)}
                    </h6>
                    <h6 numberOfLines={1} style={{ textAlign: 'left', color: color.grayArgent, fontSize: '12px', marginTop: '5px' }}>
                        <Moment format='DD MMMM YYYY HH:mm:ss'>
                            {transac_date}
                        </Moment>
                    </h6>
                </div>

                <div style={{ marginTop: '4px' }}>
                    <h6 numberOfLines={2} style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>
                        {transac_coins} coins
                    </h6>
                    <h6 style={{ textAlign: 'right', color: color.dark, fontWeight: 'bold', fontSize: '14px', marginTop: '5px' }}>
                        {transac_amount}$
                    </h6>
                </div>
            </Link>
        )
    } else {
        return (
            <Link
                to={'/profiles/transactions/details'}
                state={{
                    transaction
                }}
                style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                <div style={{ width: '49%' }}>
                    <h6 numberOfLines={2} style={{ fontSize: '14px' }}>
                        {getTransactionType(transac_type)}
                    </h6>
                    <h6 numberOfLines={1} style={{ textAlign: 'left', color: color.grayArgent, fontSize: '12px', marginTop: '5px' }}>
                        <Moment format='DD MMMM YYYY HH:mm:ss'>
                            {transac_date}
                        </Moment>
                    </h6>
                </div>

                <div style={{ marginTop: '4px' }}>
                    <h6 style={{ textAlign: 'right', color: transac_type == 'Gift' && transac_user_id == authData?.user?.user_id || transac_type == 'Sponsoring' ? 'red' : color.success, fontWeight: 'bold', fontSize: '13px' }}>
                        {getCoins()}
                    </h6>
                    <h6 style={{ textAlign: 'right', color: color.dark, fontWeight: 'bold', fontSize: '14px', marginTop: '5px' }}>
                        {transac_amount}$
                    </h6>
                </div>
            </Link>
        )
    }
}

export default TransactionItem