import React, { useEffect, useRef, useState } from 'react'
import styles from './OneToOneMessage.module.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAccessToken, useAuthReducer } from 'hooks/ReducerHooks/ReducerHooks';
import { api } from 'api/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import InputEmoji from "react-input-emoji";
import { RiSendPlaneFill, RiUserUnfollowLine } from 'react-icons/ri'
import { RiAttachment2 } from 'react-icons/ri'
import axios from 'axios'
import { MdDeleteOutline, MdOutlineKeyboardBackspace } from 'react-icons/md';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import { color } from 'hooks/Utils/color';
import Compressor from 'compressorjs'
import { useTranslation } from 'react-i18next';
import ModelAlert from 'components/ModalAlert/ModelAlert';
import { IoIosCloseCircle } from 'react-icons/io';
import { Modal, Spinner } from 'react-bootstrap';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import Header from 'components/Header/Header';
import { FaMicrophone, FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { AiFillCloseCircle, AiFillGift, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { TfiMenu } from 'react-icons/tfi'
import { BiLockAlt } from 'react-icons/bi';
import useReducerAuthAction from 'hooks/ReducerHooksAction/useReducerAuthAction';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import useReducerOneToOneAction from 'hooks/ReducerHooksAction/useReducerOneToOneAction';
import MessageCard from './MessageCard/MessageCard';
import { access_extensions, audio_extensions, excel_extensions, image_extensions, powerpoint_extensions, video_extensions, word_extensions } from 'constants/formFiles';
import messagingKeys from 'constants/messagingKeys';
import { generateChatId } from 'hooks/Utils/Utils';
import { firestoreDB } from 'services/Firebase/firebaseConfig';
import { scheme } from 'constants/env';
import firestorekeys from 'constants/firestorekeys';
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc } from 'firebase/firestore';
import SendGift from 'components/SendGIft/SendGift';


const OneToOneMessage = () => {

    // Get route state
    const { conv_id } = useParams()
    const location = useLocation()

    const {
        chat_id,
        user,
        conversation
    } = location.state

    const controller = new AbortController();
    const signal = controller.signal;

    const { t } = useTranslation()
    const accessToken = useAccessToken()
    const navigate = useNavigate()

    const { data: authReducerState } = useAuthReducer()
    const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()
    const { REFRESH_MESSAGE_NUMBER, REFRESH_CONVERSATION, REFRESH_BUDDIES } = useReducerOneToOneAction()

    const [conv_type, setConvType] = useState(
        location.pathname?.includes('one-to-one')
            ? messagingKeys.oneToOneChat
            : messagingKeys.groupChat
    );

    const [chatId, setChatId] = useState(chat_id ||
        generateChatId(
            location.pathname?.includes('one-to-one')
                ? messagingKeys.oneToOneChat
                : messagingKeys.groupChat,
            conv_id,
            authReducerState?.user?.user_id
        )
    );

    const [userChat, setUserChat] = useState(user);
    const [buddies, setBuddies] = useState([]);

    const [messages, setMessages] = useState([]);

    const [page, setPage] = useState(1);
    const [hasMorePage, setHasMorePage] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const [message, setMessage] = useState('');
    const [replyMessage, setReplyMessage] = useState(null);

    const [attachments, setAttachments] = useState([]);
    const [attachmentsPreview, setAttachmentsPreview] = useState([]);

    const [isBloqued, setIsBloqued] = useState(false);
    const [mute, setMute] = useState(false);
    const [friendMute, setFriendMute] = useState(false);
    const [showAlertVideo, setShowAlertVideo] = useState(false)
    const [showMenu, setShowMenu] = useState(false)

    const refMessageBox = useRef(null)
    const [heightMessageBox, setHeightMessageBox] = useState(0)

    const [isVoiceMessage, setIsVoiceMessage] = useState(false)
    const [isLoadingRequest, setIsLoadingRequest] = useState(false)


    // RECORDE AUDIO
    const [recording, setRecording] = useState(false);
    const [audioURL, setAudioURL] = useState('');
    let mediaRecorder = null;

    const [isVisibleGift, setIsVisibleGift] = useState(false)

    const toggleGift = () => setIsVisibleGift(!isVisibleGift)

    // GET HEIGTH OF MESSAGE BOX
    useEffect(() => {
        setHeightMessageBox(refMessageBox?.current?.clientHeight)
    }, [])


    // FETCHING MESSAGES
    useEffect(() => {
        const q = query(
            collection(
                firestoreDB,
                scheme,
                firestorekeys.messaging,
                firestorekeys.conversations,
                chatId,
                firestorekeys.messages,
            ),
            orderBy('uid', 'desc')
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {

            const data = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))
            setMessages(data)

            // Update message status
            data?.filter(item =>
                item?.user?._id != authReducerState?.user_id &&
                item?.status != 'yes'
            )?.forEach(item => {
                updateMessageStatus(item?.id)
            })

        });
        return () => {
            unsubscribe();
        }
    }, [chatId]);

    const updateMessageStatus = async (id) => {
        try {
            if (!id) return

            const collRef = collection(
                firestoreDB,
                scheme,
                firestorekeys.messaging,
                firestorekeys.conversations,
                chatId,
                firestorekeys.messages,
            )

            const docRef = doc(
                collRef,
                id?.toString()
            );

            await updateDoc(docRef, {
                status: 'yes'
            }).then(() => {
                console.log('Doc updated.');
            }).catch((error) => {
                console.error('Error doc update:', error);
            });
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (location.pathname?.includes('one-to-one')) {
            fetchUserDetails()
            fetchOneToOneMessages()
        } else {
            fetchGroupDetails()
            fetchGroupChatMessages()
        }
    }, [conv_id])

    useEffect(() => {
        const fetchBuddies = async () => {
            try {
                const request = await api('api/user/get-buddies', 'GET', null, accessToken)
                const response = await request.json()
                // console.log('response buddies:', response?.buddies)
                if (request.ok && request.status === 200) {
                    const buddiesToStore = response?.buddies?.map(buddy => ({
                        user_id: buddy?.user_id,
                        name: buddy?.user_username,
                        profile: { prof_picture: buddy?.prof_picture },
                        user_name: buddy?.user_name,
                        user_surname: buddy?.user_surname,
                        user_username: buddy?.user_username,
                        user_civility: buddy?.user_civility,
                        user_verified: buddy?.user_verified,
                        user_match_value: buddy?.user_match_value,
                        user_badge_business: buddy?.user_badge_business,
                        user_badge_color: buddy?.user_badge_color,
                        user_badge_food: buddy?.user_badge_food,
                        user_badge_goal: buddy?.user_badge_goal,
                        user_badge_hobby: buddy?.user_badge_hobby,
                    }))
                    setBuddies(buddiesToStore)
                }
            } catch (error) {
                console.error('Error get-buddies:', error)
                setTimeout(() => {
                    fetchBuddies()
                }, 2000);
            }
        }
        fetchBuddies()
    }, [])


    const fetchUserDetails = async () => {
        try {
            const request = await api(
                `api/profile/user-profile-details/${conv_id}`,
                'GET',
                {},
                accessToken
            )
            const response = await request.json()
            // console.log('Response fetch user details:', response)
            if (response?.success) {

                setUserChat(response?.user_profile_details)

            }
        } catch (error) {
            console.error('Error fetch user details:', error);
            setTimeout(() => {
                fetchUserDetails()
            }, 2000);
        }
    }

    const fetchGroupDetails = async () => {
        try {
            const request = await api(
                `api/conversations/${conv_id}`,
                'GET',
                {},
                accessToken
            )
            const response = await request.json()
            // console.log('Fetch group chat:', response)
            if (request.status === 200) {
                if (response.success) {
                    const group_tchat = {
                        profile: {
                            prof_picture: response?.data?.conv_picture,
                        },
                        user_id: response?.data?.conv_id,
                        user_name: response?.data?.conv_name,
                        user_username: response?.data?.conv_name?.toLowerCase()?.replaceAll(' ', '')
                    }
                    setUserChat(group_tchat)
                }
            }
        } catch (error) {
            console.error('Error fetch group details:', error);
            setTimeout(() => {
                fetchGroupDetails()
            }, 2000);
        }
    }


    const fetchOneToOneMessages = async () => {
        try {
            const request = await api(
                `api/one/${conv_id}?paginate&page=${1}`,
                'GET',
                {},
                accessToken
            )
            const response = await request.json()
            // console.log('Response fetch oneToone messages:', response?.messages?.data)
            if (response?.success) {

                setIsBloqued(response?.user_blocked)
                if (response?.user_blocked) {
                    deleteAllMessages()
                    return
                }

                if (response?.messages?.next_page_url) {
                    setHasMorePage(true)
                } else {
                    setHasMorePage(false)
                }
                setPage(2)

                if (response?.messages?.data?.length !== 0) {

                    setMute(response?.auth_user_muted_conversation)
                    setFriendMute(response?.friend_user_muted_conversation)

                    const docConvRef = doc(
                        firestoreDB,
                        scheme,
                        firestorekeys.messaging,
                        firestorekeys.conversations,
                        chatId
                    )

                    await setDoc(docConvRef, {
                        chat_id: chatId,
                        conversation_id: conv_id,
                        conversation_type: conv_type,
                        users: [
                            authReducerState?.user,
                            response?.user
                        ],
                        participants: [
                            conv_id,
                            authReducerState?.user?.user_id
                        ]
                    }).then(() => {
                        console.log('Firestore user saved.');
                    }).catch((e) => {
                        console.error('Error user save firestore message.', e);
                    })


                    const data = response?.messages?.data?.map(message => ({
                        _id: message?.chat_messages_id,
                        text: message?.forwarded_message?.chat_messages_text || message?.chat_messages_text,
                        createdAt: message?.chat_messages_datetime,
                        user: {
                            _id: message?.sender_id,
                            name: `${message?.sender?.user_surname || ''} ${message?.sender?.user_name || ''}`,
                        },
                        message_parent_id: message?.message_parent_id,
                        message_parent: message?.message_parent,
                        status: message?.chat_messages_status,
                        attachments: message?.attachments || [],
                        story: message?.story || {},
                        forwarded: message?.forwarded,
                        forwarded_message: message?.forwarded_message || {},
                        same_user: getSameUser(message, response?.messages?.data)
                    }))

                    // setMessages(data)
                    getFirestoreMessages(data)
                    updateMessageStatut()
                }
            }
        } catch (error) {
            console.error('Error fetch message one to one:', error);
            setTimeout(() => {
                fetchOneToOneMessages()
            }, 2000);
        }
    }


    const loadMoreOneToOneMessages = async () => {
        try {
            if (hasMorePage && !isLoading && !isLoadingMore) {
                const request = await api(
                    `api/one/${conv_id}?paginate&page=${page}`,
                    'GET',
                    {},
                    accessToken
                )
                const response = await request.json()
                // console.log('Response fetch oneToone messages:', response?.messages?.data)
                if (response?.success) {

                    setIsBloqued(response?.user_blocked)
                    if (response?.user_blocked) {
                        deleteAllMessages()
                        return
                    }

                    if (response?.messages?.next_page_url) {
                        setHasMorePage(true)
                    } else {
                        setHasMorePage(false)
                    }
                    setPage(oldValue => oldValue + 1)

                    if (response?.messages?.data?.length !== 0) {
                        setMute(response?.auth_user_muted_conversation)
                        setFriendMute(response?.friend_user_muted_conversation)

                        const data = response?.messages?.data?.map(message => ({
                            _id: message?.chat_messages_id,
                            text: message?.forwarded_message?.chat_messages_text || message?.chat_messages_text,
                            createdAt: message?.chat_messages_datetime,
                            user: {
                                _id: message?.sender_id,
                                name: `${message?.sender?.user_surname || ''} ${message?.sender?.user_name || ''}`,
                            },
                            message_parent_id: message?.message_parent_id,
                            message_parent: message?.message_parent,
                            status: message?.chat_messages_status,
                            attachments: message?.attachments || [],
                            story: message?.story || {},
                            forwarded: message?.forwarded,
                            forwarded_message: message?.forwarded_message || {},
                            same_user: getSameUser(message, response?.messages?.data)
                        }))

                        // setMessages(data)
                        getFirestoreMessages(data)
                    }


                }
            }
        } catch (error) {
            console.error('Error fetch message one to one:', error);
            setTimeout(() => {
                fetchOneToOneMessages()
            }, 2000);
        }
    }


    const fetchGroupChatMessages = async () => {
        try {
            const request = await api(
                `api/conversations/${conv_id}/messages?page=${1}`,
                'GET',
                {},
                accessToken
            )
            const response = await request.json()
            console.log('Response fetch group messages:', response?.messages?.data)
            if (response?.success) {

                setIsBloqued(response?.user_blocked)
                if (response?.user_blocked) {
                    deleteAllMessages()
                    return
                }

                if (response?.messages?.next_page_url) {
                    setHasMorePage(true)
                } else {
                    setHasMorePage(false)
                }
                setPage(2)

                if (response?.messages?.data?.length !== 0) {
                    setMute(response?.auth_user_muted_conversation)
                    setFriendMute(response?.friend_user_muted_conversation)


                    const data = response?.messages?.data?.map(message => ({
                        _id: message?.chat_messages_id,
                        text: message?.forwarded_message?.chat_messages_text || message?.chat_messages_text,
                        createdAt: message?.chat_messages_datetime,
                        user: {
                            _id: message?.sender_id,
                            name: `${message?.sender?.user_surname || ''} ${message?.sender?.user_name || ''}`,
                        },
                        message_parent_id: message?.message_parent_id,
                        message_parent: message?.message_parent,
                        status: message?.chat_messages_status,
                        attachments: message?.attachments || [],
                        story: message?.story || {},
                        forwarded: message?.forwarded,
                        forwarded_message: message?.forwarded_message || {},
                        same_user: getSameUser(message, response?.messages?.data)
                    }))

                    // setMessages(data)
                    getFirestoreMessages(data)
                    updateMessageStatut()
                }

            }
        } catch (error) {
            console.error('Error fetch message one to one:', error)
            // setTimeout(() => {
            //     fetchGroupChatMessages()
            // }, 2000);
        }
    }


    const loadMoreGroupChatMessages = async () => {
        try {
            if (hasMorePage && !isLoading && !isLoadingMore) {
                const request = await api(
                    `api/conversations/${conv_id}/messages?page=${page}`,
                    'GET',
                    {},
                    accessToken
                )
                const response = await request.json()
                console.log('Response fetch group messages:', response)
                if (response?.success) {

                    setIsBloqued(response?.user_blocked)
                    if (response?.user_blocked) {
                        deleteAllMessages()
                        return
                    }

                    if (response?.messages?.next_page_url) {
                        setHasMorePage(true)
                    } else {
                        setHasMorePage(false)
                    }
                    setPage(oldValue => oldValue + 1)

                    if (response?.messages?.data?.length !== 0) {
                        setMute(response?.auth_user_muted_conversation)
                        setFriendMute(response?.friend_user_muted_conversation)


                        const data = response?.messages?.data?.map(message => ({
                            _id: message?.chat_messages_id,
                            text: message?.forwarded_message?.chat_messages_text || message?.chat_messages_text,
                            createdAt: message?.chat_messages_datetime,
                            user: {
                                _id: message?.sender_id,
                                name: `${message?.sender?.user_surname || ''} ${message?.sender?.user_name || ''}`,
                            },
                            message_parent_id: message?.message_parent_id,
                            message_parent: message?.message_parent,
                            status: message?.chat_messages_status,
                            attachments: message?.attachments || [],
                            story: message?.story || {},
                            forwarded: message?.forwarded,
                            forwarded_message: message?.forwarded_message || {},
                            same_user: getSameUser(message, data)
                        }))
                        // setMessages(data)
                        getFirestoreMessages(data)
                    }

                }
            }
        } catch (error) {
            console.error('Error fetch message one to one:', error)
        }
    }


    const loadMoreMessages = () => {
        if (conv_type == messagingKeys.oneToOneChat) {
            loadMoreOneToOneMessages()
        } else {
            loadMoreGroupChatMessages()
        }
    }


    const updateMessageStatut = async () => {
        try {
            const request = await api(
                conv_type == messagingKeys.oneToOneChat
                    ? `api/one/${conv_id}/read`
                    : `api/conversations/${conv_id}/read`,
                conv_type == messagingKeys.oneToOneChat
                    ? 'POST'
                    : 'GET',
                null,
                accessToken,
            )
            const response = await request.json()
        } catch ({ message }) {
            setTimeout(() => {
                updateMessageStatut()
            }, 2000);
            throw new Error(message)
        }
    }

    const getSameUser = (currentMessage, messages) => {
        try {
            if (authReducerState?.user?.user_id !== currentMessage?.sender_id) {
                const index = messages.findIndex(item =>
                    item?.chat_messages_id == currentMessage?.chat_messages_id
                )
                const messageFinded = messages[index + 1]
                if (currentMessage?.sender_id === messageFinded?.sender_id) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getFirestoreMessages = async (messages) => {
        try {
            const collectionRef = collection(
                firestoreDB,
                scheme,
                firestorekeys.messaging,
                firestorekeys.conversations,
                chatId,
                firestorekeys.messages
            )
            const querySnapshot = await getDocs(collectionRef);
            if (querySnapshot.size == 0) {
                messages?.forEach(item => {
                    saveFirestoreMessage(item)
                });
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    }

    const saveFirestoreMessage = async (message) => {

        const docRef = doc(
            firestoreDB,
            scheme,
            firestorekeys.messaging,
            firestorekeys.conversations,
            chatId,
            firestorekeys.messages,
            message?._id?.toString()
        )
        console.log('Message:', message)
        await setDoc(docRef, {
            ...message,
            uid: message?._id
        }).then(() => {
            console.log('Firestore message saved.');
        }).catch(() => {
            console.error('Error save firestore message');
        })
    }

    const saveFirestoreUser = async (data) => {
        const docRef = doc(
            firestoreDB,
            scheme,
            firestorekeys.messaging,
            firestorekeys.conversations,
            chatId
        )
        await setDoc(docRef, data).then(() => {
            console.log('Firestore message saved.');
        }).catch(() => {
            console.error('Error save firestore message');
        })
    }



    const updateLastSeen = async () => {
        try {
            // console.log('Updating last seen:', authReducerState?.user?.user_show_last_seen)
            const body = {
                user_show_last_seen: authReducerState?.user?.user_show_last_seen ? false : true
            }
            const request = await api(`api/user/update-show-last-seen`, 'POST', body, accessToken)
            const response = await request.json()
            // console.log('Response updating last seen:', response)
            if (request.ok && request.status === 200) {
                if (response?.success) {
                    setShowMenu(false)
                    updateUserDetails()
                }
            }
        } catch (error) {
            setShowMenu(false)
            console.error('Error update last seen:', error);
        }
    }

    const updateUserDetails = async () => {
        try {
            const request = await api('api/get-user', 'GET', {}, accessToken)
            const response = await request.json()
            // console.log('Response fetch user:', response?.user.user_show_last_seen)
            if (request.ok && request.status === 200) {
                UPDATE_PROFILE(response?.user)
            }
        } catch (e) {
            updateUserDetails()
            console.warn('Error fetch user info:', e.message)
            throw new Error(e)
        }
    }


    const deleteAllMessages = async () => {
        try {
            const body = {
                user_id: conv_id,
            }
            const request = await api(
                'api/one/delete-inbox',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            // console.log(response, 'response of delete message ')
            if (request.ok && request.status === 200) {
                if (response?.success) {
                    setShowMenu(false)
                    setMessages([])
                    if (isBloqued) {
                        navigate(-1)
                    }
                }
            }
        } catch (error) {
            setShowMenu(false)
            throw new Error(error.message)
        }
    }

    const blockBuddy = async () => {
        try {
            const body = { user_id: conv_id }
            const request = await api(
                'api/profile/blocked',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            // console.log(response, 'response of BLOC ON ONE TO ONE PROFIL')
            if (request.ok && request.status === 200) {
                if (response.success) {
                    setIsBloqued(true)
                    deleteAllMessages()
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }



    const handleSendMessage = async () => {

        const timestamp = new Date().getTime()

        const newMessage = {
            id: timestamp,
            uid: timestamp,
            _id: timestamp,
            text: message,
            createdAt: dayjs(timestamp).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
            user: {
                _id: authReducerState?.user?.user_id || null,
                name: `${authReducerState?.user?.user_name || ''} ${authReducerState?.user?.user_surname || ''}`,
            },
            sender: authReducerState?.user,
            received: userChat,
            status: 'no',
            message_parent_id: replyMessage?._id || null,
            message_parent: {
                receiver_id: conv_id || null,
                sender_id: authReducerState?.user?.user_id || null,
                chat_messages_id: replyMessage?._id || null,
                message_parent_id: replyMessage?._id || null,
                chat_messages_text: replyMessage?.text || null,
                attachments: replyMessage?.attachments || [],
                chat_messages_status: 'yes',
                chat_messages_datetime: dayjs(timestamp).format(
                    'YYYY-MM-DDTHH:mm:ss.sssZ',
                ),
            },
            attachments: attachments?.length != 0 ? attachments?.map(attach => ({
                attach_created_at: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
                attach_duration: attach?.duration,
                attach_extension: attach?.extension,
                attach_height: attach?.height,
                attach_id: timestamp,
                attach_index: null,
                attach_mime_type: attach?.mime_type,
                attach_model_id: timestamp,
                attach_model_type: "App\\Models\\ChatMessage",
                attach_size: attach?.size,
                attach_thumbnail: attach?.thumbnail,
                attach_type: attach?.type,
                attach_updated_at: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
                attach_url: attach?.uri,
                attach_width: attach?.width,
            }))
                : []
        }

        const docRef = doc(
            firestoreDB,
            scheme,
            firestorekeys.messaging,
            firestorekeys.conversations,
            chatId,
            firestorekeys.messages,
            timestamp?.toString()
        )

        await setDoc(docRef, {
            ...newMessage,
            uid: timestamp
        }).then(() => {
            console.log('Firestore message saved.');
            setMessage('')
            setReplyMessage(null)
            setAttachments([])
            setAttachmentsPreview([])

            handleSend(timestamp)
        }).catch(() => {
            console.error('Error save firestore message');
        })


        if (conv_type == messagingKeys.oneToOneChat) {
            const docConvRef = doc(
                firestoreDB,
                scheme,
                firestorekeys.messaging,
                firestorekeys.conversations,
                chatId
            )

            await setDoc(docConvRef, {
                chat_id: chatId,
                conversation_id: conv_id,
                conversation_type: conv_type,
                users: [
                    authReducerState?.user,
                    userChat
                ],
                participants: [
                    conv_id,
                    authReducerState?.user?.user_id
                ],
                last_message: newMessage,
                timestamp: new Date().getTime()
            }).then(() => {
                console.log('Firestore user saved.');
            }).catch((e) => {
                console.error('Error user save firestore message.', e);
            })
        }
    }


    const handleSend = async (messageId) => {
        try {
            if (!message && attachments?.length === 0) {
                return
            }

            // const timestamp = new Date().getTime()

            // const new_message = {
            //     chat_messages_id: new Date().getTime(),
            //     sender_id: authReducerState?.user?.user_id,
            //     receiver_id: parseInt(conv_id),
            //     chat_messages_text: message,
            //     chat_messages_status: "pending",
            //     message_parent_id: new Date().getTime(),
            //     message_parent: null,
            //     attachments: attachments?.map(attach => ({
            //         attach_created_at: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            //         attach_duration: attach?.duration,
            //         attach_extension: attach?.extension,
            //         attach_height: attach?.height,
            //         attach_id: timestamp,
            //         attach_index: null,
            //         attach_mime_type: attach?.mime_type,
            //         attach_model_id: timestamp,
            //         attach_model_type: "App\\Models\\ChatMessage",
            //         attach_size: attach?.size,
            //         attach_thumbnail: attach?.thumbnail,
            //         attach_type: attach?.type,
            //         attach_updated_at: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            //         attach_url: attach?.uri,
            //         attach_width: attach?.width,
            //     })),
            //     chat_messages_datetime: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            // }
            // setMessages([new_message, ...messages])

            // setIsLoadingRequest(true)
            const formData = new FormData();

            if (conv_type == messagingKeys.oneToOneChat) {
                formData.append('receiver_id', conv_id)
            } else {
                formData.append('message_conv_id', conv_id)
            }

            formData.append('chat_messages_text', message);

            // formData.append('message_parent_id', messageParent?.id);

            if (attachments[0]?.type == 'document') {
                formData.append('attachments', 1);
                formData.append(`attachment_1`, attachments[0]?.file);
                formData.append(`attach_filename_1`, attachments[0]?.file?.name);
                formData.append(`attachment_mime_type_1`, attachments[0]?.mime_type);
                formData.append(`attachment_type_1`, attachments[0]?.type);
                formData.append(`attachment_size_1`, attachments[0]?.size);
                formData.append(`attachment_extension_1`, attachments[0]?.name);
            } else if (attachments[0]?.type == 'audio') {
                formData.append('attachments', 1);
                formData.append(`attachment_1`, attachments[0]?.file);
                formData.append(`attachment_type_1`, attachments[0]?.type);
                formData.append(`attachment_mime_type_1`, attachments[0]?.mime_type);
                formData.append(`attachment_extension_1`, attachments[0]?.extension);
            } else {
                formData.append('attachments', attachments?.length);
                attachments.forEach((attachment, index) => {

                    formData.append(`attachment_${index + 1}`, attachment?.file);
                    formData.append(`attach_filename_${index + 1}`, attachment?.file?.name);
                    formData.append(`attachment_type_${index + 1}`, attachment?.type);
                    formData.append(`attachment_mime_type_${index + 1}`, attachment?.mime_type);

                    if (attachment?.type !== 'audio') {
                        formData.append(`attachment_width_${index + 1}`, attachment?.width);
                        formData.append(`attachment_height_${index + 1}`, attachment?.height);
                        formData.append(`attachment_size_${index + 1}`, attachment?.size);
                    }
                    if (attachment?.type == 'video') {
                        formData.append(`attachment_thumbnail_${index + 1}`, attachment?.thumbnail);
                        formData.append(`attachment_duration_${index + 1}`, attachment?.duration);
                    }
                });
            }

            setMessage('')
            setAttachments([])
            setAttachmentsPreview([])

            const request = await axios.post(
                // `${API_URL}/api/sendMessage`,
                `${process.env.REACT_APP_API_URL}/api/user/v2/sendMessage`,
                formData, {
                onUploadProgress: progressEvent => {
                    let { loaded, total } = progressEvent;
                    console.log(Math.round((loaded / total) * 100), '% envoi...')
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    ...accessToken,
                },
            });
            const response = await request?.data
            console.log('Response send message:', response)
            if (request?.status === 200) {
                if (response?.success) {
                    setMessage('')
                    setAttachments([])
                    setAttachmentsPreview([])

                    const newMessage = response?.message

                    const docRef = doc(
                        firestoreDB,
                        scheme,
                        firestorekeys.messaging,
                        firestorekeys.conversations,
                        chatId,
                        firestorekeys.messages,
                        messageId?.toString()
                    )
                    await updateDoc(docRef, {
                        _id: newMessage?.chat_messages_id,
                        attachments: newMessage?.attachments
                    }).then(() => {
                        console.log('Firestore message updated.');
                    }).catch(() => {
                        console.error('Error update firestore message');
                    })
                }
            }
        } catch (error) {
            console.error('Error send message:', error?.response?.data || error);
        }
    }


    // AUDIO RECORDING
    const handleDataAvailable = (event) => {
        const file = new Blob([event.data], { type: 'audio/mp3' });
        const url = URL.createObjectURL(file);
        setAudioURL(url);

        const data = {
            file,
            width: null,
            height: null,
            duration: null,
            size: file.size,
            type: 'audio',
            thumbnail: null,
            extension: file.type.split("/")[1],
            mime_type: file.type
        }
        setAttachments(attachments => [...attachments, data])
    };

    const startRecording = () => {
        mediaRecorder.start();
        setRecording(true);
    };

    const stopRecording = () => {
        mediaRecorder.stop();
        setRecording(false);
    };

    const sendAudio = () => {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                // console.log('Audio sent successfully');
            }
        };
        xhr.open('POST', 'your-server-url', true);
        xhr.send(audioURL);
    };



    const handleFileChange = async (e) => {
        if (e.target.files) {

            const file = e.target.files[0]
            const fileUri = window.URL.createObjectURL(file)
            // console.log('Flie selected:', file)
            // console.log(file?.name?.split('.').pop())
            if (file.type.split("/")[0] === 'application') {

                const data = {
                    file: file,
                    uri: fileUri,
                    size: file?.size,
                    type: 'document',
                    extension: file?.name?.split('.').pop(),
                    mime_type: file?.type
                }
                setAttachments(attachments => [...attachments, data])
                setAttachmentsPreview(value => [...value, { uri: fileUri, type: 'document', extension: file?.name?.split('.').pop() }])

            }
            if (file.type.split("/")[0] === 'image') {

                setAttachmentsPreview(value => [...value, { uri: fileUri, type: 'image' }])

                new Compressor(file, {
                    quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                    success: async (compressedResult) => {
                        console.log('compressedResult:', compressedResult)
                        const { width, height } = await getImageParams(file)

                        const data = {
                            file: compressedResult,
                            uri: fileUri,
                            width,
                            height,
                            size: file?.size,
                            type: 'image',
                            extension: file?.type.split("/")[1],
                            mime_type: file?.type
                        }

                        setAttachments(attachments => [...attachments, data])

                    },
                });
            }

            if (file.type.split("/")[0] === 'video') {

                const megabytes = (file.size / 1048576).toFixed(0);
                if (megabytes > 50) {
                    setShowAlertVideo(true)
                } else {
                    // generate video thumbnail
                    const thumbnail = await generateVideoThumbnail(e.target.files[0]);
                    if (thumbnail) {

                        // set video thumbnail
                        const { thumbnailUri, thumbnailFile } = await convertBase64ToFile(thumbnail)

                        setAttachmentsPreview(value => [...value, { uri: thumbnailUri, type: 'video' }])

                        const meta = await getVideoMetadata(file)
                        // console.log('Video meta:', meta)

                        // load file
                        const data = {
                            file,
                            uri: thumbnailUri,
                            width: meta?.width,
                            height: meta?.height,
                            duration: meta?.duration,
                            size: file.size,
                            type: 'video',
                            thumbnail: thumbnailFile,
                            extension: file.type.split("/")[1],
                            mime_type: file.type
                        }

                        setAttachments(attachments => [...attachments, data])
                    }
                }
            }

        }
    };




    const generateVideoThumbnail = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");

            // this is important
            video.currentTime = 5;
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL("image/png"));
            };
        });
    };



    function getVideoMetadata(file) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.src = URL.createObjectURL(file);

            video.onloadedmetadata = () => {
                URL.revokeObjectURL(video.src);
                resolve({
                    duration: video.duration,
                    width: video.videoWidth,
                    height: video.videoHeight
                });
            };

            video.onerror = reject;
        });
    }


    const convertBase64ToFile = async (base64Image) => {
        const blob = await fetch(base64Image).then((res) => res.blob());
        const thumbnailFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        const thumbnailUri = URL.createObjectURL(thumbnailFile);
        // setImageUrl(imageUrl);
        // console.log('convertBase64ToFile:', file)
        return { thumbnailUri, thumbnailFile }
    };



    // reading a file to get height and width
    async function getImageParams(file) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader()

            reader.onload = async (e) => {
                var image = new Image()
                image.src = e.target.result
                await image.decode()

                resolve({ width: image.width, height: image.height })
            }
            reader.readAsDataURL(file)
        })
    }



    const handleDeleteAttachment = (uri) => {
        setAttachmentsPreview(attachmentsPreview.filter((attach) => attach?.uri !== uri))
        setAttachments(attachments.filter((attach) => attach?.uri !== uri))
    }



    // HANDLE SCROLL TOP TO PAGINATE
    const handleScroll = (event) => {
        const { scrollHeight, scrollTop, clientHeight } = refMessageBox?.current
        if (scrollTop <= clientHeight - scrollHeight + 1) {
            loadMoreMessages();
        }
    };


    const header = () => {
        if (isLoading) {
            return (
                <div style={{
                    height: '100vh',
                    overflow: 'hidden',
                    background: 'white'
                }}>
                    <Header title='Message' />
                    <div style={{ textAlign: 'center', marginTop: '40vh' }}>
                        <Spinner
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            variant='secondary'
                            style={{
                                width: '30px',
                                height: '30px'
                            }}
                        />
                        <h6 style={{ color: '#808080', marginTop: 10 }}>
                            {t('loading')}
                        </h6>
                    </div>
                </div>
                //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>

                // </div>
            )
        } else {
            return (
                <div className={styles.header}>
                    <button onClick={() => navigate(-1)} className={styles.backButtonHeader} >
                        <MdOutlineKeyboardBackspace size={32} />
                    </button>
                    <div>
                        <ProfileCard user={userChat} />
                    </div>
                    <button onClick={() => setShowMenu(true)} className={styles.menuButtonHeader}>
                        <TfiMenu size={28} color={color.dark} />
                    </button>
                </div>
            )
        }
    }


    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '1%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading')}
                    </h6>
                </div>
            </div>
        )
    }


    const renderAttachPreview = () => {
        if (attachmentsPreview.length !== 0) {
            return (
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
                    {attachmentsPreview?.map((attach, index) => {
                        if (attach?.type === 'document') {
                            return (
                                <div key={index.toString()} style={{ width: '50px', marginLeft: '15px' }}>
                                    <img
                                        src={
                                            image_extensions?.includes(attach?.extension) ?
                                                require('assets/docs/image_icon.png') :
                                                audio_extensions?.includes(attach?.extension) ?
                                                    require('assets/docs/audio.png') :
                                                    video_extensions?.includes(attach?.extension) ?
                                                        require('assets/docs/video_icon.png') :
                                                        word_extensions?.includes(attach?.extension) ?
                                                            require('assets/docs/word_icon.png') :
                                                            attach?.extension === 'pdf' ?
                                                                require('assets/docs/pdf_icon.png') :
                                                                excel_extensions?.includes(attach?.extension) ?
                                                                    require('assets/docs/excell_icon.png') :
                                                                    powerpoint_extensions?.includes(attach?.extension) ?
                                                                        require('assets/docs/pptx_icon.png') :
                                                                        access_extensions?.includes(attach?.extension) === 'accdb' ?
                                                                            require('assets/docs/access_icon.png') :
                                                                            attach?.extension === 'txt' ?
                                                                                require('assets/docs/txt.png') : require('assets/docs/file_icon.png')

                                        }
                                        width={50}
                                        height={50}
                                        alt=''
                                        style={{
                                            marginRight: '15px',
                                            // marginBottom: '10px',
                                            border: `1px ${color.primary} solid`,
                                            borderRadius: '3px'
                                        }}
                                    />
                                    <button
                                        style={{
                                            marginLeft: 'auto',
                                            marginTop: '-20px',
                                            backgroundColor: 'transparent'
                                        }}
                                        onClick={() => handleDeleteAttachment(attach?.uri)}>
                                        <IoIosCloseCircle size={20} color={color.danger} />
                                    </button>
                                </div>
                            )
                        } else {
                            return (
                                <div key={index.toString()} style={{ width: '50px', marginLeft: '15px' }}>
                                    <img
                                        src={attach?.uri}
                                        width={50}
                                        height={50}
                                        alt=''
                                        style={{
                                            marginRight: '15px',
                                            // marginBottom: '10px',
                                            border: `1px ${color.primary} solid`,
                                            borderRadius: '3px'
                                        }}
                                    />
                                    <button
                                        style={{
                                            marginLeft: 'auto',
                                            marginTop: '-20px',
                                            backgroundColor: 'transparent'
                                        }}
                                        onClick={() => handleDeleteAttachment(attach?.uri)}>
                                        <IoIosCloseCircle size={20} color={color.danger} />
                                    </button>
                                </div>
                            )
                        }
                    })}
                </div>
            )
        }
    }



    return (
        <div className='h-screen bg-white'>

            {/* HEADER */}
            <div style={{ height: '50.5px' }} className='flex items-center ps-2 border-b border-gray-200'>
                <button onClick={() => navigate(-1)} className={styles.backButtonHeader} >
                    <MdOutlineKeyboardBackspace size={32} />
                </button>
                {userChat &&
                    <div onClick={() => {
                        if (conv_type == messagingKeys.oneToOneChat) {
                            navigate(`/profile/${userChat?.user_username}`)
                        } else {
                            navigate(`/conversation/${conv_id}/profil`)
                        }
                    }
                    } className='flex cursor-pointer'>
                        <img src={userChat?.profile?.prof_picture || require('assets/images/background_opinion.jpg')} className='w-9 h-9 ring-1 ring-gray-200 ms-2 me-2 rounded-full object-cover object-center' alt='' />
                        <div>
                            <h3 className='font-bold'>{userChat?.user_surname} {userChat?.user_name}</h3>
                            <p className='text-gray-500 text-xs'>@{userChat?.user_username}</p>
                        </div>
                    </div>
                }
                {conv_type !== messagingKeys.groupChat &&
                    <div className='flex items-center ms-auto'>
                        <button
                            onClick={toggleGift}
                            className={`${styles.menuButtonHeader} p-2 hover:bg-slate-200 rounded-full`}>
                            <AiFillGift
                                color={'#1880a1'}
                                className='w-6 h-6 lg:w-8 lg:h-8' />
                        </button>
                        <button
                            onClick={() => navigate(`/one-to-one/${conv_id}/calling`, {
                                state: {
                                    chat_id,
                                    conv_id,
                                    user: userChat,
                                    type: 'audio'
                                }
                            })}
                            className={`${styles.menuButtonHeader} p-2 hover:bg-slate-200 rounded-full`}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 lg:size-7" style={{ color: color.primary }}>
                                <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd" />
                            </svg>
                        </button>

                        <button
                            onClick={() => navigate(`/one-to-one/${conv_id}/calling`, {
                                state: {
                                    chat_id,
                                    conv_id,
                                    user: userChat,
                                    type: 'video'
                                }
                            })}
                            className={`${styles.menuButtonHeader} p-2 hover:bg-slate-200 rounded-full`}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5 lg:size-7" style={{ color: color.primary }}>
                                <path d="M4.5 4.5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h8.25a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H4.5ZM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06Z" />
                            </svg>

                        </button>
                    </div>

                }
            </div>


            {/* MESSAGES */}
            <div id='scrollableDiv' style={{ backgroundColor: color.primaryLight }} className={`overflow-y-hidden ${attachments != 0 ? 'h-3/4' : replyMessage ? 'h-4/5' : 'h-5/6'}`}>
                <InfiniteScroll
                    dataLength={messages?.length}
                    next={loadMoreMessages}
                    hasMore={hasMorePage}
                    loader={renderLoader}
                    scrollableTarget="scrollableDiv"
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        height: '86vh',
                        paddingTop: '150px',
                        paddingBottom: '20px',
                        overflowX: 'hidden'
                    }}
                    inverse={true}>
                    {messages.map((message, index) => {
                        return (
                            <div key={index.toString()} className='mx-1'>
                                <MessageCard
                                    userChat={userChat}
                                    chatId={chatId}
                                    user_id={conv_id}
                                    conv_type={conv_type}
                                    received={userChat}
                                    message={message}
                                    messages={messages}
                                    buddies={buddies}
                                    setReplyMessage={setReplyMessage}
                                    setMessages={setMessages}
                                />
                            </div>
                        )
                    })}
                    {isLoadingMore &&
                        <div style={{ width: '100%', height: '30px', textAlign: 'center', padding: '5px' }}>
                            <Spinner variant='secondary' />
                        </div>
                    }
                </InfiniteScroll>
            </div>

            <div className={styles.inputMessage}>
                <div style={{ width: '100%' }}>

                    {/* ATTACHMENT */}
                    {attachments?.length !== 0 && renderAttachPreview()}


                    {/* REPLY */}
                    {replyMessage &&
                        <div className='flex justify-between items-center bg-white py-2 px-3 border-b border-gray-200' style={{ marginLeft: '-20px', marginRight: '-20px' }}>

                            <div>
                                {userChat?.user?.name &&
                                    <p className='text-orange-400'>{userChat?.user?.name}</p>
                                }
                                <p className='text-gray-500'>{replyMessage?.text}</p>
                            </div>

                            <button onClick={() => setReplyMessage(null)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-orange-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                            </button>
                        </div>
                    }

                    {isVoiceMessage ?
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <audio src={audioURL} controls style={{ height: '35px' }} />
                                <button onClick={startRecording} disabled={recording}>Enregistrer</button>
                                <button onClick={stopRecording} disabled={!recording}>Stop</button>
                                <button onClick={handleSendMessage} disabled={!audioURL}>Envoyer</button>
                            </div>
                            <button onClick={() => setIsVoiceMessage(false)}>
                                <AiFillCloseCircle size={30} color={color.primary} />
                            </button>
                        </div>
                        :
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                            <label
                                htmlFor="choice1"
                                onChange={(e) => handleFileChange(e)}
                                style={{
                                    borderRadius: '3px',
                                    padding: '7px',
                                    cursor: 'pointer',
                                }}>
                                <input accept="image/*, video/*, application/*" name="" type="file" id="choice1" hidden />
                                <RiAttachment2 size={30} color={color.primary} style={{ backgroudColor: color.primary, border: '2px ' + color.primary + ' solid', borderRadius: '30px', padding: '3px' }} />
                            </label>

                            <InputEmoji
                                value={message}
                                onChange={setMessage}
                                // cleanOnEnter
                                shouldReturn={true}
                                keepOpened
                                height={80}
                                onEnter={text => handleSendMessage(text)}
                                theme={'light'}
                                placeholder={t('write_your_message')}
                            />


                            {isLoadingRequest ?
                                <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                        variant={'primary'}
                                    />
                                </div>
                                :
                                <button
                                    onClick={handleSendMessage}
                                    type='submit'
                                    className='w-10 h-9 rounded-full'
                                    style={{
                                        // width: '30px',
                                        // height: '30px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: color?.primary,
                                        // borderRadius: '30px'
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                                    </svg>

                                    {/* <RiSendPlaneFill size={25} color='white' style={{ marginTop: '2px' }} /> */}
                                </button>
                            }
                        </div>}


                </div>

            </div>


            <Modal show={showMenu}>
                <Header title='Menu' type='modal' goBack={() => setShowMenu(false)} />

                <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>

                    <div
                        onClick={updateLastSeen}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '15px'
                        }}>
                        {authReducerState?.user?.user_show_last_seen ? <AiOutlineEyeInvisible size={26} color='#808080' /> : <AiOutlineEye size={26} color='#808080' />}
                        <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                            {authReducerState?.user?.user_show_last_seen ? t('hide_your_last_seen') : t('show_your_last_seen')}
                        </span>
                    </div>

                    <div
                        onClick={deleteAllMessages}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '15px'
                        }}>
                        <MdDeleteOutline size={26} color='#808080' />
                        <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                            {t('delete_all_messages')}
                        </span>
                    </div>
                    <div
                        onClick={blockBuddy}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                            marginBottom: '15px'
                        }}>
                        <RiUserUnfollowLine size={26} color='#808080' />
                        <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
                            {t('block')} {userChat?.user_surname}  {userChat?.user_name}
                        </span>
                    </div>

                </div>

            </Modal>


            <ModelAlert
                message={t('video_too_large_please_select_a_video_less_than_50mb')}
                show={showAlertVideo}
                setShow={setShowAlertVideo}
            />

            {/* Send gift */}
            <SendGift
                user_id={conv_id}
                open={isVisibleGift}
                onClose={setIsVisibleGift}
            />

        </div >
    )





    // const { UPDATE_PROFILE, UPDATE_AUDIENCE, LOGOUT } = useReducerAuthAction()
    // const { REFRESH_MESSAGE_NUMBER, REFRESH_CONVERSATION, REFRESH_BUDDIES } = useReducerOneToOneAction()
    // const { data: authReducerState } = useAuthReducer()
    // const { t } = useTranslation()
    // const accessToken = useAccessToken()
    // const navigate = useNavigate()

    // const [userChat, setuserChat] = useState({});

    // const [messages, setMessages] = useState([]);
    // const [page, setPage] = useState(1);
    // const [hasMorePage, setHasMorePage] = useState(true);
    // const [isLoading, setIsLoading] = useState(true);
    // const [isLoadingMore, setIsLoadingMore] = useState(false);

    // const [message, setMessage] = useState('');
    // const [attachments, setAttachments] = useState([]);
    // const [attachmentsPreview, setAttachmentsPreview] = useState([]);

    // const [isBloqued, setIsBloqued] = useState(false);
    // const [mute, setMute] = useState(false);
    // const [friendMute, setFriendMute] = useState(false);
    // const [showAlertVideo, setShowAlertVideo] = useState(false)
    // const [showMenu, setShowMenu] = useState(false)

    // const refMessageBox = useRef(null)
    // const [heightMessageBox, setHeightMessageBox] = useState(0)

    // const [isVoiceMessage, setIsVoiceMessage] = useState(false)
    // const [isLoadingRequest, setIsLoadingRequest] = useState(false)


    // // RECORDE AUDIO
    // const [recording, setRecording] = useState(false);
    // const [audioURL, setAudioURL] = useState('');
    // let mediaRecorder = null;

    // // GET HEIGTH OF MESSAGE BOX
    // useEffect(() => {
    //     setHeightMessageBox(refMessageBox?.current?.clientHeight)
    // }, [])


    // const handleDataAvailable = (event) => {
    //     const file = new Blob([event.data], { type: 'audio/mp3' });
    //     const url = URL.createObjectURL(file);
    //     setAudioURL(url);

    //     const data = {
    //         file,
    //         width: null,
    //         height: null,
    //         duration: null,
    //         size: file.size,
    //         type: 'audio',
    //         thumbnail: null,
    //         extension: file.type.split("/")[1],
    //         mime_type: file.type
    //     }
    //     setAttachments(attachments => [...attachments, data])
    // };

    // const startRecording = () => {
    //     mediaRecorder.start();
    //     setRecording(true);
    // };

    // const stopRecording = () => {
    //     mediaRecorder.stop();
    //     setRecording(false);
    // };

    // const sendAudio = () => {
    //     const xhr = new XMLHttpRequest();
    //     xhr.onreadystatechange = () => {
    //         if (xhr.readyState === 4 && xhr.status === 200) {
    //             // console.log('Audio sent successfully');
    //         }
    //     };
    //     xhr.open('POST', 'your-server-url', true);
    //     xhr.send(audioURL);
    // };



    // useEffect(() => {
    //     fetchMessages()
    //     updateMessageStatut()
    //     const timerMessage = setInterval(() => {
    //         fetchUnreadMessages()
    //     }, 5000);
    //     return () => {
    //         clearInterval(timerMessage)
    //         controller.abort()
    //     }
    // }, [])


    // const fetchUnreadMessages = async () => {
    //     try {
    //         const request = await api(`api/one/${user_id}/unreadmessages`, 'GET', null, accessToken)
    //         const response = await request.json()
    //         // console.log('Response unread messages:', response.messages.length + ' messages')
    //         if (request.ok && request.status === 200) {
    //             setIsBloqued(response?.user_blocked)
    //             if (response?.user_blocked) {
    //                 deleteAllMessages()
    //             } else {
    //                 setMute(response?.auth_user_muted_conversation)
    //                 setFriendMute(response?.friend_user_muted_conversation)
    //                 if (response.messages.length !== 0) {
    //                     fetchMessages()
    //                     updateMessageStatut()
    //                 }
    //             }
    //         }
    //     } catch ({ message }) {
    //         console.log(message)
    //         throw new Error(message)
    //     }
    // }

    // const updateMessageStatut = async () => {
    //     try {
    //         const request = await api(`api/one/${user_id}/read`, 'POST', null, accessToken)
    //         const response = await request.json()
    //         // console.log('response update message statut:', response)
    //         fetchConversations()
    //     } catch ({ message }) {
    //         updateMessageStatut()
    //         throw new Error(message)
    //     }
    // }


    // const fetchMessages = async () => {
    //     try {
    //         // console.log('Fetching message...')
    //         const request = await api(`api/one/${user_id}?paginate&page=${1}`, 'GET', null, { ...accessToken, ...signal })
    //         const response = await request.json()
    //         // console.log('Response message:', response)
    //         setIsLoading(false)
    //         if (request.ok && request.status === 200) {
    //             if (response.success) {
    //                 setuserChat({
    //                     user_surname: response?.user?.user_surname,
    //                     user_name: response?.user?.user_name,
    //                     user_username: response?.user?.user_username,
    //                     user_verified: response?.user?.user_verified,
    //                     user_gold: response?.user?.user_gold,
    //                     profile: {
    //                         prof_picture: response?.user?.profile?.prof_picture
    //                     },
    //                     connection_status: response?.user?.connection_status,
    //                     user_badge_business: response?.user?.user_badge_business,
    //                     user_badge_color: response?.user?.user_badge_color,
    //                     user_badge_food: response?.user?.user_badge_food,
    //                     user_badge_goal: response?.user?.user_badge_goal,
    //                     user_badge_hobby: response?.user?.user_badge_hobby,
    //                     user_match_value: response?.user?.user_match_value,
    //                 })
    //                 setIsBloqued(response?.user_blocked)
    //                 if (response?.user_blocked) {
    //                     deleteAllMessages()
    //                 } else {
    //                     if (response?.messages?.next_page_url) {
    //                         setHasMorePage(true)
    //                     } else {
    //                         setHasMorePage(false)
    //                     }
    //                     setPage(2)

    //                     if (response?.messages?.data?.length !== 0) {

    //                         setMessages(response?.messages?.data)

    //                         setMute(response?.auth_user_muted_conversation)
    //                         setFriendMute(response?.friend_user_muted_conversation)
    //                     }

    //                 }
    //             }
    //         }
    //         setIsLoading(false)
    //     } catch (error) {
    //         // fetchMessages()
    //         console.log('Error fetch message:', error)
    //     }
    // }

    // const loadMoreMessages = async () => {
    //     try {
    //         if (hasMorePage && !isLoading && !isLoadingMore) {
    //             setIsLoadingMore(true)
    //             const request = await api(`api/one/${user_id}?paginate&page=${page}`, 'GET', null, { ...accessToken, ...signal })
    //             const response = await request.json()
    //             // console.log('Response message:', response?.messages)
    //             setIsLoadingMore(false)
    //             if (request.ok && request.status === 200) {
    //                 if (response.success) {
    //                     setIsBloqued(response?.user_blocked)
    //                     if (response?.user_blocked) {
    //                         deleteAllMessages()
    //                     } else {
    //                         if (response?.messages?.next_page_url) {
    //                             setHasMorePage(true)
    //                         } else {
    //                             setHasMorePage(false)
    //                         }
    //                         setPage(page + 1)

    //                         if (response?.messages?.data?.length !== 0) {
    //                             setMessages([...messages, ...response?.messages?.data])

    //                             setMute(response?.auth_user_muted_conversation)
    //                             setFriendMute(response?.friend_user_muted_conversation)

    //                             setuserChat({
    //                                 user_surname: response?.user?.user_surname,
    //                                 user_name: response?.user?.user_name,
    //                                 user_username: response?.user?.user_username,
    //                                 user_verified: response?.user?.user_verified,
    //                                 user_gold: response?.user?.user_gold,
    //                                 profile: {
    //                                     prof_picture: response?.user?.profile?.prof_picture
    //                                 },
    //                                 connection_status: response?.user?.connection_status,
    //                                 user_badge_business: response?.user?.user_badge_business,
    //                                 user_badge_color: response?.user?.user_badge_color,
    //                                 user_badge_food: response?.user?.user_badge_food,
    //                                 user_badge_goal: response?.user?.user_badge_goal,
    //                                 user_badge_hobby: response?.user?.user_badge_hobby,
    //                                 user_match_value: response?.user?.user_match_value,
    //                             })
    //                         }

    //                     }
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         // fetchMessages()
    //         setIsLoadingMore(false)
    //         console.log('Error fetch message:', error)
    //     }
    // }


    // const updateLastSeen = async () => {
    //     try {
    //         // console.log('Updating last seen:', authReducerState?.user?.user_show_last_seen)
    //         const body = {
    //             user_show_last_seen: authReducerState?.user?.user_show_last_seen ? false : true
    //         }
    //         const request = await api(`api/user/update-show-last-seen`, 'POST', body, accessToken)
    //         const response = await request.json()
    //         // console.log('Response updating last seen:', response)
    //         if (request.ok && request.status === 200) {
    //             if (response?.success) {
    //                 setShowMenu(false)
    //                 updateUserDetails()
    //             }
    //         }
    //     } catch (error) {
    //         setShowMenu(false)
    //         console.error('Error update last seen:', error);
    //     }
    // }

    // const updateUserDetails = async () => {
    //     try {
    //         const request = await api('api/get-user', 'GET', {}, accessToken)
    //         const response = await request.json()
    //         // console.log('Response fetch user:', response?.user.user_show_last_seen)
    //         if (request.ok && request.status === 200) {
    //             UPDATE_PROFILE(response?.user)
    //         }
    //     } catch (e) {
    //         updateUserDetails()
    //         console.warn('Error fetch user info:', e.message)
    //         throw new Error(e)
    //     }
    // }


    // const deleteAllMessages = async () => {
    //     try {
    //         const body = {
    //             user_id: user_id,
    //         }
    //         const request = await api(
    //             'api/one/delete-inbox',
    //             'POST',
    //             body,
    //             accessToken,
    //         )
    //         const response = await request.json()
    //         // console.log(response, 'response of delete message ')
    //         if (request.ok && request.status === 200) {
    //             if (response?.success) {
    //                 setShowMenu(false)
    //                 setMessages([])
    //                 if (isBloqued) {
    //                     navigate(-1)
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         setShowMenu(false)
    //         throw new Error(error.message)
    //     }
    // }

    // const blockBuddy = async () => {
    //     try {
    //         const body = { user_id }
    //         const request = await api(
    //             'api/profile/blocked',
    //             'POST',
    //             body,
    //             accessToken,
    //         )
    //         const response = await request.json()
    //         // console.log(response, 'response of BLOC ON ONE TO ONE PROFIL')
    //         if (request.ok && request.status === 200) {
    //             if (response.success) {
    //                 setIsBloqued(true)
    //                 deleteAllMessages()
    //             }
    //         }
    //     } catch ({ message }) {
    //         throw new Error(message)
    //     }
    // }



    // const handleSendMessage = async () => {
    //     try {
    //         if (!message && attachments?.length === 0) {
    //             return
    //         }
    //         // chat_messages_datetime: "2023-08-14T11:54:32.000000Z"
    //         // chat_messages_id: 18
    //         // chat_messages_status: "yes"
    //         // chat_messages_text: "Coucou"
    //         // message_parent: null
    //         // message_parent_id: null
    //         // message_reponse: null
    //         // receiver_id: 2
    //         // sender_id: 4
    //         const timestamp = new Date().getTime()

    //         const new_message = {
    //             chat_messages_id: new Date().getTime(),
    //             sender_id: authReducerState?.user?.user_id,
    //             receiver_id: parseInt(user_id),
    //             chat_messages_text: message,
    //             chat_messages_status: "pending",
    //             message_parent_id: new Date().getTime(),
    //             message_parent: null,
    //             attachments: attachments?.map(attach => ({
    //                 attach_created_at: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
    //                 attach_duration: attach?.duration,
    //                 attach_extension: attach?.extension,
    //                 attach_height: attach?.height,
    //                 attach_id: timestamp,
    //                 attach_index: null,
    //                 attach_mime_type: attach?.mime_type,
    //                 attach_model_id: timestamp,
    //                 attach_model_type: "App\\Models\\ChatMessage",
    //                 attach_size: attach?.size,
    //                 attach_thumbnail: attach?.thumbnail,
    //                 attach_type: attach?.type,
    //                 attach_updated_at: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
    //                 attach_url: attach?.uri,
    //                 attach_width: attach?.width,
    //             })),
    //             chat_messages_datetime: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
    //         }
    //         setMessages([new_message, ...messages])

    //         // setIsLoadingRequest(true)
    //         const formData = new FormData();
    //         formData.append('receiver_id', user_id);
    //         formData.append('chat_messages_text', message);
    //         // formData.append('message_parent_id', messageParent?.id);

    //         if (attachments[0]?.type == 'document') {
    //             formData.append('attachments', 1);
    //             formData.append(`attachment_1`, attachments[0]?.file);
    //             formData.append(`attach_filename_1`, attachments[0]?.file?.name);
    //             formData.append(`attachment_mime_type_1`, attachments[0]?.mime_type);
    //             formData.append(`attachment_type_1`, attachments[0]?.type);
    //             formData.append(`attachment_size_1`, attachments[0]?.size);
    //             formData.append(`attachment_extension_1`, attachments[0]?.name);
    //         } else if (attachments[0]?.type == 'audio') {
    //             formData.append('attachments', 1);
    //             formData.append(`attachment_1`, attachments[0]?.file);
    //             formData.append(`attachment_type_1`, attachments[0]?.type);
    //             formData.append(`attachment_mime_type_1`, attachments[0]?.mime_type);
    //             formData.append(`attachment_extension_1`, attachments[0]?.extension);
    //         } else {
    //             formData.append('attachments', attachments?.length);
    //             attachments.forEach((attachment, index) => {

    //                 formData.append(`attachment_${index + 1}`, attachment?.file);
    //                 formData.append(`attach_filename_${index + 1}`, attachment?.file?.name);
    //                 formData.append(`attachment_type_${index + 1}`, attachment?.type);
    //                 formData.append(`attachment_mime_type_${index + 1}`, attachment?.mime_type);

    //                 if (attachment?.type !== 'audio') {
    //                     formData.append(`attachment_width_${index + 1}`, attachment?.width);
    //                     formData.append(`attachment_height_${index + 1}`, attachment?.height);
    //                     formData.append(`attachment_size_${index + 1}`, attachment?.size);
    //                 }
    //                 if (attachment?.type == 'video') {
    //                     formData.append(`attachment_thumbnail_${index + 1}`, attachment?.thumbnail);
    //                     formData.append(`attachment_duration_${index + 1}`, attachment?.duration);
    //                 }
    //             });
    //         }

    //         setMessage('')
    //         setAttachments([])
    //         setAttachmentsPreview([])

    //         const request = await axios.post(`${process.env.REACT_APP_API_URL}/api/sendMessage`, formData, {
    //             onUploadProgress: progressEvent => {
    //                 let { loaded, total } = progressEvent;
    //                 console.log(Math.round((loaded / total) * 100), '% envoi...')
    //             },
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //                 Accept: 'application/json',
    //                 ...accessToken,
    //             },
    //         });
    //         const response = await request?.data
    //         // console.log('Response send message:', response)
    //         if (request?.status === 200) {
    //             if (response?.success) {
    //                 setMessage('')
    //                 setAttachments([])
    //                 setAttachmentsPreview([])
    //                 fetchConversations()
    //                 fetchMessages()
    //             }
    //         }
    //     } catch (error) {
    //         // if (error.message === 'Network Error') {
    //         //     handleSendMessage()
    //         // } else {
    //         //     handleSendMessage()
    //         // }
    //         console.error(error.message);
    //     }
    // }


    // const fetchConversations = async () => {
    //     try {
    //         const request = await api(`api/my-messages?paginate&page=${1}`, 'GET', {}, accessToken)
    //         const response = await request.json()
    //         // console.log('Fetch conversations response:', response?.list?.data?.length)
    //         if (request.ok && request.status === 200) {
    //             if (response.success) {
    //                 if (response?.list?.data?.length !== 0) {
    //                     REFRESH_CONVERSATION(response?.list?.data)

    //                     const numberOfMessage = response?.list?.data
    //                         ?.map(message => message?.sent_messages?.length)
    //                         ?.reduce(
    //                             (previousValue, currentValue) => previousValue + currentValue, 0,
    //                         )
    //                     REFRESH_MESSAGE_NUMBER(numberOfMessage)
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         fetchConversations()
    //         console.error('Fetch conversations error:', error.message)
    //     }
    // }



    // const handleFileChange = async (e) => {
    //     if (e.target.files) {

    //         const file = e.target.files[0]
    //         const fileUri = window.URL.createObjectURL(file)
    //         // console.log('Flie selected:', file)
    //         // console.log(file?.name?.split('.').pop())
    //         if (file.type.split("/")[0] === 'application') {

    //             const data = {
    //                 file: file,
    //                 uri: fileUri,
    //                 size: file?.size,
    //                 type: 'document',
    //                 extension: file?.name?.split('.').pop(),
    //                 mime_type: file?.type
    //             }
    //             setAttachments(attachments => [...attachments, data])
    //             setAttachmentsPreview(value => [...value, { uri: fileUri, type: 'document', extension: file?.name?.split('.').pop() }])

    //         }
    //         if (file.type.split("/")[0] === 'image') {

    //             setAttachmentsPreview(value => [...value, { uri: fileUri, type: 'image' }])

    //             new Compressor(file, {
    //                 quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
    //                 success: async (compressedResult) => {
    //                     console.log('compressedResult:', compressedResult)
    //                     const { width, height } = await getImageParams(file)

    //                     const data = {
    //                         file: compressedResult,
    //                         uri: fileUri,
    //                         width,
    //                         height,
    //                         size: file?.size,
    //                         type: 'image',
    //                         extension: file?.type.split("/")[1],
    //                         mime_type: file?.type
    //                     }

    //                     setAttachments(attachments => [...attachments, data])

    //                 },
    //             });
    //         }

    //         if (file.type.split("/")[0] === 'video') {

    //             const megabytes = (file.size / 1048576).toFixed(0);
    //             if (megabytes > 50) {
    //                 setShowAlertVideo(true)
    //             } else {
    //                 // generate video thumbnail
    //                 const thumbnail = await generateVideoThumbnail(e.target.files[0]);
    //                 if (thumbnail) {

    //                     // set video thumbnail
    //                     const { thumbnailUri, thumbnailFile } = await convertBase64ToFile(thumbnail)

    //                     setAttachmentsPreview(value => [...value, { uri: thumbnailUri, type: 'video' }])

    //                     const meta = await getVideoMetadata(file)
    //                     // console.log('Video meta:', meta)

    //                     // load file
    //                     const data = {
    //                         file,
    //                         uri: thumbnailUri,
    //                         width: meta?.width,
    //                         height: meta?.height,
    //                         duration: meta?.duration,
    //                         size: file.size,
    //                         type: 'video',
    //                         thumbnail: thumbnailFile,
    //                         extension: file.type.split("/")[1],
    //                         mime_type: file.type
    //                     }

    //                     setAttachments(attachments => [...attachments, data])
    //                 }
    //             }
    //         }

    //     }
    // };




    // const generateVideoThumbnail = (file) => {
    //     return new Promise((resolve) => {
    //         const canvas = document.createElement("canvas");
    //         const video = document.createElement("video");

    //         // this is important
    //         video.currentTime = 5;
    //         video.autoplay = true;
    //         video.muted = true;
    //         video.src = URL.createObjectURL(file);

    //         video.onloadeddata = () => {
    //             let ctx = canvas.getContext("2d");

    //             canvas.width = video.videoWidth;
    //             canvas.height = video.videoHeight;

    //             ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    //             video.pause();
    //             return resolve(canvas.toDataURL("image/png"));
    //         };
    //     });
    // };



    // function getVideoMetadata(file) {
    //     return new Promise((resolve, reject) => {
    //         const video = document.createElement('video');
    //         video.preload = 'metadata';
    //         video.src = URL.createObjectURL(file);

    //         video.onloadedmetadata = () => {
    //             URL.revokeObjectURL(video.src);
    //             resolve({
    //                 duration: video.duration,
    //                 width: video.videoWidth,
    //                 height: video.videoHeight
    //             });
    //         };

    //         video.onerror = reject;
    //     });
    // }


    // const convertBase64ToFile = async (base64Image) => {
    //     const blob = await fetch(base64Image).then((res) => res.blob());
    //     const thumbnailFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });
    //     const thumbnailUri = URL.createObjectURL(thumbnailFile);
    //     // setImageUrl(imageUrl);
    //     // console.log('convertBase64ToFile:', file)
    //     return { thumbnailUri, thumbnailFile }
    // };



    // // reading a file to get height and width
    // async function getImageParams(file) {
    //     return new Promise((resolve, reject) => {
    //         var reader = new FileReader()

    //         reader.onload = async (e) => {
    //             var image = new Image()
    //             image.src = e.target.result
    //             await image.decode()

    //             resolve({ width: image.width, height: image.height })
    //         }
    //         reader.readAsDataURL(file)
    //     })
    // }



    // const handleDeleteAttachment = (uri) => {
    //     setAttachmentsPreview(attachmentsPreview.filter((attach) => attach?.uri !== uri))
    //     setAttachments(attachments.filter((attach) => attach?.uri !== uri))
    // }



    // // HANDLE SCROLL TOP TO PAGINATE
    // const handleScroll = (event) => {
    //     const { scrollHeight, scrollTop, clientHeight } = refMessageBox?.current
    //     if (scrollTop <= clientHeight - scrollHeight + 1) {
    //         loadMoreMessages();
    //     }
    // };


    // const header = () => {
    //     if (isLoading) {
    //         return (
    //             <div style={{
    //                 height: '100vh',
    //                 overflow: 'hidden',
    //                 background: 'white'
    //             }}>
    //                 <Header title='Message' />
    //                 <div style={{ textAlign: 'center', marginTop: '40vh' }}>
    //                     <Spinner
    //                         size="sm"
    //                         role="status"
    //                         aria-hidden="true"
    //                         animation="border"
    //                         variant='secondary'
    //                         style={{
    //                             width: '30px',
    //                             height: '30px'
    //                         }}
    //                     />
    //                     <h6 style={{ color: '#808080', marginTop: 10 }}>
    //                         {t('loading')}
    //                     </h6>
    //                 </div>
    //             </div>
    //             //     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh' }}>

    //             // </div>
    //         )
    //     } else {
    //         return (
    //             <div className={styles.header}>
    //                 <button onClick={() => navigate(-1)} className={styles.backButtonHeader} >
    //                     <MdOutlineKeyboardBackspace size={32} />
    //                 </button>
    //                 <div>
    //                     <ProfileCard user={userChat} />
    //                 </div>
    //                 <button onClick={() => setShowMenu(true)} className={styles.menuButtonHeader}>
    //                     <TfiMenu size={28} color={color.dark} />
    //                 </button>
    //             </div>
    //         )
    //     }
    // }


    // const renderLoader = () => {
    //     return (
    //         <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '1%' }}>
    //             <div style={{ textAlign: 'center' }}>
    //                 <Spinner
    //                     size="sm"
    //                     role="status"
    //                     aria-hidden="true"
    //                     animation="border"
    //                     variant='secondary'
    //                     style={{
    //                         width: '25px',
    //                         height: '25px'
    //                     }}
    //                 />
    //                 <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
    //                     {t('loading')}
    //                 </h6>
    //             </div>
    //         </div>
    //     )
    // }


    // const renderAttachPreview = () => {
    //     if (attachmentsPreview.length !== 0) {
    //         return (
    //             <div style={{ width: '100%', display: 'flex', alignItems: 'center', paddingTop: '10px', paddingBottom: '5px', backgroundColor: '#f1f1f1' }}>
    //                 {attachmentsPreview?.map((attach, index) => {
    //                     if (attach?.type === 'document') {
    //                         return (
    //                             <div key={index.toString()} style={{ width: '50px', marginLeft: '15px' }}>
    //                                 <img
    //                                     src={
    //                                         image_extensions?.includes(attach?.extension) ?
    //                                             require('assets/docs/image_icon.png') :
    //                                             audio_extensions?.includes(attach?.extension) ?
    //                                                 require('assets/docs/audio.png') :
    //                                                 video_extensions?.includes(attach?.extension) ?
    //                                                     require('assets/docs/video_icon.png') :
    //                                                     word_extensions?.includes(attach?.extension) ?
    //                                                         require('assets/docs/word_icon.png') :
    //                                                         attach?.extension === 'pdf' ?
    //                                                             require('assets/docs/pdf_icon.png') :
    //                                                             excel_extensions?.includes(attach?.extension) ?
    //                                                                 require('assets/docs/excell_icon.png') :
    //                                                                 powerpoint_extensions?.includes(attach?.extension) ?
    //                                                                     require('assets/docs/pptx_icon.png') :
    //                                                                     access_extensions?.includes(attach?.extension) === 'accdb' ?
    //                                                                         require('assets/docs/access_icon.png') :
    //                                                                         attach?.extension === 'txt' ?
    //                                                                             require('assets/docs/txt.png') : require('assets/docs/file_icon.png')

    //                                     }
    //                                     width={50}
    //                                     height={50}
    //                                     alt=''
    //                                     style={{
    //                                         marginRight: '15px',
    //                                         // marginBottom: '10px',
    //                                         border: `1px ${color.primary} solid`,
    //                                         borderRadius: '3px'
    //                                     }}
    //                                 />
    //                                 <button
    //                                     style={{
    //                                         marginLeft: 'auto',
    //                                         marginTop: '-20px',
    //                                         backgroundColor: 'transparent'
    //                                     }}
    //                                     onClick={() => handleDeleteAttachment(attach?.uri)}>
    //                                     <IoIosCloseCircle size={20} color={color.danger} />
    //                                 </button>
    //                             </div>
    //                         )
    //                     } else {
    //                         return (
    //                             <div key={index.toString()} style={{ width: '50px', marginLeft: '15px' }}>
    //                                 <img
    //                                     src={attach?.uri}
    //                                     width={50}
    //                                     height={50}
    //                                     alt=''
    //                                     style={{
    //                                         marginRight: '15px',
    //                                         // marginBottom: '10px',
    //                                         border: `1px ${color.primary} solid`,
    //                                         borderRadius: '3px'
    //                                     }}
    //                                 />
    //                                 <button
    //                                     style={{
    //                                         marginLeft: 'auto',
    //                                         marginTop: '-20px',
    //                                         backgroundColor: 'transparent'
    //                                     }}
    //                                     onClick={() => handleDeleteAttachment(attach?.uri)}>
    //                                     <IoIosCloseCircle size={20} color={color.danger} />
    //                                 </button>
    //                             </div>
    //                         )
    //                     }
    //                 })}
    //             </div>
    //         )
    //     }
    // }


    // return (
    //     <div className={styles.container}>
    //         {header()}
    //         <div ref={refMessageBox} onScroll={handleScroll} id='scrollableDiv' className={styles.messageContainer}>
    //             {renderAttachPreview()}
    //             <InfiniteScroll
    //                 dataLength={messages?.length}
    //                 next={loadMoreMessages}
    //                 hasMore={hasMorePage}
    //                 loader={renderLoader}
    //                 scrollableTarget="scrollableDiv"
    //                 style={{
    //                     display: 'flex',
    //                     flexDirection: 'column-reverse',
    //                     height: '86vh',
    //                     paddingTop: '150px',
    //                     overflowX: 'hidden'
    //                 }}
    //                 inverse={true}>
    //                 {messages.map((message, index) => {
    //                     return (
    //                         <div key={index.toString()}>
    //                             <MessageCard
    //                                 user_id={user_id}
    //                                 received={userChat}
    //                                 message={message}
    //                                 messages={messages}
    //                                 setMessages={setMessages}
    //                             />
    //                         </div>
    //                     )
    //                 })}
    //                 {isLoadingMore ?
    //                     <div style={{ width: '100%', height: '30px', textAlign: 'center', padding: '5px' }}>
    //                         <Spinner variant='secondary' />
    //                     </div>
    //                     : null
    //                 }
    //             </InfiniteScroll>
    //         </div>

    //         <div className={styles.inputMessage}>
    //             <div style={{ width: '100%' }}>

    //                 {isVoiceMessage ?
    //                     <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    //                         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    //                             <audio src={audioURL} controls style={{ height: '35px' }} />
    //                             <button onClick={startRecording} disabled={recording}>Enregistrer</button>
    //                             <button onClick={stopRecording} disabled={!recording}>Stop</button>
    //                             <button onClick={handleSendMessage} disabled={!audioURL}>Envoyer</button>
    //                         </div>
    //                         <button onClick={() => setIsVoiceMessage(false)}>
    //                             <AiFillCloseCircle size={30} color={color.primary} />
    //                         </button>
    //                     </div>
    //                     :
    //                     <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

    //                         <label
    //                             htmlFor="choice1"
    //                             onChange={(e) => handleFileChange(e)}
    //                             style={{
    //                                 borderRadius: '3px',
    //                                 padding: '7px',
    //                                 cursor: 'pointer',
    //                             }}>
    //                             <input accept="image/*, video/*, application/*" name="" type="file" id="choice1" hidden />
    //                             <RiAttachment2 size={30} color={color.primary} style={{ backgroudColor: color.primary, border: '2px ' + color.primary + ' solid', borderRadius: '30px', padding: '3px' }} />
    //                         </label>

    //                         <InputEmoji
    //                             value={message}
    //                             onChange={setMessage}
    //                             // cleanOnEnter
    //                             keepOpened
    //                             onEnter={text => handleSendMessage(text)}
    //                             theme={'light'}
    //                             placeholder={t('write_your_message')}
    //                         />

    //                         {isLoadingRequest ?
    //                             <div style={{ width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //                                 <Spinner
    //                                     size="sm"
    //                                     role="status"
    //                                     aria-hidden="true"
    //                                     animation="border"
    //                                     variant={'primary'}
    //                                 />
    //                             </div>
    //                             :
    //                             <button
    //                                 onClick={handleSendMessage}
    //                                 type='submit'
    //                                 style={{
    //                                     width: '30px',
    //                                     height: '30px',
    //                                     display: 'flex',
    //                                     justifyContent: 'center',
    //                                     alignItems: 'center',
    //                                     backgroundColor: color?.primary,
    //                                     borderRadius: '30px'
    //                                 }}>
    //                                 <RiSendPlaneFill size={25} color='white' style={{ marginTop: '2px' }} />
    //                             </button>
    //                         }
    //                     </div>}

    //             </div>

    //         </div>


    //         <Modal show={showMenu}>
    //             <Header title='Menu' type='modal' goBack={() => setShowMenu(false)} />

    //             <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>

    //                 <div
    //                     onClick={updateLastSeen}
    //                     style={{
    //                         cursor: 'pointer',
    //                         display: 'flex',
    //                         alignItems: 'center',
    //                         marginTop: '10px',
    //                         marginBottom: '15px'
    //                     }}>
    //                     {authReducerState?.user?.user_show_last_seen ? <AiOutlineEyeInvisible size={26} color='#808080' /> : <AiOutlineEye size={26} color='#808080' />}
    //                     <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
    //                         {authReducerState?.user?.user_show_last_seen ? t('hide_your_last_seen') : t('show_your_last_seen')}
    //                     </span>
    //                 </div>

    //                 <div
    //                     onClick={deleteAllMessages}
    //                     style={{
    //                         cursor: 'pointer',
    //                         display: 'flex',
    //                         alignItems: 'center',
    //                         marginTop: '10px',
    //                         marginBottom: '15px'
    //                     }}>
    //                     <MdDeleteOutline size={26} color='#808080' />
    //                     <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
    //                         {t('delete_all_messages')}
    //                     </span>
    //                 </div>
    //                 <div
    //                     onClick={blockBuddy}
    //                     style={{
    //                         cursor: 'pointer',
    //                         display: 'flex',
    //                         alignItems: 'center',
    //                         marginTop: '10px',
    //                         marginBottom: '15px'
    //                     }}>
    //                     <RiUserUnfollowLine size={26} color='#808080' />
    //                     <span style={{ color: '#222', fontWeight: '600', fontSize: '14px', marginLeft: '10px' }}>
    //                         {t('block')} {userChat?.user_surname}  {userChat?.user_name}
    //                     </span>
    //                 </div>

    //             </div>

    //         </Modal>


    //         <ModelAlert
    //             message={t('video_too_large_please_select_a_video_less_than_50mb')}
    //             show={showAlertVideo}
    //             setShow={setShowAlertVideo}
    //         />

    //     </div>
    // )
}

export default OneToOneMessage