
import React from 'react'
import './Header.style.css'
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { IoCloseSharp } from 'react-icons/io5';


const Header = ({
  title = 'Screen',
  type = 'screen',
  goBack
}) => {

  return (
    <div className='header-componant'>
      {type == 'screen' ?
        <button onClick={goBack} className='button-back' >
          <MdOutlineKeyboardBackspace size={32} />
        </button>
        :
        <button onClick={goBack} className='button-back' >
          <IoCloseSharp size={32} />
        </button>
      }
      <h5 className='title text-center'>
        {title}
      </h5>
      <div style={{
          width: '40px',
          height: '40px',
          marginRight: '10px'
      }}>
        <IoCloseSharp size={32} color='white' />
      </div>
    </div>
  )
}

export default Header