import React, { useEffect, useState } from 'react'
import './AgoraDetails.style.css'
import Header from '../../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../../hooks/ReducerHooks/ReducerHooks'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../api/api'
import LoadingPage from '../../../components/LoadingPage/LoadingPage'
import { color } from '../../../hooks/Utils/color'
import InfiniteScroll from 'react-infinite-scroll-component';
import { MdGroups, MdOutlinePublic, MdArrowForwardIos } from 'react-icons/md'
import { BiLockAlt } from 'react-icons/bi'
import { MdPublic, MdOutlineKeyboardBackspace } from 'react-icons/md'
import { TfiMenu } from 'react-icons/tfi'
import { FaUsers } from 'react-icons/fa'
import { formatCash } from '../../../hooks/Utils/parsing'
import Opinion from '../../../components/Opinion/Opinion'
import { Spinner } from 'react-bootstrap'
import { TbPlugConnected } from 'react-icons/tb'
import { AiOutlineDisconnect } from 'react-icons/ai'
import ButtonSubmit from '../../../components/ButtonSubmit/ButtonSubmit'
import ModalCreateOpinion from '../../../components/ModalCreateOpinion/ModalCreateOpinion'
import { IoCloseSharp } from 'react-icons/io5';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Switch, TextField } from '@mui/material'
import CoverProfilePicture from 'components/CoverProfilePicture/CoverProfilePicture'
import Cover from 'components/Cover/Cover'
import CoverAgora from 'components/CoverAgora/CoverAgora'
import HelmetSeo from 'components/HelmetSeo/HelmetSeo'



const AgoraDetails = () => {

    const { ago_id } = useParams()
    const { state } = useLocation();


    const { data: authData } = useAuthReducer()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const accessToken = useAccessToken()
    const [agoraDetails, setAgoraDetails] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    const [agm_status, setAgm_status] = useState(null)
    const [agm_role, setAgm_role] = useState(null)
    const [agm_type, setAgm_type] = useState(null)
    const [agm_moderator, setAgm_moderator] = useState(null)
    const [ago_picture, setAgo_picture] = useState(null)

    const MAX_LENGTH = 150
    const [showDescription, setShowDescription] = useState(false)

    const [opinions, setOpinions] = useState([])
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)

    const [showModalCreateOpinion, setShowModalCreateOpinion] = useState(false)


    const [agoraTitle, setAgoraTitle] = useState(agoraDetails?.agora?.ago_title)
    const [agoraPrivate, setAgoraPrivate] = useState(agoraDetails?.agora?.ago_private)
    const [agoraDescription, setAgoraDescription] = useState(agoraDetails?.agora?.ago_description)
    const [showEditAgora, setShowEditAgora] = useState(false)
    const [isLoadingEdit, setIsLoadingEdit] = useState(false)


    const handleOpen = () => {
        setShowEditAgora(true)
    }

    const handleClose = () => {
        setShowEditAgora(false)
    }


    useEffect(() => {
        setPage(1)
        fetchAgoraDetails()
        fetchAgoraOpinions()
    }, [])


    const fetchAgoraDetails = async () => {
        try {
            const request = await api(`api/agoras/${ago_id}`, 'GET', null, accessToken)
            const response = await request.json()
            // console.log('Response fetch agora details:', response.ago_private)
            // console.log('Response fetch agora details:', response?.agora?.ago_private)
            if (request?.status === 200) {
                if (response?.success) {
                    setAgoraDetails(response)
                    setAgm_status(response?.membership_status?.agm_status)
                    setAgm_role(response?.membership_status?.agm_role)
                    setAgm_type(response?.membership_status?.agm_type)
                    setAgm_moderator(response?.agora?.ago_creator)
                    setAgo_picture(response?.agora?.ago_picture ? response?.agora.ago_picture : null)

                    setAgoraTitle(response?.agora?.ago_title)
                    setAgoraDescription(response?.agora?.ago_presentation)
                    setAgoraPrivate(response?.agora?.ago_private)
                }
            }
            setIsLoading(false)
        } catch (error) {
            fetchAgoraDetails()
            console.log('Error request get agora details:', error.message)
        }
    }


    const fetchAgoraOpinions = async () => {
        try {
            if (!isLoadingMore) {
                setIsLoadingMore(true)
                const request = await api(`api/agoras/v2/opinions/${ago_id}?page=${1}`, 'GET', {}, accessToken);
                const response = await request.json()
                // console.log('Get opinion page ' + page + ' :', response)
                setIsLoadingMore(false)
                if (request?.status === 200) {
                    if (response?.success) {
                        if (response?.opinions?.data.length === 0) {
                            setHasMore(false)
                            return
                        }
                        if (response?.opinions?.next_page_url) {
                            setHasMore(true)
                        } else {
                            setHasMore(false)
                        }
                        setOpinions(response?.opinions?.data)
                        setPage(2)
                    }
                }
            }
        } catch (error) {
            fetchAgoraOpinions()
            console.log('Error request get agora details:', error.message)
        }

    }

    const loadMoreAgoraOpinions = async () => {
        try {
            if (hasMore && !isLoadingMore) {
                setIsLoadingMore(true)
                const request = await api(`api/agoras/v2/opinions/${ago_id}?page=${page}`, 'GET', {}, accessToken);
                const response = await request.json()
                // console.log('Get opinion page ' + page + ' :', response)
                setIsLoadingMore(false)
                if (request?.status === 200) {
                    if (response?.success) {
                        if (response?.opinions?.data.length === 0) {
                            setHasMore(false)
                            return
                        }
                        if (response?.opinions?.next_page_url) {
                            setHasMore(true)
                        } else {
                            setHasMore(false)
                        }
                        setOpinions(opinions => [...opinions, ...response?.opinions?.data])
                        setPage(p => p + 1)
                    }
                }
            }
        } catch (error) {
            fetchAgoraOpinions()
            console.log('Error request get agora details:', error.message)
        }

    }






    const updateOpinion = (data) => {
        const new_opinions = opinions.map(opinion =>
            opinion.sond_id !== data.sond_id ? opinion : data,
        )
        setOpinions(new_opinions)
    }

    const deleteOpinion = (data) => {
        setOpinions(opinions.filter(opinion => opinion.sond_id !== data?.sond_id))
    }



    const joinAnAgora = async () => {
        try {
            console.log('Join...')
            const body = { agm_agora: ago_id }
            const request = await api('api/agoras/join', 'POST', body, accessToken)
            const response = await request.json()
            console.log('Response join an agora:', response)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    fetchAgoraDetails()
                    // sendNotification(response?.moderator, {
                    //     title: `${AuthContextState?.user?.user_surname} ${AuthContextState?.user?.user_name} a demandé à rejoindre votre agora.`,
                    //     body: `Rendez-vous dans la liste des membres de votre agora pour l'accepter.`,
                    // })
                }
            }
        } catch ({ message }) {
            throw new Error(message)
        }
    }


    // DELETE DEMANDE L'AGORA
    const deleteJoin = async () => {
        try {
            const body = { agm_agora: ago_id }
            const request = await api(
                'api/agoras/members/delete-join',
                'POST',
                body,
                accessToken,
            )
            const response = await request.json()
            console.log('Response delete join', response.message)
            if (request.ok && request.status === 200) {
                fetchAgoraDetails()
            }
        } catch (error) {
            console.log('Delete join an agora errorrr', error.message)
        }
    }


    const EditAgora = async () => {
        try {
            handleClose()
            setIsLoadingEdit(true)
            const body = {
                ago_title: agoraTitle,
                ago_presentation: agoraDescription,
                ago_private: agoraPrivate
            }
            const request = await api('api/agoras/' + ago_id, 'PUT', body, accessToken)
            const response = await request.json()
            // console.log('Update Agora details response:', response)
            setIsLoadingEdit(false)
            if (request.ok && request.status === 200) {
                if (response.success) {
                    fetchAgoraDetails()
                }
            }
        } catch ({ message }) {
            setIsLoadingEdit(false)
            throw new Error(message)
        }
    }


    if (isLoading) {
        return (
            <div style={{ width: '100%', backgroundColor: 'white' }}>
                <Header title='Agora' goBack={() => navigate(-1)} />
                <div style={{ height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <LoadingPage />
                </div>
            </div>
        )
    }



    const renderLoader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '3%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Spinner
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        variant='secondary'
                        style={{
                            width: '25px',
                            height: '25px'
                        }}
                    />
                    <h6 style={{ color: '#808080', marginTop: '3%', fontSize: '13px' }}>
                        {t('loading_opinion')}
                    </h6>
                </div>
            </div>
        )
    }



    const renderButtonParticipate = () => {

        if (agm_status === 0 && agm_type === 0) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', }}>
                    <button
                        onClick={deleteJoin}
                        className='button'
                        style={{
                            width: '60%',
                            backgroundColor: 'white',
                            color: color.primary,
                            fontWeight: '600',
                            border: `1.8px ${color.primary} solid`
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <AiOutlineDisconnect size={18} color={color.primary} />
                        </div>
                        {t('cancel')}
                    </button>
                </div>
            )
        }

        if (agm_status === 0 && agm_type === 1) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', }}>
                    <button
                        onClick={joinAnAgora}
                        className='button'
                        style={{
                            backgroundColor: color.primary,
                            color: color.white,
                            width: '60%',
                            marginLeft: '10px'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <TbPlugConnected size={18} color={color.white} />
                        </div>
                        {t('participate')}
                    </button>
                </div>
            )
        }


        if (!agm_status) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px', }}>
                    <button
                        onClick={joinAnAgora}
                        className='button'
                        style={{
                            backgroundColor: color.primary,
                            color: color.white,
                            width: '60%',
                            marginLeft: '10px'
                        }}>
                        <div style={{ width: '20px', marginRight: '5px', marginBottom: '3px' }}>
                            <TbPlugConnected size={18} color={color.white} />
                        </div>
                        {t('participate')}
                    </button>
                </div>
            )
        }
    }


    const renderHeader = () => {
        return (
            <div className='header-profile'>
                <button onClick={() => navigate(-1)} className='button-back' >
                    <MdOutlineKeyboardBackspace size={32} />
                </button>
                <h5 className='title'>
                    {agoraDetails?.agora?.ago_title || 'Agora'}
                </h5>
                {agoraDetails?.membership_status?.agm_role === 2 &&
                    <button onClick={handleOpen}>
                        <TfiMenu size={28} />
                    </button>
                }
            </div>
        )
    }


    return (
        <div className='agora-details'>
            <HelmetSeo
                title={agoraDetails?.agora?.ago_title}
                description={agoraDetails?.agora?.ago_description}
                imageUrl={agoraDetails?.agora?.ago_description}
            />
            {renderHeader()}
            <div id='scrollableDiv' className='content'>
                <InfiniteScroll
                    dataLength={opinions.length} //This is important field to render the next data
                    next={loadMoreAgoraOpinions}
                    hasMore={hasMore}
                    loader={renderLoader()}
                    scrollableTarget="scrollableDiv">

                    <CoverAgora
                        agora={agoraDetails}
                        agmStatus={agm_status}
                        onRefresh={fetchAgoraDetails}
                    />

                    <p style={{ color: color.dark, marginLeft: '10%', marginRight: '10%', textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <h4 style={{ fontWeight: 'bold', marginLeft: '20px', marginLeft: '20px' }}>
                            {agoraDetails?.agora?.ago_title}
                        </h4>
                        {agoraDetails?.ago_presentation?.length > MAX_LENGTH && !showDescription ?
                            `${agoraDetails?.agora?.ago_presentation.substring(0, MAX_LENGTH)}...` : agoraDetails?.agora?.ago_presentation
                        }
                        {agoraDetails?.agora?.ago_presentation?.length > MAX_LENGTH &&
                            <button onClick={() => setShowDescription(!showDescription)} style={{ color: color.primary, fontWeight: '600' }}>
                                {showDescription ? 'Read less' : 'Read more'}
                            </button>
                        }
                    </p>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '10%', marginRight: '10%' }}>

                        <div className='box-info'>
                            {agoraDetails?.agora?.ago_private ?
                                <FaUsers size={26} color={color.primary} />
                                :
                                <MdPublic size={26} color={color.primary} />
                            }
                            <span style={{ fontWeight: '600', fontSize: '18px', color: color.primary, marginLeft: '10px' }}>
                                {agoraDetails?.agora?.ago_private ? t('private_agora') : t('public_agora')}
                            </span>
                        </div>

                        {agm_status === 1 &&
                            <div onClick={() => navigate(`/agora/${ago_id}/members`, { state: { agm_user_role: agm_role } })} className='box-info' style={{ cursor: 'pointer' }}>
                                <MdGroups size={26} color={color.primary} />
                                <span style={{ fontWeight: '600', fontSize: '18px', color: color.primary, marginLeft: '10px', marginRight: '5px' }}>
                                    {agoraDetails?.agora?.users_count} {agoraDetails?.agora?.users_count > 1 ? t('members') : t('member')}
                                </span>
                                <MdArrowForwardIos size={18} color={color.primary} style={{ marginBottom: '3px' }} />
                            </div>}

                    </div>
                    {renderButtonParticipate()}
                    <div style={{ backgroundColor: '#f1f1f1', margin: '30px', padding: '15px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <MdGroups color={color.primary} size={28} style={{ marginRight: '5px' }} />
                            <h5 style={{ fontWeight: 'bold', color: color.primary }}>Audience</h5>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingLeft: '10%', paddingRight: '10%' }}>
                            <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                {formatCash(agoraDetails?.agora?.users_count)} Membres
                            </h5>
                            <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                {formatCash(agoraDetails?.agora?.current_opinions_count)} Opinions
                            </h5>
                        </div>


                    </div>

                    <div style={{ backgroundColor: '#f1f1f1', margin: '30px', padding: '15px' }}>

                        <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <MdOutlinePublic color={color.primary} size={28} style={{ marginRight: '5px' }} />
                            <h5 style={{ fontWeight: 'bold', color: color.primary }}>Interactions</h5>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', paddingLeft: '10%', paddingRight: '10%' }}>
                            <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                {formatCash(agoraDetails?.agora?.opinions_count)} Opinions publiées
                            </h5>
                            <h5 style={{ color: color.primary, fontSize: '20px' }}>
                                {formatCash(agoraDetails?.votes)} Votes
                            </h5>
                        </div>

                    </div>


                    {agm_status === 1 && opinions.map((opinion, index) => {
                        return (
                            <Opinion
                                key={index.toString()}
                                opinion={opinion}
                                updateOpinion={updateOpinion}
                                deleteOpinion={deleteOpinion}
                                sourceFrom='Agora'
                            />
                        )
                    })}

                </InfiniteScroll>

            </div>

            {/* Desktop button create opinion */}
            <div className='hidden md:flex justify-center items-center h-16'>
                <button
                    onClick={() => setShowModalCreateOpinion(true)}
                    className='px-5 py-2 rounded-full text-sm text-white'
                    style={{ backgroundColor: color.primary }}>
                    {`${t('publish_in_the_agora')} ${agoraDetails?.agora?.ago_title?.length > 20 ?
                        `${agoraDetails?.agora?.ago_title?.slice(0, 20)}...` : agoraDetails?.agora?.ago_title
                        }`
                    }
                </button>
            </div>

            {/* Mobile button create opinion */}
            <button
                onClick={() => setShowModalCreateOpinion(true)}
                className='block lg:hidden absolute bottom-24 md:bottom-36 lg:bottom-12 right-4 px-5 py-2 rounded-full text-sm text-white'
                style={{ backgroundColor: color.primary }}>
                {`${t('publish_in_the_agora')} ${agoraDetails?.agora?.ago_title?.length > 20 ?
                    `${agoraDetails?.agora?.ago_title?.slice(0, 20)}...` : agoraDetails?.agora?.ago_title
                    }`
                }
            </button>

            <ModalCreateOpinion
                user={authData?.user}
                ago_id={ago_id}
                ago_title={agoraDetails?.agora?.ago_title}
                ago_private={agoraPrivate}
                show={showModalCreateOpinion}
                setShow={setShowModalCreateOpinion}
            />



            {/* DIALOG CREATE GROUP */}
            <Dialog open={showEditAgora} onClose={handleClose}>
                <DialogTitle>Modifier le groupe</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ marginBottom: '20px' }}>
                        Entrer les informations de votre groupe.
                    </DialogContentText>

                    <span>Groupe privée</span>
                    <Switch
                        checked={agoraPrivate}
                        onChange={(event) => setAgoraPrivate(event.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />

                    <TextField
                        autoFocus
                        multiline={false}
                        margin="dense"
                        label={t('name_of_the_group')}
                        value={agoraTitle || ''}
                        onChange={(event) => setAgoraTitle(event.target.value)}
                        fullWidth
                        variant="outlined"
                        size='small'
                        style={{ marginBottom: '15px', marginTop: '10px' }}
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        label={t('description_of_the_group')}
                        value={agoraDescription || ''}
                        onChange={(event) => setAgoraDescription(event.target.value)}
                        fullWidth
                        multiline
                        variant="outlined"
                        style={{ marginBottom: '15px' }}
                    />

                    {isLoadingEdit &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spinner />
                        </div>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('cancel')}</Button>
                    <Button onClick={EditAgora}>{t('edit')}</Button>
                </DialogActions>
            </Dialog>




        </div >
    )
}

export default AgoraDetails