import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button,
} from "@mui/material";
import { color } from "hooks/Utils/color";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoIosCloseCircle } from "react-icons/io";
import { Link } from "react-router-dom";

const ModelUnauth = ({
    show = false,
    setShow = () => null
}) => {

    const { t } = useTranslation()

    return (
        <>
            <Dialog open={show} handler={setShow}>
                <div className="p-4">
                    <button onClick={() => setShow(false)} style={{ position: 'absolute', right: 15 }}>
                        <IoIosCloseCircle size={32} color={color.primary} />
                    </button>

                    <div style={{ textAlign: 'center', paddingTop: '8%', paddingBottom: '8%' }}>
                        <img src={require('../../assets/images/logo.png')} width={100} alt='' className='mx-auto' />
                        <h5 className='mb-3' style={{ textAlign: 'center' }}>
                            {t('login_now_to_interact_with_thousands_of_people')}
                        </h5>
                        <Link to={'/login'} style={{backgroundColor: color.primary}} className='px-3 py-2 mt-4 text-white rounded-full'>
                            {t('login')}
                        </Link>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default ModelUnauth