import React, { useEffect, useState } from 'react'
import './OpinionDetailsPublic.style.css'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams, useRoutes } from 'react-router-dom'
import Header from '../../components/Header/Header'
import { useAccessToken, useAuthReducer } from '../../hooks/ReducerHooks/ReducerHooks'
import { api } from '../../api/api'
import Opinion from '../../components/Opinion/Opinion'
import Comment from '../../components/Comment/Comment'
import LoadingPage from '../../components/LoadingPage/LoadingPage'
import EmptyPage from '../../components/EmptyPage/EmptyPage'
import HelmetSeo from '../../components/HelmetSeo/HelmetSeo'
import Endbar from 'components/Endbar/Endbar'
import Sidebar from 'components/Sidebar/Sidebar'
import AppStore from 'components/AppStore/AppStore'
import LoginForm from 'components/LoginForm/LoginForm'
import { ShimmerSocialPost } from 'react-shimmer-effects'


const OpinionDetailsPublic = () => {

    const {
        ago_id,
        sond_id
    } = useParams();

    const location = useLocation()

    const details = location.state?.details;

    const controller = new AbortController();
    const signal = controller.signal;


    const { t } = useTranslation()
    const { data: authData } = useAuthReducer()
    const accessToken = useAccessToken()
    const navigate = useNavigate()
    const [opinion, setOpinion] = useState(details)
    const [comments, setComments] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isEmpty, setIsEmpty] = useState(false)


    useEffect(() => {
        fetchOpinion()
        fetchComments()
        return () => controller.abort();
    }, [sond_id])


    //  FETCH OPINION
    const fetchOpinion = async () => {
        try {
            if (!accessToken) {
                setIsLoading(false)
                setIsEmpty(false)
            }
            const request = await api(`api/feed/${sond_id}`, 'GET', null, { ...accessToken, ...signal });
            const response = await request.json()
            console.log('Response opinion details:', response)
            if (request?.status === 200) {
                if (response?.success) {
                    setOpinion(response.data)
                    updateOpinion(response?.data)
                    setIsEmpty(false)

                } else {
                    setIsEmpty(true)
                }
            }
            setIsLoading(false)
        } catch (error) {
            setTimeout(() => {
                fetchOpinion()
            }, 2000);
            console.error('Error fetch opinion:', error);
        }
    }


    useEffect(() => {
        fetchComments()
        return () => controller.abort();
    }, [])

    const fetchComments = async () => {
        try {
            if (accessToken && sond_id) {
                const request = await api(
                    `api/feed/paginated_comments/${sond_id}?page=1`,
                    'GET',
                    null,
                    { ...accessToken, ...signal }
                );
                const response = await request.json()
                console.log('Response all comments: ', response)
                setIsLoading(false)
                if (response?.success) {
                    setComments(response?.datas?.data)
                }
            }
        } catch (error) {
            setTimeout(() => {
                fetchComments()
            }, 2000);
            console.error('Get opinion comments:', error.message);
        }
    }


    const updateOpinion = (data) => {
        setOpinion(data)
    }

    const deleteOpinion = (data) => {
        navigate(-1)
    }

    if (isLoading) {
        return (
            <div className='content-box flex'>
                <div className='w-2/5 h-screen hidden lg:block'>
                    <AppStore />
                </div>
                <div className='w-full lg:w-3/5 h-screen border-s border-e border-gray-200'>
                    <Header title='opinion' goBack={() => navigate(-1)} />
                    <div>
                        <ShimmerSocialPost type="both" />
                        {/* <LoadingPage text={t('loading_opinion')} /> */}
                    </div>
                </div>
                <div className='w-2/5 h-screen hidden lg:block'>
                    <LoginForm />
                </div>
            </div>
        )
    }


    // Empty page
    if (isEmpty || !opinion) {
        return (
            <div className='content-box flex'>
                <div className='w-2/5 h-screen hidden lg:block'>
                    <AppStore />
                </div>
                <div className='w-full lg:w-3/5 h-screen border-s border-e border-gray-200'>
                    <Header title='Opinion' goBack={() => navigate(-1)} />
                    <EmptyPage text={`${t('sorry')}, opinion ${t('unavailable').toLowerCase()}`} />
                </div>
                <div className='w-2/5 h-screen hidden lg:block'>
                    <LoginForm />
                </div>
            </div>
        )
    }


    return (
        <div className='flex-1 flex'>
            <div className='w-2/5 h-screen hidden lg:block'>
                <AppStore />
            </div>
            <div className='w-full lg:w-3/5 h-screen border-s border-e border-gray-200'>
                <Header title='Opinion' goBack={() => navigate(-1)} />
                {/* Opinion box */}
                <div className='h-[93%] pb-5 overflow-y-auto'>
                    {/* Opinion details */}
                    {opinion &&
                        <Opinion
                            key={0}
                            index={0}
                            opinion={opinion}
                            updateOpinion={updateOpinion}
                            deleteOpinion={deleteOpinion}
                            handleMuteUser={deleteOpinion}
                            sourceFrom={'ViewOpinion'}
                        />
                    }

                    {/* Opinion comments */}
                    {opinion && comments.length !== 0 ?
                        comments.map((comment, index) => {
                            return (
                                <div key={index}>
                                    <Comment
                                        key={index}
                                        user={comment?.user}
                                        com_id={comment?.com_id}
                                        com_mute={comment?.com_mute}
                                        com_parent={comment?.com_parent}
                                        com_sond={comment?.com_sond}
                                        com_text={comment?.com_text}
                                        com_type={comment?.com_type}
                                        com_user={comment?.com_user}
                                        attachment={comment?.attachment}
                                        soutiens={comment?.soutiens}
                                        soutiens_count={comment?.soutiens_count}
                                        subcomments={comment?.subcomments}
                                        subcomments_count={comment?.subcomments_count}
                                        com_date_created={comment?.com_date_created}
                                        sourceFrom={'ViewOpinion'}
                                    />

                                    {comment?.subcomments.map((subcomment, index) => {
                                        return (
                                            <div style={{ marginLeft: '55px' }}>
                                                <Comment
                                                    key={index}
                                                    user={subcomment?.user}
                                                    com_id={subcomment?.com_id}
                                                    com_mute={subcomment?.com_mute}
                                                    com_parent={comment?.com_id}
                                                    com_sond={subcomment?.com_sond}
                                                    com_text={subcomment?.com_text}
                                                    com_type={subcomment?.com_type}
                                                    com_user={subcomment?.com_user}
                                                    attachment={subcomment?.attachment}
                                                    soutiens={subcomment?.soutiens}
                                                    soutiens_count={subcomment?.soutiens_count}
                                                    subcomments={subcomment?.subcomments}
                                                    subcomments_count={subcomment?.subcomments_count}
                                                    com_date_created={subcomment?.com_date_created}
                                                    sourceFrom={'ViewOpinion'}
                                                />
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            )
                        }) : null}
                </div>
            </div>

            <div className='w-2/5 h-screen  hidden lg:block '>
                <LoginForm />
            </div>
        </div>
    )
}

export default OpinionDetailsPublic
